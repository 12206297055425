// import { useEffect } from "react";
// import { useMediaQuery } from "react-responsive";
import StaticPageTemplate from "./StaticPageTemplate";

const About = () => {
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <div>
      <StaticPageTemplate title={"About Us"}>
        <div className="static-content-container">
          <p>
            <b>Losode</b> is a global e-commerce marketplace platform and a
            dynamic growth engine for businesses. While our journey with Losode
            spans several years of development, we were formally established as
            a registered company in 2016. With legal entities in Nigeria, the
            United Kingdom, and the United States, our primary focus is
            connecting businesses with expansive customer bases, beginning with
            the fashion industry. <br />
            <br />
            At its core, <b>Losode</b> is a technology-driven company making a
            significant impact across Sub-Saharan Africa. By facilitating
            meaningful connections between businesses and consumers, we are
            enhancing accessibility and enabling more businesses to participate
            in the digital economy.
            <br />
            The name <b>‘Losode’</b> originates from a Yoruba phrase meaning
            <b>"let us go to town"</b> or <b>"let us go out."</b> The Yoruba
            people, indigenous to the coastal regions of Southwestern Nigeria,
            are renowned for their rich cultural heritage, including their
            music, art, and sculpture, as noted by the Collins dictionary. In
            alignment with our focus on growth and innovation across African
            industries, we proudly identify with a tribe deeply rooted in
            diversity and tradition.
            <br />
            Our product offerings combine the essence of African heritage with
            contemporary Western influences, ensuring a diverse and robust
            experience for all our customers.
            <br />
            <br />
            <span className="txt-description">
              At Losode, our mission is anchored in five core principles:
            </span>
            <ul className="list-goals">
              <li className="goal">Empowering Entrepreneurs</li>
              <li className="goal">Breaking Down Trade Barriers</li>
              <li className="goal">Facilitating Large-Scale Business Growth</li>
              <li className="goal">
                Supporting the Industries in Which We Operate
              </li>
              <li className="goal">Acting with Boldness and Courage</li>
            </ul>
          </p>
          <div className="about-content">
            <h3 className="txt-sellers"> Our Commitment to Our Customers</h3>
            <p className="txt-description">
              Integrity and excellence are fundamental to our ethos. Our
              commitment to our customers is unwavering, and we continuously
              strive to make that commitment clear. Our promise is:
            </p>
            Trusted logistics partners
            <ul className="list-promise">
              <li className="txt-description">
                A secure, reliable, and robust platform
              </li>
              <li className="txt-description">
                Exceptional customer service and engagement
              </li>
              <li className="txt-description">
                A straightforward exchange and returns policy
              </li>
              <li className="txt-description">
                Access to a competitive marketplace with a wide variety of items
              </li>
              <li className="txt-description">
                Verified and trustworthy businesses to trade with
              </li>
              <li className="txt-description">
                High-quality assurance standards
              </li>
              <li className="txt-description">Trusted logistic partners</li>
            </ul>
          </div>
          <div className="about-content">
            <h3 className="txt-sellers">
              Our Commitment to Our Business Partnerships
            </h3>
            <p className="txt-description">
              At Losode, we highly value our partnerships. We actively
              collaborate with our brands and businesses to reach some of the
              largest markets across the continent. With brands from Nigeria,
              Ghana, South Africa, and customers from across the globe, Losode
              serves as the ideal partner for your business. We nurture and
              support our partner brands and businesses, applying the same level
              of rigour and dedication we do in serving our customers. Our
              promise to business partners includes:
            </p>

            <ul className="list-promise">
              <li className="txt-description">
                Increased profitability through expanded market exposure
              </li>
              <li className="txt-description">
                New revenue-generating opportunities 
              </li>
              <li className="txt-description">
                Access to emerging and less congested markets
              </li>
              <li className="txt-description">
                Exposure to large customer bases
              </li>
              <li className="txt-description">
                A personalized technology storefront on Losode
              </li>
              <li className="txt-description">Minimal setup costs</li>
              <li className="txt-description">
                Enhanced visibility through strategic media exposure
              </li>
              <li className="txt-description">
                Unique features on Losode’s fashion and style magazine –'The
                Verdict' and Losmedia
              </li>
              <li className="txt-description">
                Participation in multiple capital markets with local currency
                trading
              </li>
              <li className="txt-description">
                Premium advertising opportunities
              </li>
              <li className="txt-description">
                Support from local and international logistics partners
              </li>
              <li className="txt-description">
                Multiple payment options, both local and international
              </li>
            </ul>
          </div>
        </div>
      </StaticPageTemplate>
      <style jsx="true">{`
        .p-list {
          margin: 0;
        }
        .list-promise,
        .list-goals {
          padding-inline-start: 17px;
        }
        .list-goals {
          margin-top: 15px;
        }
        .promise,
        .goal,
        .txt-sellers-desc {
          font-family: DomaineSansText-Light;
        }
        .txt-sellers,
        .txt-promise {
          font: normal 600 16px/24px DomaineSansText-Light;
        }
        .footer-container {
          padding: 40px;
        }
        .details-container {
          width: 85%;
          margin-left: 70px;
          margin-right: auto;
          margin-top: 8em;
          margin-bottom: 8em;
        }
        .collapse-container {
          padding-top: 1em;
        }

        .txt-detail-desc {
          background: #f3f3f3;
          font: normal normal 20px/22px DomaineSansText-Light;
          padding: 20px 164px 28px 36px;
          text-align: justify;
        }
        @media (max-width: 640px) {
          .about-content h3,
          .about-content h4 {
            font-size: 15px;
            color: #000;
            font-weight: 700;
          }
          .text-container {
            padding: 20px 10% 0;
          }
          .title-div-about {
            height: 124px;
          }
          .questions-container {
            margin: 40px 7%;
            width: auto;
          }
          .sm-txt {
            font-size: 11px;
            margin: 0;
            font-family: DomaineSansText-Light;
          }
        }
      `}</style>
    </div>
  );
};

export default About;
