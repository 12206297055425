import { useState, useEffect } from "react";
import { Button, Form, Input, Radio, Select, Space } from "antd";
import { countries, plans } from "../../../../assets/constants";
import triggerNotification from "../../../../hooks/triggerNotification";
import formatter from "../../../../utils/formatter";
import { useSelector } from "react-redux";
import api from "../../../../api";
import { useParams } from "react-router-dom";
import { CheckCircle } from "../../../../../public/images";
import { fetchCities } from "../../../../utils/getStates";

const PhotoshootCheckoutWeb = () => {
  const { plan } = useParams();
  const selectedPlan = plans[plan];
  const [form] = Form.useForm();

  const [paymentLink, setPaymentLink] = useState("");
  const [cities, setCities] = useState([]);

  const { details } = useSelector((state) => state.store);
  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };

  const [phone, setPhone] = useState(details?.phoneNumber);

  const userInfo = {
    brand_city: details?.city,
    email: details?.email,
    brand_name: details?.brandName,
    first_name: details?.firstName,
    last_name: details?.lastName,
    phone_number: details?.phoneNumber,
  };

  const getCities = async () => {
    setCities(await fetchCities("nigeria"));
  };

  const onSubmitPayment = async (gateway) => {
    try {
      const values = form.getFieldsValue();
      const { name, desc, price } = selectedPlan;

      // Set additional values
      values.payment_channel = gateway;
      values.phone_dial = countries[0]?.dial;
      values.photoshoot_plan = name;
      values.photoshoot_classification = desc;
      values.photoshoot_price = price;

      // Check if any field is undefined
      if (Object.values(values).includes(undefined)) {
        return triggerNotification({
          type: "error",
          message: "Please fill all fields",
        });
      }

      // Make API call to book photoshoot
      const { data } = await api.store.bookPhotoshoot(values);

      // Process the API response
      if (data.status === "ok") {
        setPaymentLink(data.data.link);
      } else {
        triggerNotification({
          type: "error",
          message:
            data.status === "not ok" ? data.message : "Unknown error occurred",
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: error.response.data.message || "An unexpected error occurred",
      });
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  return (
    <div>
      <div className="checkout-top-nav">
        <div className="logo-container">
          <img src="/images/losode-no-tagline.png" alt="losode-logo" />
        </div>
        <h2 className="txt-checkout">
          Losode Photoshoot <br />
          (Checkout)
        </h2>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div className="photoshoot-checkout-container">
        <div className="checkout-section">
          <div className="checkout-subsection checkout-proceed-subsection">
            <div className="checkout-section-header">
              <div className="checkout-section-title">Country</div>
            </div>
            <div className="checkout-section-body">
              <div className="checkout-section-body-country-container">
                <div className="checkout-section-body-country-content">
                  <img src={countries[0]?.icon} alt="NG" />
                  <div>{countries[0]?.country}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="checkout-subsection checkout-proceed-subsection"
            style={{ marginBottom: 10 }}
          >
            <div className="checkout-section-header">
              <div className="checkout-section-title">
                Please fill in the form below
              </div>
            </div>
            <div className="checkout-section-body">
              <Form
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
                form={form}
                initialValues={userInfo || {}}
              >
                <Form.Item
                  label="Designer"
                  name="brand_name"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    className="checkout-signin-form-input"
                    placeholder="Designer name"
                  />
                </Form.Item>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    className="checkout-signin-form-input"
                    placeholder="John"
                  />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    className="checkout-signin-form-input"
                    placeholder="Doe"
                  />
                </Form.Item>
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please use an acceptable email format",
                    },
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input
                    className="checkout-signin-form-input"
                    placeholder="www.example.com"
                  />
                </Form.Item>

                <Form.Item
                  name="brand_city"
                  label="City"
                  labelAlign="top"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    className="select"
                    placeholder="Select your city"
                    showSearch
                  >
                    {cities?.map((city, index) => (
                      <Select.Option key={index} value={city}>
                        {city}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="phone_number"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone Number!",
                    },
                  ]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Select
                      disabled
                      defaultValue={"+234"}
                      style={{
                        width: "35%",
                      }}
                      showArrow={false}
                    >
                      <Select.Option
                        key={countries[0].dial}
                        value={countries[0].dial}
                      >
                        <img
                          className="icon-flag"
                          src={countries[0].icon}
                          alt={countries[0].country}
                        />
                        {countries[0].dial}
                      </Select.Option>
                    </Select>
                    <Input
                      className="checkout-signin-form-input"
                      type="number"
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Space.Compact>
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="checkout-subsection checkout-proceed-subsection">
            <div className="checkout-section-header">
              <div className="checkout-section-title">
                Select Your Payment Option
              </div>
            </div>
            <div className="checkout-section-body">
              <div className="checkout-section-body-payment-content">
                <Radio.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                  onChange={(e) => {
                    onSubmitPayment(e.target.value);
                  }}
                >
                  {/* <Radio
                          value="losodepay"
                          className="checkout-section-body-payment-content-radio"
                        >
                          <div style={{ display: "flex" }}>
                            <div className="checkout-section-body-payment-content-logo-container">
                              <img
                                src="/images/losodepay.svg"
                                alt="losodepay"
                              />
                            </div>
                            <div>LosodePay</div>
                          </div>
                        </Radio> */}
                  <Radio
                    value="paystack"
                    className="checkout-section-body-payment-content-radio"
                  >
                    <div style={{ display: "flex" }}>
                      <div className="checkout-section-body-payment-content-logo-container">
                        <img src="/images/paystack.svg" alt="paystack" />
                      </div>
                      <div>Paystack</div>
                    </div>
                  </Radio>

                  <Radio
                    value="flutterwave"
                    className="checkout-section-body-payment-content-radio"
                    style={{ border: "0" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div className="checkout-section-body-payment-content-logo-container">
                        <img src="/images/flutterwave.svg" alt="flutterwave" />
                      </div>
                      <div>Flutterwave</div>
                    </div>
                  </Radio>
                </Radio.Group>

                <Button
                  className="checkout-signin-form-button"
                  disabled={paymentLink === ""}
                  style={{ marginTop: "24px" }}
                  onClick={() => window.open(paymentLink, "_blank")}
                >
                  Pay Now
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="checkout-order-info-section">
          <div className="checkout-order-info-section-title">
            <div>Your Order Information</div>
          </div>
          <div
            className="checkout-order-info-products-section"
            style={{ margin: "30px 0" }}
          >
            <div className="package-info">
              <h2 className="txt-package-name">{selectedPlan?.name}</h2>
              <h4 className="txt-package-desc">({selectedPlan?.desc})</h4>
              <h4 className="txt-package-price">
                {
                  formatter(selectedPlan?.price, rate, code, indicator).split(
                    "."
                  )[0]
                }
              </h4>
              {selectedPlan?.features?.map((feature) => (
                <h4 className="txt-package-feature">
                  <CheckCircle /> {feature}
                </h4>
              ))}
            </div>
          </div>
          <div className="checkout-order-info-section-body-container">
            {/* <div className="checkout-order-info-section-body">
              <div className="checkout-order-info-section-body-items">
                <div>Sub - Total</div>
                <div>
                  {formatter(selectedPlan?.price, rate, code, indicator)}
                </div>
              </div>
              <div className="checkout-order-info-section-body-delivery">
                <div>Payment Charge</div>
                <div style={{ color: "#800000" }}>
                  {formatter(500, rate, code, indicator)}
                </div>
              </div>
            </div> */}
            <div className="checkout-order-info-section-total">
              <div>Total</div>
              <div>{formatter(selectedPlan?.price, rate, code, indicator)}</div>
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .checkout-top-nav {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 68px;
          position: fixed;
          width: 100%;
          background: white;
          font-family: DomaineSansText-Regular;
          font-size: 18px;
          font-weight: bold;
          height: 80px;
          z-index: 100;
          box-shadow: 0 2px 10px 0 #05050526;
        }

        .logo-container {
          display: grid;
          place-items: center;

          img {
            width: 130px;
          }
        }

        .txt-checkout {
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }

        .photoshoot-checkout-container {
          font-family: "DomaineSansText-Light";
          font-size: 14px;
          background: #f9f9f9;
          padding: 40px 60px;
          display: flex;
          gap: 40px;
          justify-content: space-between;
          width: 100vw;
          padding-top: 120px;
          margin: auto;
        }
        .checkout-section {
          display: flex;
          flex-direction: column;
          gap: 40px;
          width: 100%;
          max-width: 45vw;
        }
        .checkout-subsection {
          background: white;
          padding: 24px;
          width: 100%;
          margin-bottom: 24px;
        }
        .checkout-section-header {
          max-width: 75%;
        }
        .checkout-section-title {
          font-size: 20px;
          font-family: "DomaineSansText-Regular";
        }
        .checkout-section-body-country-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .checkout-section-body-country-content {
          display: flex;
          gap: 16px;
          align-items: center;
        }
        .checkout-short-button {
          width: 225px;
          font-size: 14px;
          height: 48px;
          background: black;
          color: white;
        }
        .checkout-proceed-subsection .checkout-section-header {
          padding-left: 16px;
        }
        .checkout-proceed-subsection .checkout-section-title {
          font-size: 20px;
          font-family: "DomaineSansText-Regular";
        }
        .checkout-proceed-subsection .checkout-section-body {
          padding: 24px 16px 0;
          padding-right: 0;
        }
        .checkout-signin-form-input {
          border: 1px solid #707070;
          height: 48px;
        }
        .ant-select-selector {
          background: #fff;
        }
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 48px !important;
          width: 100%;
          border: 1px solid #707070;
        }

        .ant-select-selection-item {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
        .checkout-section-body-payment-content
          .checkout-section-body-payment-content-logo-container {
          width: 15rem;
          margin-left: 40px;
        }
        .checkout-section-body-payment-content
          .checkout-section-body-payment-content-radio {
          display: flex;
          border-bottom: 1px solid rgb(167, 167, 167);
          padding-bottom: 16px;
          align-items: center;
          justify-content: space-between;
        }
        .checkout-signin-form-button {
          height: 48px;
          width: 100%;
          background: black;
          color: white;
          font-size: 16px;
        }
        .checkout-order-info-section {
          height: fit-content;
          width: 100%;
          max-width: 45vw;
        }
        .checkout-order-info-section-title {
          background: white;
          font-size: 20px;
          text-align: center;
          font-family: "DomaineSansText-Regular";
          padding: 24px;
        }
        .checkout-order-info-section-title > div {
          padding: 16px;
        }

        .checkout-order-info-section-body {
          border-bottom: 1px solid #a8a8a8;
          padding-bottom: 24px;
          margin-bottom: 24px;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 10px;
          justify-content: flex-start;
        }
        .checkout-order-info-products-section {
          display: flex;
          flex-direction: column;
          padding: 24px 0;
          gap: 16px;
          background: white;
        }
        .checkout-order-info-products-section-item {
          background: white;
          position: relative;
          margin-bottom: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid #a7a7a7;
        }

        .package-info {
          padding: 0px 24px;
        }

        .txt-package-name {
          font-family: "DomaineSansText-Regular";
          font-size: 28px;
          text-align: center;
          margin-bottom: 0;
        }
        .txt-package-desc {
          font-family: "DomaineSansText-Light";
          font-size: 16px;
          text-align: center;
        }
        .txt-package-price {
          border-bottom: 1px solid #a8a8a8;
          font-family: "DomaineSansText-Regular";
          font-weight: 600;
          font-size: 45px;
          line-height: 58px;
          text-align: center;
          padding: 10px 0 30px;
          margin-bottom: 4rem;
        }

        .txt-package-feature {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-family: "DomaineSansText-Regular";
          gap: 10px;
          margin-top: 20px;
          padding-left: 3rem;
        }

        .checkout-order-info-section-body-container {
          background-color: #fff;
          padding: 24px;
        }
        .checkout-order-info-products-section-item-main {
          display: flex;
          gap: 16px;
        }
        .checkout-order-info-section-body-items,
        .checkout-order-info-section-body-delivery,
        .checkout-order-info-section-total {
          display: flex;
          justify-content: space-between;
        }
        .checkout-order-info-section-total {
          font-family: DomaineSansText-Black;
          // border-bottom: 1px solid #a8a8a8;
          padding-bottom: 10px;
        }

        @media (max-width: 640px) {
          .checkout-top-nav {
            padding: 16px 22px;
          }
          .logo-container img {
            width: 64px;
          }
          .txt-checkout {
            font-size: 12px;
            position: absolute;
            width: 100%;
            left: 0;
          }
          .checkout-top-nav svg {
            width: 16px;
            height: 16px;
          }
          .photoshoot-checkout-container {
            padding: 0;
            padding-top: 100px;
            flex-direction: column;
            gap: 16px;
          }
          .checkout-section,
          .checkout-order-info-section {
            max-width: 100%;
            gap: 16px;
          }
          .checkout-subsection {
            margin-bottom: 0px;
          }
          .checkout-proceed-subsection .checkout-section-title {
            font-size: 16px;
          }
          .checkout-proceed-subsection .checkout-section-body {
            padding: 24px 0 0;
          }
          .checkout-proceed-subsection .checkout-section-header {
            padding: 0;
          }
          .checkout-subsection {
            padding: 16px 12px;
          }
          .ant-form-vertical .ant-form-item-row {
            padding-bottom: 16px;
          }
          .txt-package-name {
            font-size: 24px;
          }
          .txt-package-desc {
            font-size: 16px;
          }
          .txt-package-price {
            font-size: 32px;
            border-top: 1px solid #a8a8a8;
            padding: 16px 0;
            margin: 16px auto 32px;
          }
          .txt-package-feature {
            font-size: 16px;
          }
          .txt-package-feature svg {
            height: 14px;
            width: 14px;
          }
          .checkout-order-info-section-title > div {
            border-bottom: 0;
            padding: 0;
          }
          .checkout-section-body-payment-content
            .checkout-section-body-payment-content-logo-container {
            width: auto;
            margin-left: 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default PhotoshootCheckoutWeb;
