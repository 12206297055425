import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Table } from "antd";
import formatter from "../utils/formatter";
import api from "../api";
import triggerNotification from "../hooks/triggerNotification";

const OrderCompleted = () => {
  const query = useLocation().search;
  const txref = new URLSearchParams(query).get("txRef");
  const message = new URLSearchParams(query).get("message");

  const { data: currency } = useSelector((state) => state.currency);
  const { code, indicator, rate } = currency
    ? currency
    : { code: "", currency: "", rate: "" };
  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Added Items",
      dataIndex: "product_img",
      key: "img1",
      render: (img1) => <img className="item-img" alt="product" src={img1} />,
      width: 100,
    },
    {
      align: "center",
      title: "Product Name",
      dataIndex: "product_name",
      key: "name",
      width: 300,
    },
    {
      align: "center",
      colSpan: 1,
      title: "Size",
      dataIndex: "product_size",
      key: "size",
    },
    {
      align: "center",
      title: "Color",
      dataIndex: "product_color",
      key: "color",
      render: (color) => (
        <div
          style={{
            padding: 5,
            border: "1px solid #ccc",
            width: "33px",
            display: "inline-block",
          }}
        >
          <div className="color-box" style={{ backgroundColor: `${color}` }} />
        </div>
      ),
    },
    {
      align: "center",
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <p className="price">{formatter(price, rate, code, indicator)}</p>
      ),
    },
    {
      align: "center",
      title: "Quantity",
      dataIndex: "order_qty",
      key: "quantityWanted",
    },
    {
      align: "center",
      title: "Total",
      dataIndex: "total_amount",
      key: "totalPrice",
      render: (totalPrice) => (
        <p className="price">{formatter(totalPrice, rate, code, indicator)}</p>
      ),
    },
  ];

  const getOrderDetails = async () => {
    try {
      const { data } = await api.checkout.orderReceipt(txref);
      setData(data?.data);
      const order = data?.data;
      const ids = order?.order_items?.map((item) => item?.product_id);
      window.fbq("track", "Purchase", {
        value: order?.order_detail?.grand_total, // Dynamic total value
        currency: code, // Dynamic currency, e.g., 'USD'
        content_ids: ids, // Dynamic array of product IDs
        content_type: "product", // Specify 'product' or 'service'
        num_items: order?.order_items?.length, // Dynamic number of purchased items
        order_id: order?.order_items?.order_no, // Dynamic order ID
      });
    } catch (err) {
      triggerNotification({
        type: "error",
        message: err.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    getOrderDetails();
    localStorage.setItem("txref", txref);
    localStorage.setItem("isSuccess", message);
    window?.opener?.location ?? window?.opener?.location?.reload();
    window.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txref]);

  return (
    <div>
      <div className="completed-container">
        <p className="info-txt">Payment has been completed successfully</p>
        <hr className="line" />
        <div className="info-container">
          <div className="info-div">
            <h4 className="info">Order date</h4>
            <h3 className="value">{data?.order_detail?.payment_date}</h3>
          </div>
          <div className="info-div">
            <h4 className="info">Transaction ref</h4>
            <h3 className="value">{txref}</h3>
          </div>
          <div className="info-div">
            <h4 className="info">Payment status</h4>
            <h3 className="value">Completed</h3>
          </div>
          <div className="info-div">
            <h4 className="info">Address</h4>
            <h3 className="value">{data?.order_detail?.shipping_address}</h3>
          </div>
        </div>
        <hr className="line" />
        <Table
          dataSource={data?.order_items}
          columns={columns}
          pagination={false}
        />
        <hr className="line" />
        <div className="summary-div">
          <div className="details-div">
            <h4 className="txt-title">Subtotal</h4>
            <h4 className="value">
              {formatter(data?.order_detail?.subtotal, rate, code, indicator)}
            </h4>
          </div>
          <div className="details-div">
            <h4 className="txt-title">{data?.order_detail?.shipping_type}</h4>
            <h4 className="value">
              {formatter(
                data?.order_detail?.shipping_cost,
                rate,
                code,
                indicator
              )}
            </h4>
          </div>
          {/* <div className="details-div">
            <h4 className="txt-title">Taxes</h4>
            <h4 className="value">N20.00</h4>
          </div> */}
          <div className="details-div">
            <h4 className="txt-title">Voucher/Discount </h4>
            <h4 className="value">
              {data?.order_detail?.general_discount_percentage || "0%"}
            </h4>
          </div>
        </div>
        <hr className="line" />
        <div className="details-div">
          <h4 className="txt-total">Total </h4>
          <h4 className="value">
            {formatter(data?.order_detail?.grand_total, rate, code, indicator)}
          </h4>
        </div>
        <hr className="line" />
      </div>
      <style jsx="true">{`
        .completed-container {
          margin: 92px 64px;
        }
        .info-txt {
          font: normal bold 24px/28px DomaineSansText-Light;
          color: #3e3e3e;
        }
        .line {
          color: #9d9d9d;
          margin-top: 44px;
          width: 100%;
        }
        .info-container {
          display: flex;
          justify-content: space-around;
        }
        .info-div {
          display: flex;
          flex: 1;
          flex-direction: column;
        }
        .info {
          font: normal normal 16px/23px DomaineSansText-Light;
          color: #787878;
        }
        .value {
          font: normal normal 18px/28px DomaineSansText-Light;
          color: #000;
          margin-top: 13px;
        }
        .item-img {
          height: 150px;
        }
        .color-box {
          width: 20px;
          height: 20px;
          border: 1px solid #ccc;
        }
        .summary-div {
          margin-top: 44px;
        }
        .details-div {
          display: flex;
          justify-content: space-between;
        }
        .txt-title {
          font: normal normal 18px/28px DomaineSansText-Light;
          color: #3e3e3e;
        }
        .txt-total {
          font: normal bold 18px/28px DomaineSansText-Light;
          color: #3e3e3e;
        }
      `}</style>
    </div>
  );
};

export default OrderCompleted;
