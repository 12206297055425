import React from "react";
import { useMediaQuery } from "react-responsive";
import PhotoshootSuccessMobile from "./PhotoshootSuccessMobile";
import PhotoshootSuccessWeb from "./PhotoshootSuccessWeb";

function PhotoshootSuccess() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <>
      {isTabletOrMobile ? (
        <PhotoshootSuccessMobile />
      ) : (
        <PhotoshootSuccessWeb />
      )}
    </>
  );
}

export default PhotoshootSuccess;
