import React from "react";
import { useMediaQuery } from "react-responsive";
import BookPhotoshootMobile from "./BookPhotoshootMobile";
import BookPhotoshootWeb from "./BookPhotoshootWeb";

const BookPhotoshoot = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <>
      {isTabletOrMobile ? <BookPhotoshootMobile /> : <BookPhotoshootWeb />}
    </>
  );
};

export default BookPhotoshoot;
