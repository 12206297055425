import { useEffect, useState } from "react";
import { Button, Checkbox, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { storeActionCreators } from "../../redux/stores";
import { useMediaQuery } from "react-responsive";
import {
  PlusOutlined,
  ArrowDownOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import ListingView from "./ListingView";
import SellerTopBar from "./SellerTopbar";
import SellerSideNav from "./SellerSideNav";
import { useNavigate } from "react-router-dom";

const ManageDrafts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checkedList, setCheckedList] = useState(new Set([]));
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const { drafts, details } = useSelector((state) => state.store);

  const { fetchStoreDrafts, deleteDraft, fetchSingleDraft } =
    bindActionCreators(storeActionCreators, dispatch);

  //   const currency = useSelector((state) => state.currency[0]);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const onChange = (id) => {
    checkedList.has(id) ? checkedList.delete(id) : checkedList.add(id);
    setIndeterminate(
      !!checkedList.size && checkedList.size < drafts.data.length
    );
    setCheckAll(checkedList.size === drafts.data.length);
    setCheckedList(new Set(checkedList));
  };

  const onCheckAllChange = (e) => {
    const ids = drafts.data.map((draft) => draft.draft_id);
    setCheckedList(e.target.checked ? new Set(ids) : checkedList.clear());
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const deleteItem = async (id) => {
    if (id !== undefined) {
      onChange(id);
    }
    const ids = Array.from(checkedList);
    if (ids.length > 0) {
      const res = await deleteDraft(Array.from(checkedList));
      if (res.status === "ok") {
        message.success(res.message);
        fetchStoreDrafts();
      }
    }
  };

  const resumeDraft = async (id) => {
    const res = await fetchSingleDraft(id);
    if (res.status === "ok") {
      navigate("/complete-listing");
    }
  };

  // function itemRender(current, type, originalElement) {
  //   if (type === "prev") {
  //     return (
  //       <span className="pagination-nav-text">
  //         {!isTabletOrMobile ? "Previous" : ""}
  //       </span>
  //     );
  //   }
  //   if (type === "next") {
  //     return (
  //       <span className="pagination-nav-text">
  //         {!isTabletOrMobile ? "Next" : ""}
  //       </span>
  //     );
  //   }
  //   return originalElement;
  // }

  useEffect(() => {
    fetchStoreDrafts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="manage-drafts-container">
      <SellerTopBar />
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && <SellerSideNav currentPageKey="5" />}
        <div className="manage-drafts-coverall-container">
          <div className="manage-drafts-adspace"></div>
          <div className="manage-drafts-body-content-header">
            <div className="manage-drafts-body-content-header-container">
              <div className="manage-drafts-body-content-header-title">
                Manage Saved Listings
              </div>
              <div className="manage-drafts-body-content-header-subtitle">
                View and edit your saved listings here
              </div>
            </div>
            <Button
              icon={
                <PlusOutlined
                  style={{ fontSize: "17px", verticalAlign: "middle" }}
                />
              }
              className="manage-drafts-body-sidebar-add-listing-button"
              onClick={() => navigate("/list-item")}
            >
              List A New Item
            </Button>
          </div>
          <div className="manage-drafts-body-container">
            <div className="manage-drafts-body-content">
              {drafts?.data?.length > 0 && (
                <div className="manage-drafts-body-content-top-actions">
                  <div className="top-actions-checkbox-container">
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                      style={{ marginRight: "8px" }}
                    />
                    <ArrowDownOutlined />
                  </div>
                  <div>
                    <Button
                      className="activate-delete-button"
                      disabled={checkedList?.size === 0}
                      onClick={() => deleteItem()}
                    >
                      Delete Saved Listing
                    </Button>
                  </div>
                </div>
              )}

              <div className="manage-drafts-body-content-listings-container">
                {drafts?.data?.length > 0 ? (
                  drafts.data
                    .sort((a, b) => b.draft_id - a.draft_id)
                    ?.map((draft) => (
                      <ListingView
                        checked={checkedList?.has(draft.draft_id)}
                        key={draft.draft_id}
                        product={draft}
                        type="draft"
                        onChange={() => onChange(draft.draft_id)}
                        onResume={() => resumeDraft(draft.draft_id)}
                        deleteDraft={() => deleteItem(draft.draft_id)}
                      />
                    ))
                ) : (
                  <div className="no-listings-to-show">
                    <div>
                      <ExclamationCircleFilled
                        style={{ fontSize: "50px", marginBottom: "16px" }}
                      />
                      <div>
                        You do have any saved listings.{" "}
                        <div
                          className="no-listings-to-show-link"
                          onClick={() => navigate("/list-item")}
                        >
                          Go here
                        </div>{" "}
                        to list a new item to your Store{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="manage-drafts-body-sidebar">
              {/* <div className="manage-drafts-body-sidebar-content">
                <div className="manage-drafts-body-sidebar-content-title">
                  Sort
                </div>
                <Select
                  defaultValue="latest"
                  placeholder="Latest"
                  className="manage-drafts-body-sidebar-content-select"
                >
                  <Select.Option value="latest">Latest</Select.Option>
                  <Select.Option value="latest">Latest</Select.Option>
                  <Select.Option value="latest">Latest</Select.Option>
                  <Select.Option value="latest">Latest</Select.Option>
                </Select>
              </div> */}
              {/* <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "8px",
                  }}
                >
                  Listing Status
                </div>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Draft</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Active</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Inactive</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
                <Checkbox style={{ width: "100%", marginLeft: 0 }}>
                  <span>Sold out</span>
                  <span style={{ marginLeft: 8 }}>3</span>
                </Checkbox>
              </div>*/}
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .manage-drafts-container {
          font-family: DomaineSansText-Light;
        }
        .manage-listing-all-content {
          position: relative;
          margin-top: 154px;
        }

        .manage-drafts-coverall-container {
          margin-left: 200px;
        }

        .manage-drafts-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }

        .manage-drafts-body-container {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 100px;
        }
        .manage-drafts-body-content-header-container {
          margin-bottom: 24px;
        }
        .manage-drafts-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px;
        }
        .manage-drafts-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .manage-drafts-body-content-header-subtitle {
          font-size: 16px;
          color: #707070;
        }
        .manage-drafts-body-sidebar-add-listing-button {
          height: 47px;
          background: black;
          color: white;
          font-size: 16px;
        }
        .sidebar-icons-container {
          font-size: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .sidebar-tabs-icon {
          padding: 10px;
          border: 1px solid;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          margin-right: 8px;
        }
        .sidebar-list-icon {
          padding: 10px;
          border: 1px solid;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .sidebar-quick-edit {
          padding: 10px;
          border: 1px solid #d4d4d4;
          text-align: center;
          margin-top: 16px;
          cursor: pointer;
        }

        .manage-drafts-body-content-listings-container {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 70px;
        }

        .manage-drafts-body-content-top-actions {
          display: flex;
          gap: 40px;
          margin-bottom: 24px;
          align-items: center;
        }
        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }
        .activate-delete-button {
          background: black;
          color: white;
          height: 34px;
          width: 175px;
        }
        .manage-drafts-body-content-header-container {
          margin-bottom: 24px;
        }
        .manage-drafts-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px;
        }
        .manage-drafts-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .manage-drafts-body-content-header-subtitle {
          font-size: 16px;
          color: #707070;
        }

        .manage-drafts-body-sidebar {
          width: 17%;
          padding-left: 10px;
          border-left: 1px solid #d4d4d4;
          margin-top: 60px;
          margin-right: 10px;
        }
        .manage-drafts-body-sidebar-add-listing-button {
          height: 47px;
          background: black;
          color: white;
          font-size: 16px;
        }
        .sidebar-icons-container {
          font-size: 20px;
          text-align: center;
          margin-top: 16px;
        }
        .sidebar-tabs-icon {
          padding: 10px;
          border: 1px solid;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          margin-right: 8px;
        }
        .sidebar-list-icon {
          padding: 10px;
          border: 1px solid;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .sidebar-quick-edit {
          padding: 10px;
          border: 1px solid #d4d4d4;
          text-align: center;
          margin-top: 16px;
          cursor: pointer;
        }

        .manage-drafts-body-content {
          width: 80%;
          padding: 0 20px;
          margin: auto;
        }

        .top-actions-checkbox-container {
          padding: 5px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
        }
        .pagination-nav-text {
          font-family: "DomaineSansText-Light";
        }
        .pagination-nav-text:hover {
          color: #800000;
        }

        .no-listings-to-show {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 500px;
          font-weight: bold;
          font-size: 18px;
          text-align: center;
          width: 80%;
          margin: auto;
        }
        .no-listings-to-show-link {
          text-decoration: underline;
          display: inline-block;
        }
        .no-listings-to-show-link:hover {
          cursor: pointer;
          color: #800000;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background: white;
        }

        .manage-drafts-body-sidebar-content {
          margin: 10px 0px 16px;
        }
        .manage-drafts-body-sidebar-content-title {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 8px;
        }
        .manage-drafts-body-sidebar-content-select {
          width: 100%;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .manage-drafts-body-content {
            width: 85%;
            margin: auto;
            padding: 0 16px;
          }

          .manage-drafts-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
          }
          .manage-drafts-body-sidebar-add-listing-button {
            margin-top: 0;
            width: fit-content;
            align-self: flex-end;
          }

          .manage-drafts-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
        }

        @media (max-width: 640px) {
          .manage-drafts-coverall-container {
            margin-left: 0;
          }
          .manage-listing-all-content {
            margin-top: ${!details.phone_verify_status ? "154px" : "90px"};
          }
          .manage-drafts-adspace {
            background-image: url("/images/seller-stats-banner.png");
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .manage-drafts-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .manage-drafts-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .manage-drafts-body-content-header-title {
            font-size: 18px;
          }
          .manage-drafts-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 169px;
          }

          .manage-drafts-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .manage-drafts-body-content-header-container {
            display: block;
            align-items: center;
            margin-bottom: 16px;
          }
          .manage-drafts-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
          }
          .manage-drafts-body-content-header-subtitle {
            width: 100%;
            font-size: 14px;
          }
          .manage-drafts-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
          }
          .manage-drafts-body-sidebar {
            width: 100%;
            margin-top: 0;
            margin-bottom: 24px;
            padding: 0 16px;
            border: 0;
            margin-right: 0;
            display: flex;
            justify-content: flex-end;
          }
          .manage-drafts-body-sidebar-content {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .manage-drafts-body-sidebar-content-title {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
          }
          .manage-drafts-body-sidebar-content-select {
            width: auto;
          }
          .manage-drafts-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: auto !important;
            font-size: 12px !important;
            padding: 0 16px !important;
          }
          .manage-drafts-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .manage-drafts-body-content {
            width: 100%;
            padding: 0 16px;
          }
          .manage-drafts-body-content-top-actions {
            gap: 16px;
            margin-top: 16px;
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default ManageDrafts;
