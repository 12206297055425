import React from "react";
import { useMediaQuery } from "react-responsive";
import FAQMobile from "./FaqMobile";
import FAQWeb from "./FaqWeb";

function FAQ() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  return <>{isTabletOrMobile ? <FAQMobile /> : <FAQWeb />}</>;
}

export default FAQ;
