import React from "react";
import { SuccessIcon } from "../../../../../public/images";
import { Button } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { plans } from "../../../../assets/constants";
import formatter from "../../../../utils/formatter";
import { useSelector } from "react-redux";

const PhotoshootSuccessWeb = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };

  const plan = params.get("plan");
  const selectedPlan = (() => {
    for (const key in plans) {
      const currPlan = plans[key];
      if (currPlan.name === plan) return currPlan;
    }
    return undefined;
  })();

  return (
    <div className="success-page-container">
      <div className="checkout-top-nav">
        <div className="logo-container">
          <img src="/images/losode-no-tagline.png" alt="losode-logo" />
        </div>
        <h2 className="txt-checkout">
          Losode Photoshoot <br />
          (Checkout)
        </h2>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="success-page-wrapper">
        <section className="success-header-section">
          <img className="img-success" alt="success icon" src={SuccessIcon} />
          <h3 className="txt-thank-you">Thank You</h3>
          <p className="txt-success-info">
            We have sent you an order confirmation email. <br />
            Your photoshoot date will be confirmed by our team shortly.
          </p>
        </section>
        <section className="plan-info-section">
          <h4 className="txt-order-info">Your Order Information</h4>
         <div className="plan-info-container">
          <div className="plan-info">
              <h4 className="txt-plan">Package</h4>
              <h4 className="txt-plan">Price</h4>
            </div>
            <div className="plan-info">
              <h4 className="txt-plan-name">
                {selectedPlan?.name} <p>({selectedPlan?.desc})</p>
              </h4>
              <h4 className="txt-plan-price">
                {formatter(selectedPlan?.price, rate, code, indicator)}
              </h4>
            </div>
         </div>
        </section>
        <section className="btn-section">
          <Button
            className="btn-action"
            type="ghost"
            onClick={() => navigate("/book-photoshoot")}
          >
            Return to photoshoot homepage
          </Button>
          <Button
            className="btn-action"
            type="primary"
            onClick={() =>
              navigate(`/${selectedPlan?.type}-photoshoot-checkout`)
            }
          >
            Book another photoshoot
          </Button>
        </section>
      </div>
      <style jsx="true">
        {`
          .success-page-container {
            position: relative;
          }
          .checkout-top-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 68px;
            position: fixed;
            width: 100%;
            background: white;
            font-family: DomaineSansText-Regular;
            font-size: 18px;
            font-weight: bold;
            height: 80px;
            z-index: 100;
            box-shadow: 0 2px 10px 0 #05050526;
            margin-bottom: 64px;
          }

          .logo-container {
            display: grid;
            place-items: center;

            img {
              width: 130px;
            }
          }

          .txt-checkout {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
          }

          .success-page-wrapper {
            width: 80%;
            margin: 0 auto;
            padding-top: 120px;
          }
          .success-header-section {
            background: #272727;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 50px;
          }
          .img-success {
            width: 80px;
            height: 80px;
          }
          .txt-thank-you {
            font-family: DomaineSansText-Regular;
            font-size: 40px;
            font-weight: 400;
            line-height: 85.11px;
            color: #fff;
            margin: 0;
          }
          .txt-success-info {
            font-family: DomaineSansText-Light;
            font-size: 25px;
            font-weight: 300;
            line-height: 41.38px;
            color: #fff;
            text-align: center;
          }
          .plan-info-section {
            background: #f9f9f9;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 22px;
          }
          .plan-info-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width:100%
          }
          .txt-order-info {
            font-family: DomaineSansText-Regular;
            font-size: 24px;
            font-weight: 400;
            line-height: 31.03px;
            margin-bottom: 24px;
          }
          .plan-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 40%;
          }
          .txt-plan {
            font-family: DomaineSansText-Regular;
            font-size: 18px;
            font-weight: 400;
            line-height: 25.86px;
          }
          .txt-plan-price {
            font-family: DomaineSansText-Regular;
            font-size: 16px;
            font-weight: 600;
            line-height: 23.27px;
            margin: 0;
          }
          .txt-plan-name {
            font-family: DomaineSansText-Regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 23.27px;
            margin: 0;

            p {
              font-family: DomaineSansText-Light;
              font-size: 12px;
              font-weight: 300;
              line-height: 20.69px;
              text-align: center;
            }
          }
          .btn-section {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 74px;
          }
          .btn-action {
            font-family: DomaineSansText-Regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 20.69px;
            height: 48px;
          }

          @media (max-width: 640px) {
            .success-page-wrapper {
              width: 100%;
              padding: 100px 16px 40px;
            }
            .checkout-top-nav{
              padding: 16px;
            }
            .txt-checkout {
              position: absolute;
              width: 100%;
              font-size: 16px;
            }
            .logo-container img{
              width: 80px;
            }
            .success-header-section{
              padding: 14px;
            }
            .img-success{
              width: 60px;
              height: 60px;
              margin:10px
            }
            .txt-thank-you {
              font-size: 32px;
              line-height: 40px;
            }
            .txt-success-info {
              font-size: 18px;
              line-height: 30px;
              margin-top:10px;
            }
            .plan-info-container {
              flex-direction: row;
              gap: 32px;
              width: 80%;
              justify-content: space-between;
            }
            .plan-info {
              width: auto;
              flex-direction: column;
              gap: 16px;
              aign-items: center;
            }
            .txt-plan, .txt-plan-name, .txt-plan-price {
              margin-bottom: 0;
              height:60px;
              align-content: center;
              width: 100%;
            }
            .txt-plan-name p{
              margin-bottom: 0;
            }
            .btn-section {
              flex-direction: column-reverse;
              gap: 16px;
            }
            .btn-action {
              width: 100%;
            }                        
          }
            
        `}
      </style>
    </div>
  );
};

export default PhotoshootSuccessWeb;
