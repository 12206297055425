/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Breadcrumb,
  Collapse,
  Form,
  Select,
  Radio,
  Skeleton,
} from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  // HomeOutlined,
  RightOutlined,
  LeftOutlined,
  LeftCircleFilled,
  RightCircleFilled,
  // ShareAltOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { productActionCreators } from "../redux/product";
// import { productsActionCreators } from "../redux/products";
import { cartActionCreators } from "../redux/cart";
import { userActionCreators } from "../redux/user";
import { useMediaQuery } from "react-responsive";
// import Reviews from "../components/Reviews";

import formatter from "../utils/formatter";
import colors from "../assets/colors";
import SizeGuideModal from "../components/SizeGuideModal";
import { standardize_color } from "../utils/getColorHex";
import triggerNotification from "../hooks/triggerNotification";
import { postSavedItemToLocalStorage } from "../utils/guestSavedProductsLocal";
import { DetailsSkeleton } from "../components/skeletons";
import { PdfViewer } from "../components/modals";
import usePageTitle from "../hooks/usePageTitle";

const { Option } = Select;
const { Panel } = Collapse;

// const settings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   lazyLoad: "progressive",
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   slidesPerRow: 1,
//   nextArrow: (
//     <div>
//       <div className="next-slick-arrow">
//         <RightCircleFilled style={{ fontSize: 32 }} />
//       </div>
//     </div>
//   ),
//   prevArrow: (
//     <div>
//       <div className="prev-slick-arrow">
//         <LeftCircleFilled style={{ fontSize: 32 }} />
//       </div>
//     </div>
//   ),
// };

const ProductDetails = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  const [currentBigImageKey, setCurrentBigImageKey] = useState(1);

  const [setPageTitle, setPageDescription, setPageKeywords] = usePageTitle();
  const [sizeGuideModalVisible, setSizeGuideModalVisible] = useState(false);
  const [isSizeFrameVisible, setSizeFrameVisible] = useState(false);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const [addToBagBtnLoading, setAddToBagBtnLoading] = useState(false);
  // const [productAddedToBag, setProductAddedToBag] = useState(false);
  const [btnCTA, setBtnCTA] = useState();
  const [btnIcon, setBtnIcon] = useState();

  const { item: product, loading } = useSelector((state) => state.product);
  const { info } = useSelector((state) => state.user);
  const { data } = useSelector((state) => state.currency);

  const { code, indicator, rate } = data
    ? data
    : { indicator: "", code: "", rate: "" };

  const { items: productInCart } = useSelector((state) => state.cart);

  const [selectedColor, setColor] = useState("");

  const { fetchProduct } = bindActionCreators(productActionCreators, dispatch);
  const { postItem } = bindActionCreators(userActionCreators, dispatch);
  const { addToCart, updateProductAlreadyInCart, persistCartItem } =
    bindActionCreators(cartActionCreators, dispatch);
  // const { fetchCategoryProducts } = bindActionCreators(
  //   productsActionCreators,
  //   dispatch
  // );
  const settingsBigScreen = {
    dots: !isTabletOrMobile ? false : true,
    infinite: true,
    speed: 500,
    lazyLoad: "progressive",
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    nextArrow: (
      <div>
        <div className="next-slick-arrow" style={{ color: "black" }}>
          <RightCircleFilled style={{ fontSize: 32 }} />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow" style={{ color: "black" }}>
          <LeftCircleFilled style={{ fontSize: 32 }} />
        </div>
      </div>
    ),
    beforeChange: (current, next) => setCurrentBigImageKey(next + 1),
  };

  const sliderRef = useRef();

  const changeDivBg = (index) => {
    try {
      sliderRef?.current.slickGoTo(index);
      setCurrentBigImageKey(index + 1);
    } catch (error) {
      console.log(error);
    }
  };

  // const shareURL = async () => {
  //   try {
  //     await navigator.clipboard.writeText(window.location.href);
  //     message.success("Product link has been copied to clipboard");
  //   } catch (err) {
  //     message.error("Failed to copy: ", err.message);
  //   }
  // };

  const onSelectColor = (e) => setColor(e.target.value);

  const variantSizeArr = [];
  if (selectedColor) {
    const color = colors.find(
      (color) => color.hex.toLocaleLowerCase() === selectedColor
    );
    product?.variety?.map((variant) => {
      if (variant.color === color.name) variantSizeArr.push(variant.size);
      return true;
    });
  }

  let sizes = product?.sizes;
  if (selectedColor) {
    sizes = variantSizeArr;
  }
  // add product to cart item using deep clones to prevent unintentional modifications of already existing items in cart
  const addProductToCart = (values) => {
    if (btnCTA !== "Add to Bag" && btnCTA !== undefined) {
      if (btnCTA === "View Shopping Bag") {
        navigate("/cart");
      }
      return;
    }

    const selectedColor = colors.find(
      (color) =>
        color?.hex?.toLowerCase() === values?.selectedColor?.toLowerCase()
    );

    const clonedCartItems = JSON.parse(JSON.stringify(productInCart));

    let productAlreadyInCartIndex;

    if (selectedColor) {
      productAlreadyInCartIndex = clonedCartItems.findIndex((cartItem) => {
        return (
          cartItem?.product_id === product?.product_id &&
          cartItem?.selectedColor?.hex?.toLowerCase() ===
            selectedColor?.hex?.toLowerCase() &&
          cartItem?.selectedSize === values?.selectedSize
        );
      });
    } else {
      productAlreadyInCartIndex = clonedCartItems.findIndex((cartItem) => {
        return cartItem?.product_id === product?.product_id;
      });
    }

    if (productAlreadyInCartIndex !== -1) {
      const clonedItemAlreadyInCart = {
        ...clonedCartItems[productAlreadyInCartIndex],
      };
      clonedItemAlreadyInCart.quantityWanted++;
      clonedItemAlreadyInCart.totalPrice =
        clonedItemAlreadyInCart.price * clonedItemAlreadyInCart.quantityWanted;
      updateProductAlreadyInCart(clonedItemAlreadyInCart);
      triggerNotification({
        type: "success",
        message: "Item quantity updated in your shopping bag",
      });
    } else {
      const tempProductForCart = JSON.parse(JSON.stringify(product));
      tempProductForCart.quantityWanted = 1;
      tempProductForCart.totalPrice = JSON.parse(JSON.stringify(product)).price;
      tempProductForCart.selectedColor = selectedColor;
      tempProductForCart.selectedSize = values?.selectedSize;
      addToCart(tempProductForCart);
      triggerNotification({
        type: "success",
        message: "Item has been added to your shopping bag",
      });
      info.token && persistItem(tempProductForCart);
    }

    setBtnCTA("Added to Bag");
    setBtnIcon(
      <svg
        width="21"
        height="15"
        viewBox="0 0 21 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: "12px",
          left: "20px",
        }}
      >
        <path
          d="M18.6403 2L7.49048 13L2.42236 8"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );

    setAddToBagBtnLoading(false);
    afterProductAddedToCart();
  };

  const persistItem = (product) => {
    const {
      product_id,
      quantityWanted: quantity,
      selectedSize: size,
      selectedColor,
      variety,
    } = product;
    const variant_sku = variety.find(
      (variant) => variant.size === size && variant.color === selectedColor.name
    )?.variant_sku;
    persistCartItem(product_id, {
      size,
      quantity,
      color: selectedColor.name,
      variant_sku,
    });
  };

  const afterProductAddedToCart = () => {
    const timer = setTimeout(() => {
      setBtnCTA("View Shopping Bag");
      setBtnIcon(undefined);
    }, 2000);

    const backToAddtimer = setTimeout(() => {
      setBtnCTA("Add to Bag");
      setBtnIcon(undefined);
    }, 5000);
    return () => {
      clearTimeout(timer);
      clearTimeout(backToAddtimer);
    };
  };

  const images = [];
  if (product?.img1) images.push({ imageURL: product.img1, imageKey: 1 });
  if (product?.img2) images.push({ imageURL: product.img2, imageKey: 2 });
  if (product?.img3) images.push({ imageURL: product.img3, imageKey: 3 });
  if (product?.img4) images.push({ imageURL: product.img4, imageKey: 4 });

  const saveItem = async (id) => {
    if (info.token) {
      const res = await postItem([id]);
      if (res.status === "ok")
        triggerNotification({
          type: "success",
          message: res.message,
        });
    } else {
      postSavedItemToLocalStorage(product.slug, code);
      triggerNotification({
        type: "success",
        message: "Item has been saved successfully",
      });
    }
    // if (res.status === "Not ok")
    //   message.error("Please login to add item to your wishlist");
  };

  // const shopCategory = (id) => {
  //   fetchCategoryProducts(id);
  // };

  const shopBrand = (slug) => {
    navigate(`/designers/${slug}`);
  };

  product?.colors?.length === 1 &&
    selectedColor === "" &&
    setColor(standardize_color(product?.colors[0]));

  useEffect(() => {
    fetchProduct(slug, code);
  }, [slug, code]);

  const handleUpdate = () => {
    setPageTitle(product?.meta_data?.page_title);
    setPageDescription(product?.meta_data?.page_description);
    setPageKeywords(product?.meta_data?.page_keywords);
  };

  useEffect(() => {
    handleUpdate();
  }, [product]);

  useEffect(() => {
    setColor("");
    form.resetFields();
    form.setFieldsValue({
      ...(product?.colors?.length === 1 && {
        selectedColor: standardize_color(product?.colors[0]),
      }),
      ...(product?.sizes?.length === 1 && { selectedSize: product.sizes[0] }),
    });
  }, [form, product]);

  const settingsForOtherProducts = {
    dots: isTabletOrMobile,
    infinite: true,
    speed: 500,
    lazyLoad: "progressive",
    slidesToShow: !isTabletOrMobile
      ? product?.items_you_may_like?.length < 5
        ? product?.items_you_may_like?.length
        : 4
      : product?.items_you_may_like?.length < 3
      ? product?.items_you_may_like?.length
      : 2,
    slidesToScroll: 1,
    // slidesPerRow: 1,
    rows: 1,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <RightOutlined style={{ fontSize: 20 }} />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <LeftOutlined style={{ fontSize: 20 }} />
        </div>
      </div>
    ),
  };

  const { type } = useSelector((state) => state.user);

  const categoryRoutes = {
    clothing: {
      women: `/${type.toLowerCase()}/subcategory/clothing/cid=6?page=1`,
      men: `/${type.toLowerCase()}/subcategory/clothing/cid=5?page=1`,
    },
    accessories: {
      women: `/${type.toLowerCase()}/subcategory/accessories/cid=10?page=1`,
      men: `/${type.toLowerCase()}/subcategory/accessories/cid=9?page=1`,
    },
    footwear: {
      women: `/${type.toLowerCase()}/subcategory/shoes/cid=8?page=1`,
      men: `/${type.toLowerCase()}/subcategory/shoes/cid=7?page=1`,
    },
    [`skin and beauty`]: {
      women: `/${type.toLowerCase()}/subcategory/beauty/cid=3?page=1`,
    },
  };

  function convertArrayStringToString(arrayString) {
    // Check if the input is already a simple string without brackets or quotes
    if (
      !arrayString.includes("[") &&
      !arrayString.includes("]") &&
      !arrayString.includes('"')
    ) {
      return arrayString.trim();
    }

    // Remove the square brackets
    let withoutBrackets = arrayString.replace(/[[\]]/g, "");

    // Remove the double quotes only, preserving single quotes that may be part of the content
    let withoutQuotes = withoutBrackets.replace(/["]/g, "");

    // Trim any extra whitespace and return
    return withoutQuotes.trim();
  }

  return (
    <div>
      <div>
        {!isTabletOrMobile && (
          <>
            {loading ? (
              <Skeleton.Input
                active
                className="breadcrumb-container"
                style={{ width: "300px", height: "22px" }}
              />
            ) : (
              <Breadcrumb
                className="breadcrumb-container"
                separator={<RightOutlined style={{ fontSize: "10px" }} />}
              >
                <Breadcrumb.Item href="/">
                  {/* <HomeOutlined /> */}
                  Home
                </Breadcrumb.Item>
                {/* <Breadcrumb.Item
                  style={{ fontFamily: "DomaineSansText-Regular" }}
                  onClick={() => shopCategory()}
                >
                  {product?.category_info?.main_category_name}
                </Breadcrumb.Item> */}
                <Breadcrumb.Item
                  style={{ fontFamily: "DomaineSansText-Regular" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(
                      categoryRoutes[
                        product?.category_info?.submain_category_name?.toLowerCase()
                      ]?.[type?.toLowerCase()]
                    );
                  }}
                  href={
                    categoryRoutes[
                      product?.category_info?.submain_category_name?.toLowerCase()
                    ]?.[type?.toLowerCase()]
                  }
                >
                  {product?.category_info?.submain_category_name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  style={{ fontFamily: "DomaineSansText-Regular" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(
                      `/products/category/cid=${product?.category_info?.subchild_category_id}&name=${product?.category_info?.subchild_category_name}?page=1`
                    );
                  }}
                  href={`/products/category/cid=${product?.category_info?.subchild_category_id}&name=${product?.category_info?.subchild_category_name}?page=1`}
                >
                  {product?.category_info?.subchild_category_name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  style={{ fontFamily: "DomaineSansText-Regular" }}
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   e.stopPropagation();
                  //   navigate(`/product/${product?.slug}`)
                  // }}
                  // href={`/product/${product?.slug}`}
                >
                  {product?.name}
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          </>
        )}
        {loading ? (
          <DetailsSkeleton
            settingsBigScreen={settingsBigScreen}
            settingsForOtherProducts={settingsForOtherProducts}
          />
        ) : (
          <>
            <div className="product-container">
              <div className="product-image-more-text-contain">
                <div className="product-images-supercontainer">
                  <div className="other-img-container">
                    {images?.map((image, index) => (
                      <div
                        alt="product"
                        className="img"
                        onClick={() => changeDivBg(index)}
                        style={{
                          backgroundImage: `url(${image?.imageURL})`,
                          cursor: "pointer",
                          border: `${
                            image.imageKey === currentBigImageKey
                              ? "3px solid #4d4d4d"
                              : ""
                          }`,
                        }}
                      />
                    ))}
                  </div>

                  <div
                    style={{
                      gridColumn: "2 / 6",
                      marginLeft: !isTabletOrMobile ? "40px" : 0,
                    }}
                  >
                    <Slider {...settingsBigScreen} ref={sliderRef}>
                      {images.map((image) => (
                        <div>
                          <div
                            key={image?.imageURL}
                            style={{
                              background: `url(${image?.imageURL})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "top",
                              backgroundSize: "cover",
                              height: !isTabletOrMobile
                                ? "clamp(200px, 48vw, 1000px)"
                                : "65vh",
                            }}
                          ></div>
                          <div className="mobile-item-code-product">
                            Item Code: {product?.product_id}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
                {!isTabletOrMobile && (
                  <div className="product-more-text-contain-container">
                    <div className="product-more-text-contain">
                      <div className="product-more-text-contain-title">
                        OTHER CATEGORIES FROM{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => shopBrand(product?.store_info?.slug)}
                        >
                          {product?.store_info?.store_name}
                        </span>
                      </div>
                      <div className="product-more-text-contain-details">
                        {product?.store_info?.store_brandCategory?.map(
                          (category) => (
                            <div>{convertArrayStringToString(category)}</div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="details-container">
                <div style={{ width: !isTabletOrMobile ? "70%" : "100%" }}>
                  {/* <div className="low-stock-text">LOW STOCK</div> */}
                  <div
                    className="product-store-name"
                    onClick={() => shopBrand(product?.store_info?.slug)}
                  >
                    {product?.store_info?.store_name}
                  </div>
                  <h2 className="product-title">{product?.name}</h2>
                  <div className="price-container">
                    {/* <span className="single-price">
                  {formatter(
                    product?.price,
                    currency?.rate,
                    currency?.code,
                    currency?.indicator
                  )}
                </span> */}
                    {product?.has_discount &&
                      product?.oldPrice &&
                      Date.now() >= product?.discount_start_date &&
                      Date.now() < product?.discount_end_date && (
                        <span className="old-price">
                          {formatter(product?.oldPrice, rate, code, indicator)}
                        </span>
                      )}
                    <div className="discount-price">
                      {formatter(product?.price, rate, code, indicator)}
                    </div>
                  </div>
                  <Form
                    className="add-to-cart-form"
                    form={form}
                    name="add-to-cart"
                    onFinish={addProductToCart}
                    scrollToFirstError
                  >
                    {product?.sizes?.length > 0 && (
                      <div>
                        <div>
                          Color:
                          {product?.colors?.map((color, idx) => (
                            <span key={idx}> {color}</span>
                          ))}
                        </div>

                        <Form.Item
                          name="selectedColor"
                          rules={[
                            {
                              required: true,
                              message: "Please select a color",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={onSelectColor}
                            style={{ display: "flex" }}
                            className="colors-radiogroup"
                          >
                            {product?.colors?.map((color) => (
                              <Radio.Button
                                key={color}
                                style={{
                                  border: "1px solid #E6E6E6",
                                  display: "grid",
                                  placeContent: "center",
                                  height: 35,
                                  marginTop: 4,
                                  marginRight: 10,
                                  width: 35,
                                  borderRadius: "50%",
                                }}
                                value={standardize_color(color)}
                              >
                                <div
                                  className="color-box"
                                  style={{
                                    background: standardize_color(color),
                                  }}
                                />
                              </Radio.Button>
                            ))}
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    )}

                    {product?.category_info?.submain_category_name?.toLowerCase() !==
                      "accessories" &&
                      product?.category_info?.submain_category_name?.toLowerCase() !==
                        "skin and beauty" && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>Size:</div>
                            <div
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                product?.size_guide_url
                                  ? setSizeFrameVisible(true)
                                  : setSizeGuideModalVisible(true);
                              }}
                            >
                              view size guide
                            </div>
                          </div>
                          <div>
                            {!isTabletOrMobile ? (
                              <Form.Item
                                name="selectedSize"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a size",
                                  },
                                ]}
                              >
                                <Radio.Group
                                  className="sizes-radiogroup"
                                  disabled={!selectedColor}
                                  style={{
                                    display: "flex",
                                    gap: "8px",
                                    flexWrap: "wrap",
                                    marginTop: "4px",
                                  }}
                                >
                                  {sizes?.map((size) => (
                                    <Radio.Button
                                      value={size}
                                      key={size}
                                      style={{
                                        border: "1px solid #E6E6E6",
                                        display: "grid",
                                        placeContent: "center",
                                        height: 40,
                                        width: "31%",
                                        lineHeight: "normal",
                                      }}
                                    >
                                      <div className="size-box">{size}</div>
                                    </Radio.Button>
                                  ))}
                                </Radio.Group>
                              </Form.Item>
                            ) : (
                              <Form.Item
                                name="selectedSize"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select a size",
                                  },
                                ]}
                              >
                                <Select
                                  className="product-page-mobile-size-select"
                                  disabled={!selectedColor}
                                  placeholder="Select Size"
                                >
                                  {sizes?.map((size) => (
                                    <Option value={size} key={size}>
                                      {size}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}
                          </div>
                        </div>
                      )}

                    <div style={{ marginTop: 40 }}>
                      <Button
                        className="btn-product bag-button"
                        style={{
                          color: "white",
                          background:
                            btnCTA === "View Shopping Bag"
                              ? "#00000080"
                              : "black",
                          borderColor:
                            btnCTA === "View Shopping Bag"
                              ? "transparent"
                              : "black",
                          width: "100%",
                          height: 48,
                          marginBottom: "8px",
                        }}
                        loading={addToBagBtnLoading}
                        icon={
                          btnIcon || (
                            <svg
                              style={{
                                position: "absolute",
                                top: "12px",
                                left: "20px",
                              }}
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.4785 19.9999H0.521526L0 19.5057L2.0231 5.42339L2.54463 5.03516H11.4491V5.91751H3.01015L1.11306 19.1175H18.8939L17.2804 5.91751H16.6083V5.03516H17.7529L18.2779 5.43221L20 19.5146L19.4785 19.9999Z"
                                fill="white"
                              />
                              <path
                                d="M14.8863 6.87059H13.1362V2.36176L12.0756 1.47059H7.92441L6.86386 2.36176V4.25294H5.11377V1.75294L7.19637 0H12.8002L14.8863 1.75294V6.87059Z"
                                fill="white"
                              />
                              <path
                                d="M14.5432 8.55613H13.4756L12.6006 7.82084V6.92084L13.4756 6.18555H14.5432L15.4182 6.92084V7.82084L14.5432 8.55613Z"
                                fill="white"
                              />
                            </svg>
                          )
                        }
                        htmlType="submit"
                      >
                        {btnCTA ||
                          (!addToBagBtnLoading
                            ? "Add to Bag"
                            : "Adding item to Bag")}
                      </Button>
                      <Button
                        className="btn-product"
                        style={{
                          color: "black",
                          background: "white",
                          width: "100%",
                          height: 48,
                        }}
                        icon={
                          <img
                            alt="save"
                            src="/images/icon-wishlist.svg"
                            style={{
                              position: "absolute",
                              top: "14px",
                              left: "20px",
                            }}
                          />
                        }
                        onClick={() => saveItem(product.product_id)}
                      >
                        Save Item
                      </Button>
                    </div>
                    {/* <ShareAltOutlined className="btn-share" onClick={shareURL} /> */}
                  </Form>
                  <div className="product-details-collapsible">
                    <Collapse
                      expandIconPosition="right"
                      defaultActiveKey={["0", "1", "2", "3", "4"]}
                      ghost
                    >
                      <Panel
                        header="Item Description"
                        className="panel-heading"
                        key="0"
                      >
                        <p
                          className="txt-product-desc"
                          style={{ fontWeight: "bold", marginBottom: "8px" }}
                        >
                          {product?.name}
                        </p>
                        <p
                          className="txt-product-desc"
                          style={{ textAlign: "justify" }}
                        >{`${product?.description}`}</p>
                      </Panel>
                      {product?.size_and_fit && (
                        <Panel
                          header="Size And Fit"
                          className="panel-heading"
                          key="1"
                        >
                          <ul>
                            <li className="txt-product-desc">{`${product?.size_and_fit}`}</li>
                          </ul>
                        </Panel>
                      )}
                      <Panel
                        header="Occasion"
                        className="panel-heading"
                        key="2"
                      >
                        <ul>
                          {product?.occassion_type?.map((occassion) => (
                            <li
                              className="txt-product-desc"
                              style={{ marginRight: 10 }}
                            >
                              {occassion}
                            </li>
                          ))}
                        </ul>
                      </Panel>
                      <Panel
                        header="Material And Care Info"
                        className="panel-heading"
                        key="3"
                      >
                        <p
                          className="txt-product-desc"
                          style={{ whiteSpace: "normal" }}
                        >
                          {product?.detail_and_care}
                        </p>
                      </Panel>
                      <Panel
                        header="Returns Policy"
                        className="panel-heading"
                        key="4"
                      >
                        <ul>
                          {product?.return_type?.map((txt) => (
                            <li
                              className="txt-product-desc"
                              style={{ marginRight: 10 }}
                            >
                              {txt}
                            </li>
                          ))}
                        </ul>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>

              {isTabletOrMobile && (
                <div className="product-more-text-contain">
                  <div className="product-more-text-contain-title">
                    OTHER CATEGORIES FROM{" "}
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => shopBrand(product?.store_info?.slug)}
                    >
                      {product?.store_info?.store_name}
                    </span>
                  </div>
                  <div className="product-more-text-contain-details">
                    {product?.store_info?.store_brandCategory?.map(
                      (category) => (
                        <div>{convertArrayStringToString(category)}</div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>

            {product?.items_you_may_like?.length > 0 && (
              <div className="other-products-container">
                <div className="other-products-header">
                  <div className="other-products-header-title">
                    YOU MAY ALSO BE INTERESTED IN THESE ITEMS
                  </div>
                </div>
                <div className="other-products-body">
                  <Slider {...settingsForOtherProducts}>
                    {product?.items_you_may_like?.map((item) => (
                      <div className="other-products-card">
                        <Link to={`/product/${item.slug}`}>
                          <img
                            className="other-products-image"
                            alt="brand"
                            src={item.img1}
                          />
                        </Link>
                        <div className="other-products-item-container">
                          <div className="other-products-store-name">
                            {item.store_name}
                          </div>
                          <div
                            className="other-products-product-name"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.name}
                          </div>
                          <div
                            className="other-products-product-price"
                            style={{ fontWeight: "bold" }}
                          >
                            {formatter(item.price, rate, code, indicator)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            )}
          </>
        )}
        {/* 
        {isTabletOrMobile && (
          <div style={{ textAlign: "right", padding: "0 16px" }}>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_7557_19900)">
                <circle cx="24" cy="20" r="20" fill="black" />
                <path
                  d="M20 17.2683H20.01H20ZM24 17.2683H24.01H24ZM28 17.2683H28.01H28ZM21 24.5366H17C16.4696 24.5366 15.9609 24.2813 15.5858 23.827C15.2107 23.3726 15 22.7564 15 22.1138V12.4228C15 11.7802 15.2107 11.164 15.5858 10.7096C15.9609 10.2553 16.4696 10 17 10H31C31.5304 10 32.0391 10.2553 32.4142 10.7096C32.7893 11.164 33 11.7802 33 12.4228V22.1138C33 22.7564 32.7893 23.3726 32.4142 23.827C32.0391 24.2813 31.5304 24.5366 31 24.5366H26L21 30.5935V24.5366Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_7557_19900"
                  x="0"
                  y="0"
                  width="48"
                  height="48"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_7557_19900"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_7557_19900"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        )} */}

        {/* <div className="other-products">
            <h2 className="txt-title">Other Items you should look at</h2>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {products.map((product) => (
                <div key={product.id}>
                  <img
                    alt="product"
                    src={product.imageUrl}
                    style={{ height: 400, objectFit: "contain" }}
                  />
                  <div>
                    <h4 style={{ marginTop: 10 }}>{product.brand}</h4>
                    <h4 style={{ color: "#6F6F6F" }}>{product.name}</h4>
                    <h4>{product.price}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        {/* <div className="style-container">
        <h2 className="txt-title">Style the look</h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {styles.map((style) => (
            <div
              style={{
                background: "#fff",
                padding: "30px",
                marginRight: 25,
                width: 300,
              }}
            >
              <div
                alt="styles"
                style={{
                  height: 330,
                  backgroundImage: `url(${style.imageUrl})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <h4 style={{ marginTop: 20 }}>{style.brand}</h4>
              <h4 style={{ fontWeight: 350, wordSpacing: 1.28 }}>
                {style.name}
              </h4>
            </div>
          ))}
        </div>
      </div> */}
        {/* <div className="reviews">
            <h2 className="txt-title" style={{ textAlign: "center" }}>
              Reviews
            </h2>
            <Reviews />
          </div> */}
      </div>
      {isSizeFrameVisible && (
        <PdfViewer cloudinaryUrl={product?.size_guide_url} />
      )}
      <SizeGuideModal
        sizeGuideModalVisible={sizeGuideModalVisible}
        setSizeGuideModalVisible={setSizeGuideModalVisible}
        productName={product?.name}
        designerName={product?.store_info?.store_name}
        productImage={images[0]}
        productSubcategory={product?.category_info?.submain_category_name?.toLowerCase()}
        version={
          product?.category_info?.main_category_name === "Women"
            ? "womenGuide"
            : "Men"
            ? "menGuide"
            : "Women"
        }
      />

      <style jsx="true">
        {`
          .breadcrumb-container {
            text-align: left;
            margin-top: 8px;
            margin-left: 16px;
          }
          .ant-breadcrumb-link {
            cursor: pointer;
          }
          .product-container {
            margin: 40px 60px;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
          .product-images-supercontainer {
            display: grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
          }
          .other-img-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin-top: 40px;
          }
          .img {
            background-size: cover;
            background-repeat: no-repeat;
            width: 75px;
            height: 100px;
          }
          // .img-container {
          //   background-size: contain;
          //   background-repeat: no-repeat;
          //   flex: 3;
          //   height: 800px;
          // }
          .img-big-screen {
            object-fit: contain;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          .details-container {
            flex: 2;
            display: flex;
            justify-content: center;
            font-family: "DomaineSansText-Light";
          }
          .product-store-name {
            cursor: pointer;
            text-transform: uppercase;
            font-size: 32px;
            line-height: 40px;
            font-family: "DomaineSansText-Regular";
            margin-bottom: 8px;
          }
          .product-store-name:hover {
            opacity: 0.7;
          }
          .product-title {
            font-size: 16px;
            margin-bottom: 8px;
          }
          .price-container {
            margin-bottom: 24px;
          }
          .single-price {
            font: normal normal normal 16px DomaineSansText-Regular;
          }
          .old-price {
            text-decoration: line-through;
            font: normal normal normal 16px DomaineSansText-Regular;
            color: #800000;
          }
          .discount-price {
            font: normal normal normal 16px DomaineSansText-Regular;
            color: #000000;
          }
          .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
          }

          .colors-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked {
            border: 1px solid #000;
            border-radius: 50%;
          }
          .sizes-radiogroup
            .ant-radio-button-wrapper
            > .ant-radio-button-checked {
            border: 1px solid #000;
          }
          .color-box {
            border: 1px solid #cccc;
            height: 26px;
            margin: 6px auto;
            width: 26px;
            border-radius: 50%;
          }
          .size-box {
            color: black;
            font-size: 12px;
          }
          .btn-product {
            width: 130px;
            height: 40px;
            margin-right: 15px;
            // transition: 0.5s;
            // box-shadow: inset 0px 1px 0 rgb(0 0 0 / 5%)
          }
          // .btn-product.bag-button:hover{
          //   box-shadow: inset 20em 0 0 0 black;
          //   color: white;
          //   border: 6px solid black
          //   // transition:1s
          // }
          .btn-share {
            font-size: 30px;
            color: #b7b7b7;
            cursor: pointer;
            margin-top: 20px;
          }
          .btn-share:hover {
            color: #000;
          }
          .product-details-collapsible {
            margin-top: 40px;
          }
          .product-details-collapsible .ant-collapse-header {
            padding-left: 0px !important;
          }
          .product-details-collapsible ul {
            padding-inline-start: 17px !important;
          }
          .product-details-collapsible
            .ant-collapse-icon-position-end
            > .ant-collapse-item
            > .ant-collapse-header
            .ant-collapse-arrow {
            right: 0px !important;
          }
          .list-item {
            font: italic normal normal 12px DomaineSansText-Regular;
          }
          .brand-details-container {
          }
          .txt-brand-title {
            font: normal bold 16px/18px DomaineSansText-Regular;
          }
          .panel-heading {
            font: normal 600 16px/18px DomaineSansText-Light;
          }
          .txt-product-desc {
            font: normal normal 14px DomaineSansText-Light;
            line-height: 22px;
            white-space: pre-line;
          }
          .txt-title {
            font: normal normal bold 30px DomaineSansText-Regular;
            color: #000000;
            text-transform: uppercase;
            padding-bottom: 1em;
          }
          .style-container {
            background: #eeeeee;
            padding-top: 4em;
            padding-left: 5em;
            padding-right: 5em;
            padding-bottom: 5em;
          }
          .reviews {
            margin-top: 3em;
          }
          .returns-status {
            font: normal normal bold 20px DomaineSansText-Regular;
          }
          .returns-title,
          .returns-terms {
            font: normal normal 11px DomaineSansText-Regular;
            color: #b7b7b7;
          }
          .returns-terms {
            font-size: 10px;
          }
          .arrow-image-change {
            background: #fff;
            float: right;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-top: 70%;
            margin-right: 30px;
            text-align: center;
          }
          .arrow-image-change:hover {
            background: maroon;
            // border-radius: 50%;
            // padding: 10px;
            color: white;
            transition: 0.5s;
          }
          .slick-slider div {
            height: 100%;
          }
          .slick-arrow {
            height: auto !important;
          }
          .low-stock-text {
            color: #d18f8f;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 4px;
          }
          .ant-radio-button-wrapper-checked:not(
              .ant-radio-button-wrapper-disabled
            )::before {
            background-color: transparent;
          }
          .ant-collapse-icon-position-right
            > .ant-collapse-item
            > .ant-collapse-header {
            padding: 12px 0 !important;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box {
            padding: 0px !important;
          }
          .product-more-text-contain-container {
            grid-template-columns: repeat(5, minmax(0, 1fr));
            display: grid;
          }
          .product-more-text-contain {
            font-family: "DomaineSansText-Light";
            font-size: 14px;
            margin-top: 24px;
            grid-column: 2 / 6;
            margin-left: 40px;
          }
          .product-more-text-contain-title {
            text-transform: uppercase;
            font-family: "DomaineSansText-Regular";
          }
          .product-more-text-contain-details {
            display: flex;
            gap: 16px;
            margin-top: 8px;
          }
          .other-products-container {
    max-width: 80%;
    padding-left: 60px;
    margin: 80px auto 120px;
          }
          .other-products-header-title {
            text-transform: uppercase;
            font-family: "DomaineSansText-Regular";
            font-size: 16px;
            margin-bottom: 16px;
            padding-left: 16px;
          }
          .slick-next:before,
          .slick-prev:before {
            font-size: 2.5vw;
            display: none;
          }
          .slick-arrow {
            z-index: 2;
            width: auto;
            text-align: center;
          }
          .slick-next {
            right: 3%;
          }
          .slick-prev {
            left: 3%;
          }
          .other-products-container .slick-arrow {
            top: -34px;
          }
          .other-products-container svg {
            color: black;
          }
          .other-products-container .slick-prev {
            right: -80%;
          }
          .other-products-container .slick-next {
            right: 20px;
          }
          .other-products-card {
            padding: 0 16px;
          }
          .other-products-image {
            object-fit: cover;
            object-position: top;
            // width: 230px;
            // height: 400px;
            width: 100%;
          }
          .other-products-item-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 16px;
            font-family: "DomaineSansText-Light";
          }
          .other-products-store-name {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 800;
            font-family: "DomaineSansText-Regular";
            overflow-wrap: break-word;
          }
          .other-products-header-title {
            font-size: 14px;
          }
          .mobile-item-code-product {
            color: #b5b5b5;
            margin-top: 4px;
            font-family: "DomaineSansText-Light";
            font-size: 12px;
          }
          .other-products-container .slick-slider .slick-track,
          .other-products-container .slick-slider .slick-list {
            // width: 80vw;
          }

          ${
            product?.items_you_may_like?.length < 5 &&
            `
          .other-products-body .slick-slide{
            width: fit-content !important;
          }`
          }}

          @media (min-width: 1445px) {
            .other-products-image {
              // width: 300px;
              height: 500px;
            }
          }

          @media (max-width: 640px) {
            .product-container {
              margin: 16px;
              display: block;
            }
            .other-img-container {
              display: none;
            }
            .product-images-supercontainer {
              display: block;
            }

            .slick-slider div {
              width: 100%;
            }
            .slick-arrow {
              width: auto !important;
            }
            .details-container {
              justify-content: flex-start;
              margin-top: 30px;
            }
            .product-store-name {
              font-size: 24px;
              text-decoration: underline;
              line-height: normal;
            }
            .product-title {
              font-size: 14px;
            }
            .old-price,
            .single-price,
            .discount-price {
              font-size: 14px;
            }
            .product-page-mobile-size-select.ant-select-single
              .ant-select-selector
              .ant-select-selection-item {
              line-height: 48px;
            }
            .product-page-mobile-size-select.ant-select-single:not(
                .ant-select-customize-input
              )
              .ant-select-selector {
              height: 48px;
            }
            .product-page-mobile-size-select
              .ant-select-item-option-selected:not(
                .ant-select-item-option-disabled
              ) {
              background-color: #f5f5f5;
            }
            .panel-heading {
              font-size: 14px;
            }

            .product-more-text-contain {
              font-size: 12px;
              margin-left: 0px;
            }
            .other-products-image {
              height: 248px;
              width: 100%;
              margin: auto;
              padding-right: 12px;
            }
            .other-products-item-container {
              padding-right: 12px;
            }
            .other-products-container {
              margin-top: 40px;
              max-width: 100%;
              padding-left:10px;
            }
            .other-products-card {
              padding: 0;
              max-width: 250px;
            }
            .other-products-header-title {
              font-size: 12px;
              padding-left: 0px;
            }
            .other-products-container .slick-arrow {
              display: none !important;
            }
            .other-products-container .slick-slider .slick-track,
            .other-products-container .slick-slider .slick-list {
              width: auto;
            }
            ${
              product?.items_you_may_like?.length < 3 &&
              `
            .other-products-body .slick-slide{
              width: fit-content !important;
            }`
            }}
          }
        `}
      </style>
    </div>
  );
};

export default ProductDetails;
