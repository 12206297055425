import { useMediaQuery } from "react-responsive";
import StoreSettingsWeb from "./StoreSettingsWeb";
import StoreSettingsMobile from "./StoreSettingsMobile";

const StoreSettings = () => {

    const isMobile = useMediaQuery({ maxWidth: 640 });

    return(
        !isMobile ? 
        <StoreSettingsWeb /> : 
        <StoreSettingsMobile />
    )
}

export default StoreSettings;