// import { useEffect } from "react";
import { Button, Form, Input, Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { userActionCreators } from "../redux/user";
import { useState, useEffect } from "react";
// import { UpOutlined } from "@ant-design/icons";
import triggerNotification from "../hooks/triggerNotification";
import { cartActionCreators } from "../redux/cart";

const BuyerSignIn = ({ changeTab, isModalVisible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { isAuthenticated, info } = useSelector((state) => state.user);

  const { loginBuyer } = bindActionCreators(userActionCreators, dispatch);
  const { fetchUserCart } = bindActionCreators(cartActionCreators, dispatch);

  const onFinish = async (values) => {
    const res = await loginBuyer(values);
    if (res.status === "ok") {
      isModalVisible(false);
      fetchUserCart();
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const [whatsThisState, setWhatsThisState] = useState("none");

  useEffect(() => {
    if (isAuthenticated && info.role === "1") {
      navigate("/account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  return (
    <div>
      <div className="signin-seller-container">
        <div className="signin-register-container">
          <div className="form-container">
            <Form
              form={form}
              name="login"
              onFinish={onFinish}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
            >
              <div className="signin-inputs-container">
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please use an acceptable email format",
                    },
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="signin-input" placeholder="Email Address" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input.Password
                    className="signin-input"
                    placeholder="Password"
                    type="password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="signin-btn-register"
                    type="primary"
                    htmlType="submit"
                  >
                    Sign In
                  </Button>
                </Form.Item>
                <div className="seller-signin-other-options">
                  <Link
                    to="/reset-password"
                    className="signin-txt-signin"
                    onClick={() => isModalVisible(false)}
                  >
                    <strong>Forgot password?</strong>
                  </Link>
                  <p className="signin-txt-signin">
                    New to Losode, register{" "}
                    <span
                      onClick={() => changeTab("2")}
                      className="signin-txt-signin"
                    >
                      <strong
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        here
                      </strong>
                    </span>
                  </p>
                </div>
                <p
                  className="signin-txt-signin"
                  style={{ textAlign: "left", marginTop: "12px" }}
                >
                  {" "}
                  <Checkbox /> Keep me signed in.{" "}
                  <div
                    onClick={() => setWhatsThisState("block")}
                    style={{
                      textDecoration: "underline",
                      display: "inline-block",
                      cursor: "pointer",
                    }}
                  >
                    What's this?
                  </div>
                  <div
                    style={{
                      display: `${whatsThisState}`,
                      marginTop: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    Select this option to easily access your account. Sign out
                    any time you want. We do not recommend this option if you
                    are using a shared or public device
                    <div
                      onClick={() => setWhatsThisState("none")}
                      style={{
                        textDecoration: "underline",
                        marginTop: "8px",
                        cursor: "pointer",
                      }}
                    >
                      Show less
                      {/* <UpOutlined /> */}
                    </div>
                  </div>
                </p>
              </div>
            </Form>
          </div>
          <div className="signin-img-container" />
        </div>
      </div>

      <style jsx="true">
        {`
          .signin-seller-container {
            flex-direction: column-reverse;
            height: 80vh;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .signin-img-container {
            background-image: url("/images/olan.jpeg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            margin: 0 24px;
            height: 300px;
          }
          .seller-auth-img-txt-bg {
            width: 100%;
            height: 100%;
            background: #0000006e;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            text-align: center;
            font-family: "DomaineSansText-Regular";
          }
          .seller-auth-img-txt {
            width: 85%;
            font-size: 20px;
          }
          .signin-register-container {
            text-align: center;
            justify-content: center;
            margin: 10px 0;
          }
          .signin-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
            text-align: center;
          }

          .signin-inputs-container {
            margin: 0 24px;
          }
          .signin-input {
            background-color: #fff;
            color: #000;
            font-size: 16px;
            height: 60px;
            border: 1px solid #808080;
            font-weight: 400;
            width: 100%;
          }
          .ant-form-item-explain.ant-form-item-explain-error {
            text-align: left;
            margin-top: 4px;
          }

          .signin-input:focus,
          .signin-input:hover {
            border: 2px solid #000;
          }
          .signin-btn-register {
            font: normal 16px DomaineSansText-Regular;
            width: 100%;
            height: 60px;
            margin-top: 1px;
          }
          .seller-signin-other-options {
            display: flex;
            justify-content: space-between;
          }
          .signin-txt-signin {
            font: normal normal 14px DomaineSansText-Light;
            color: #000;
            margin-top: 0px;
            text-align: center;
            text-decoration: none;
          }
          .signin-txt-setup {
            font: normal 14px/20px DomaineSansText-Regular;
            color: #000;
            padding: 0 24px;
            text-align: center;
          }

          input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px white inset;
          }

          @media screen and (max-width: 600px) {
            .signin-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }
            .signin-img-container {
              display: block !important;
              height: 220px;
            }
            .seller-auth-img-txt {
              width: 85%;
              font-size: 16px;
            }
            .ant-form-item-explain,
            .ant-form-item-extra {
              font-size: 12px;
            }
            .signin-register-container {
              background: white;
              margin: 0;
              max-width: 100%;
              width: 100% !important;
              padding: 18px 11px;
            }
            .signin-regiseter-seller-contents {
              padding: 0;
            }
            .input,
            .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector,
            .signin-btn-register,
            .signin-date,
            .ant-picker-input > input {
              margin-top: 0 !important;
              font-size: 14px !important;
            }
            .ant-form-vertical .ant-form-item-label > label {
              font-size: 12px !important;
            }
            .ant-form-item {
              margin-bottom: 16px !important;
            }
            .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
            .ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              display: flex;
              padding: 0 15px;
              align-items: center;
            }
            .ant-select-arrow {
              top: 53%;
              height: 10px;
              width: 16px;
            }
            .signin-txt-setup {
              font-size: 12px !important;
              width: 100%;
              line-height: 18px !important;
            }
            .signin-txt-register {
              margin-bottom: 8px;
              font-size: 24px !important;
            }
            .signin-txt-signin {
              font: normal normal 12px/14px DomaineSansText-Regular !important;
            }
            .signin-txt-terms {
              font: normal normal 12px/16px DomaineSansText-Regular !important;
            }

            .signin-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }

            .auth-modal-link {
              font-size: 12px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default BuyerSignIn;
