import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthModal from "./AuthModal";

const SellMenu = ({ showSellMenu, hideSellMenu, visible }) => {
  const navigate = useNavigate();

  const [sellerAuthModalVisible, setSellerAuthModalVisible] = useState(false);

  const { menuBrands: data } = useSelector((state) => state.brands);

  const brands = data?.brands;

  const shopBrands = (slug) => {
    navigate(`/designers/${slug}`);
    hideSellMenu();
  };

  return (
    <>
      <AuthModal
        authModalVisible={sellerAuthModalVisible}
        setAuthModalVisible={setSellerAuthModalVisible}
        activePage="2"
        pageUserType="seller"
      />
      <div className="sell-menu-container">
        <div className="menu-overlay" />
        <div
          className="sell-content"
          onMouseEnter={() => showSellMenu()}
          onMouseLeave={() => hideSellMenu()}
        >
          <div className="subs-sell-cat-container">
            <div className="subs-sell-cat">
              <h3 className="cat-title">Sell on Losode</h3>
              <div className="">
                <a
                  className="sub-item"
                  href="##"
                  onClick={() => setSellerAuthModalVisible(true)}
                >
                  Create a store
                </a>
                <a
                  className="sub-item"
                  rel="noopener noreferrer"
                  href="/book-photoshoot"
                >
                  Book A Photoshoot
                </a>
                <a
                  className="sub-item"
                  href="##"
                  onClick={() => setSellerAuthModalVisible(true)}
                >
                  List an item
                </a>
                {/* <a
                  className="sub-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://losode.sharepoint.com/:f:/s/LOSODE/EnKK-OhUs2RIi4QRF86Gzm8B1dcIhAuciEXJGQwO8rLC6A?e=RdaEYM"
                >
                  Designer Kit
                </a> */}
                <a
                  className="sub-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="###"
                >
                  Buy our Losode bag
                </a>
              </div>
            </div>
            <div className="subs-sell-left-div">
              <h3 className="cat-title">FEATURED DESIGNERS</h3>
              <div className="">
                <h3
                  className="designer-item"
                  onClick={() => navigate("/designers")}
                  style={{ color: "#800000" }}
                >
                  Designers A to Z
                </h3>
                {brands?.slice(0, 7).map((brand, idx) => (
                  <h3
                    className="designer-item"
                    onClick={() => shopBrands(brand.slug)}
                    key={idx}
                  >
                    {brand.store_name}
                  </h3>
                ))}
              </div>
            </div>
            {/* <div className="designers-cat" style={{ paddingTop: "18px" }}>
              {brands?.slice(7, 15).map((brand, idx) => (
                <h3
                  className="designer-item"
                  onClick={() => shopBrands(brand.seller_id, brand.slug)}
                  key={idx}
                >
                  {brand.store_name}
                </h3>
              ))}
            </div> */}
          </div>
          <div className="featured-brand-container">
            <div className="menu-divider"></div>
            <div>
              <img
                className="cat-img"
                src="/images/seller-homepage-picture.jpg"
                alt="register store"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/sell")}
              />
              <p
                className="txt-featured-brand"
                style={{ marginBottom: "5px", fontSize: "18px", color: "#000" }}
              >
                Make more money
              </p>
              <p
                className="txt-shop-featured-brand"
                onClick={() => navigate("/sell")}
              >
                Sell to a larger audience 
              </p>
            </div>

            {/* <p
              className="txt-featured-brand"
              style={{ marginBottom: "5px", fontSize: "18px", color: "#000" }}
            >
              Grow your brand global
            </p>
            <p
              className="txt-shop-featured-brand"
              onClick={() => navigate("/sell")}
            >
              Create your store now 
            </p> */}
          </div>
        </div>
        <style>{`
      
      .menu-overlay {
        position: fixed;
        left: 0px;
        top: 214px;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.5;
        z-index: 998;
      }
      .sell-menu-container {
        display: ${visible};
        width:100%
      }
      .designer-title{
        color:#000;
        font: normal bold 14px/18px DomaineSansText-Light;
        margin-bottom:0px;
      }
      .sell-content {
        display: flex;
        justify-content: space-between;
        min-height: 50vh;
        // margin-left: 3%;
        background: #ffffff;
        z-index: 999;
        padding: 40px 50px;
        position: absolute;
        width: 100%;
      }
      .sell-content a {
        display:block;
        text-decoration: none;
        font: normal normal 13px/16px DomaineSansText-Light;
        // margin: 2px;
        // margin-top: 2.5vh;
      }
      .sell-content a:hover {
        color: #800000;
      }
      .subs-sell-cat-container {
        display: flex;
        flex-basis: 60%;
      }
      .subs-sell-cat {
        flex-basis: 50%;
      }
      .subs-sell-left-div {
        // width: 150px;
           flex-basis: 50%;
        display: flex;
        flex-direction: column;
       align-items: flex-start;
          // border-left: 1px solid #a5a5a5;
        border-right: 1px solid #D4D4D4;
        flex-wrap: wrap;
        align-content: center;
      }
      .brands-left-div{
        flex-basis:40%;
      }
      .div-brands-list{
        align-items: flex-start;
        display: flex;   
        flex-direction: column;
        flex-wrap: wrap;
        height: 51%;
       
      }
      .designer-item {
        cursor: pointer;
        font: normal normal 13px/16px DomaineSansText-Light;
        // margin-top: 2.5vh;
        margin-bottom: 0px;
        text-transform: capitalize;
        color:#000
      }
      .designer-item:hover {
        color: #800000;
      }
      .vertical-line {
        border: 0.5px solid #6F6F6F;
        height: 45vh;
        margin:24px;
        width: 0px;
      }

      .txt-featured-brand{
        font: normal normal 18px/23px DomaineSansText-Regular;
        margin-top:20px;
        width:282px;
        text-transform: capitalize;
      }
      .txt-shop-featured-brand{
        font: normal normal 14px/16px DomaineSansText-Light;
        color: #6F6F6F;
        cursor: pointer;
      }
      .featured-brand-container{
        flex-basis: 40%;
        display: flex;
        justify-content: space-between;

      }
      `}</style>
      </div>
    </>
  );
};

export default SellMenu;
