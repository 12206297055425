import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SellerTopBar from "./SellerTopbar";
import SellerSideNav from "./SellerSideNav";
import { Modal, Tabs } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const PaymentSettings = () => {
  const [visible, setVisible] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  const { details } = useSelector((state) => state.store);

  const navigate = useNavigate();

  const scrollToRef = (ref) =>
    window.scrollTo({ top: ref.current.offsetTop - 100, behavior: "smooth" });
  const summaryRef = useRef(null);
  const currencyRef = useRef(null);

  const changeTab = (key) => {
    if (key === "1") scrollToRef(summaryRef);
    if (key === "2") scrollToRef(currencyRef);
  };

  return (
    <div className="payment-settings-container">
      <SellerTopBar />
      <div className="manage-listing-all-content">
        {!isTabletOrMobile && <SellerSideNav currentPageKey="11" />}
        <div className="payment-settings-coverall-container">
          {isTabletOrMobile && (
            <div className="hello-store-mobile">Hello {details?.brandName}</div>
          )}
          <div className="payment-settings-adspace"></div>
          <div className="payment-settings-body-content-header">
            <div className="payment-settings-body-content-header-container">
              <div className="payment-settings-body-content-header-title">
                Payment Settings
              </div>
            </div>
          </div>
          <div ref={summaryRef}>
            <Tabs
              defaultActiveKey="1"
              style={{ padding: `${!isTabletOrMobile ? "0 40px" : "0 16px"}` }}
              activeKey="1"
              onTabClick={(key) => changeTab(key)}
            >
              <TabPane tab="Payment Summary" key="1">
                <div className="payment-settings-box payment-settings-summary">
                  <div className="payment-settings-summary-title">
                    You are covered under Losode Payments{" "}
                  </div>
                  <div className="payment-settings-summary-subtitle">
                    You have access to our highest level of payment security and
                    support and are able to offer your buyers multiple payment
                    options
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "40px",
                      marginTop: "16px",
                    }}
                  >
                    <div className="payment-settings-summary-section">
                      <div className="payment-settings-summary-logos-container">
                        <img
                          style={{ height: "21px" }}
                          src="/images/losodepay.png"
                          alt="losodepay"
                        />
                        <img src="/images/paystack.png" alt="paystack" />
                        <img src="/images/interswitch.png" alt="interswitch" />
                        <img src="/images/flutterwave.png" alt="flutterwave" />
                        <img src="/images/paypal.png" alt="paypal" />
                      </div>
                      <div className="payment-settings-summary-deposit-section">
                        <strong>Deposit Schedule</strong>
                        <div className="payment-settings-summary-deposit-box">
                          Payout runs occurs 7days after delivery
                        </div>
                        <p className="payment-more-info">
                          <b className="payment-more-info-bold">
                            NB:{" "}
                            <span
                              className="btn-payment-more-info"
                              onClick={() => setVisible(true)}
                            >
                              click here
                            </span>{" "}
                          </b>
                          for more information on your payout schedule.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Currency" key="2"></TabPane>
            </Tabs>
            <div className="payment-settings-info-card">
              <div
                className="payment-settings-summary-title"
                style={{ fontSize: "14px" }}
              >
                Need to update your payment information?
              </div>
              <div className="payment-settings-info-card-subtitle">
                As a security precaution, all payment information changes will
                need to be done via our Seller team. <br />
                Send an email to{" "}
                <a href="mailto:hello@losode.com">hello@losode.com </a> to
                effect this change
              </div>
            </div>
            <div className="payment-settings-faq-section">
              Do you have any questions? {"   "}
              <span
                className="payment-settings-faq-link"
                onClick={() =>
                  navigate("/faqs", { state: { activeFAQ: "payment" } })
                }
              >
                Read our payment FAQs
              </span>{" "}
              {/* or contact the Finance Team at Losode - 
          <a href="mailto:seller-payments@losode.com">seller-payments@losode.com</a> {' '}
          or our Seller Team at Losode - 
          <a href="mailto:designers@losode.com">designers@losode.com </a> */}
            </div>
          </div>

          <div ref={currencyRef}>
            <Tabs
              defaultActiveKey="1"
              style={{ padding: `${!isTabletOrMobile ? "0 40px" : "0 16px"}` }}
              activeKey="2"
              onTabClick={(key) => changeTab(key)}
            >
              <TabPane tab="Payment Summary" key="1"></TabPane>
              <TabPane tab="Currency" key="2">
                <div className="payment-settings-box payment-settings-currency">
                  <div>
                    <div className="payment-settings-summary-title">
                      Your currency
                    </div>
                    <div className="payment-settings-summary-subtitle">
                      Your store currency has been set to Naira (
                      {details?.storeCurrency?.symbol}) in alignment to your
                      operating location. If you would like to change your base
                      currency. Please go to Store Settings and change your
                      address
                    </div>
                    <div className="payment-settings-currency-container">
                      <div className="payment-settings-currency-active-container">
                        <div className="payment-settings-currency-active-title">
                          Current currency
                        </div>
                        <div className="payment-settings-currency-active">
                          {details?.storeCurrency?.code} (
                          {details?.storeCurrency?.symbol})
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
            <div className="payment-settings-faq-section">
              Do you have any questions? {"   "}
              <span
                className="payment-settings-faq-link"
                onClick={() =>
                  navigate("/faqs", { state: { activeFAQ: "payment" } })
                }
              >
                Read our payment FAQs
              </span>{" "}
              {/* or contact the Finance Team at Losode - 
          <a href="mailto:seller-payments@losode.com">seller-payments@losode.com</a> {' '}
          or our Seller Team at Losode - 
          <a href="mailto:designers@losode.com">designers@losode.com </a> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={!isTabletOrMobile ? "55%" : "100%"}
        bodyStyle={{ padding: `${!isTabletOrMobile ? 0 : 10}` }}
        maskClosable={false}
        className="quantity-modal"
      >
        <div className="deposit-schedule-modal-body">
          <div className="deposit-modal-title">
            <h3>Seller Deposit schedule</h3>
          </div>
          <div className="deposit-modal-content">
            <p>
              1. We have two (2) payment cutoff days - the 15th calendar day
              (23:59) and the last calendar day of each month (23:59)
            </p>
            <p>
              2. Orders made and paid between the{" "}
              <span className="modal-bold-text">1st and 15th</span> of the
              month, with no refund or exchange requests, result in Nigeria
              Sellers getting paid on the{" "}
              <span className="modal-bold-text">
                last working day of the month.
              </span>
            </p>
            <p>
              3. Nigeria Sellers get paid on the{" "}
              <span className="modal-bold-text">11th</span> working day of the{" "}
              <span className="modal-bold-text">next month</span> for orders
              placed, paid, and with no refund or exchange requests between the{" "}
              <span className="modal-bold-text">16th</span> and the{" "}
              <span className="modal-bold-text">last day of the month.</span>
            </p>
          </div>
        </div>
      </Modal>

      <style jsx="true">{`
        .payment-settings-container {
          font-family: DomaineSansText-Light;
          color: black;
        }
        .manage-listing-all-content {
          position: relative;
          margin-top: 154px;
        }

        .payment-settings-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }

        .payment-settings-body-container {
          margin: 0 40px 40px;
          display: flex;
          justify-content: space-between;
        }
        .payment-settings-body-content-header-container {
          margin-bottom: 24px;
        }
        .payment-settings-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px 0;
        }
        .payment-settings-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .payment-settings-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
        }

        .payment-settings-summary-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .payment-settings-summary-subtitle {
          font-size: 14px;
        }

        .payment-settings-container .ant-tabs-content-holder {
          border: 1px solid;
          padding: 20px;
          font-size: 14px;
        }
        .ant-tabs-tab-btn {
          font-size: 14px;
        }
        .payment-settings-faq-section {
          padding: 0 40px;
          font-size: 14px;
          margin-bottom: 24px;
          margin-top: 12px;
        }
        .payment-settings-faq-link {
          text-decoration: underline;
          cursor: pointer;
        }
        .payment-settings-summary-logos-container {
          display: flex;
          gap: 12px;
          margin: 20px 0;
          align-items: center;
        }
        .payment-settings-summary-logos-container img {
          height: 25px;
        }
        .payment-settings-summary-deposit-section {
          font-size: 14px;
          margin-top: 40px;
        }
        .payment-settings-summary-deposit-box {
          border: 1px solid #ececee;
          padding: 10px;
          width: 100%;
          margin-top: 8px;
        }

        .payment-more-info {
          font-size: 14px;
          margin-top: 12px;
        }
        .payment-more-info-bold {
          color: #000;
          font-family: "DomaineSansText-Regular";
          font-weight: 700;
        }

        .btn-payment-more-info {
          cursor: pointer;
          text-decoration: underline;
        }

        .payment-settings-summary-confirm-account-section {
          display: flex;
          gap: 16px;
          margin: 16px 0;
          width: 80%;
        }
        .payment-settings-summary-confirm-account-input {
          height: 47px;
        }
        .payment-settings-summary-confirm-account-button {
          height: 47px;
          background: black;
          color: white;
        }
        .payment-settings-summary-confirm-account-cancel {
          height: 47px;
        }
        .payment-settings-summary-bank-section {
          font-size: 14px;
        }
        .payment-settings-summary-bank-title {
          font-weight: bold;
          margin-top: 20px;
        }
        .payment-settings-summary-bank-subtitle {
        }
        .payment-settings-summary-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .payment-settings-currency-container {
          display: flex;
          gap: 200px;
          margin-top: 40px;
        }
        .payment-settings-currency-active-title {
          font-weight: bold;
        }
        .payment-settings-currency-active {
          border: 1px solid #d9d9d9;
          margin-top: 8px;
          height: 47px;
          padding: 10px;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          padding: 10px;
          height: 47px;
          font-size: 14px;
          width: 130px;
        }
        .payment-settings-currency {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }

        .delivery-items-container .ant-form label {
          font-size: 14px;
        }

        .payment-settings-info-card {
          margin: 24px 40px 0px;
          background: rgba(252, 165, 165, 0.44);
          padding: 20px;
        }
        .payment-settings-coverall-container {
          margin-left: 200px;
        }

        .deposit-schedule-modal-body {
          background-color: #fff;
          font-family: "DomaineSansText-Light";
          padding: 70px 55px;
        }

        .deposit-modal-title {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          margin: 0px;
          border-bottom: 1px solid #000;
        }

        .deposit-modal-content {
          font-weight: 300;
          margin-top: 30px;
        }

        .modal-bold-text {
          color: #000;
          font-family: "DomaineSansText-Regular";
          font-weight: 700;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .payment-settings-body-content {
            margin: auto;
            padding: 0 24px;
          }
          .payment-settings-body-container {
            margin: 0;
          }

          .payment-settings-body-content-header {
            padding: 24px 40px;
          }

          .payment-settings-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
          .payment-settings-payment-summary-section {
            gap: 24px;
            width: auto;
          }
        }

        @media (max-width: 640px) {
          .payment-settings-coverall-container {
            margin-left: 0;
          }
          .manage-listing-all-content {
            margin-top: ${!details.phone_verify_status ? "154px" : "90px"};
          }
          .payment-settings-adspace {
            background-image: url(images/seller-stats-banner.png);
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          // .manage-listing-all-content {
          //   margin-top: 174px;
          // }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .payment-settings-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .payment-settings-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .payment-settings-body-content-header-title {
            font-size: 18px;
          }
          .payment-settings-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 169px;
          }

          .payment-settings-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .payment-settings-body-content-header-container {
            display: block;
            align-items: center;
            margin-bottom: 16px;
          }
          .payment-settings-body-content-header {
            padding: 24px 16px 0;
            flex-direction: column;
          }
          .payment-settings-body-content-header-subtitle {
            width: 100%;
            font-size: 14px;
          }
          .payment-settings-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
            margin: 0;
          }
          .payment-settings-body-sidebar {
            width: 100%;
            margin-top: 0;
            margin-bottom: 24px;
            padding: 0 16px;
            border: 0;
            margin-right: 0;
            display: flex;
            justify-content: flex-end;
          }
          .payment-settings-body-sidebar-content {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .payment-settings-body-sidebar-content-title {
            font-weight: normal;
            font-size: 12px;
            margin-bottom: 0px;
          }
          .payment-settings-body-sidebar-content-select {
            width: auto;
          }
          .payment-settings-body-sidebar-content-select .ant-select-selector {
            margin: 0 !important;
            height: auto !important;
            font-size: 12px !important;
            padding: 0 16px !important;
          }
          .payment-settings-body-sidebar-content-select .ant-select-arrow {
            top: 50%;
            height: auto;
            width: auto;
          }
          .payment-settings-body-content {
            width: 100%;
            padding: 0 16px;
          }
          .payment-settings-body-content-top-actions {
            gap: 16px;
            margin-top: 16px;
            display: none;
          }
          .payment-settings-container .ant-tabs-content-holder {
            border: 0;
            padding: 0;
          }
          .payment-settings-summary-title {
            font-size: 14px;
          }
          .payment-settings-summary-logos-container {
            flex-wrap: wrap;
            margin: 16px 0;
          }
          .payment-settings-summary-deposit-section {
            margin-top: 24px;
          }
          .payment-settings-summary-deposit-box {
            margin-top: 8px;
            width: 100%;
            border: 0;
            padding: 0;
          }
          .payment-settings-info-card {
            margin: 24px 0px 0px;
            padding: 16px;
          }
          .payment-settings-faq-section {
            padding: 0 16px;
            font-size: 12px;
            margin-bottom: 40px;
          }
          .payment-settings-currency-container {
            margin-top: 24px;
          }

          .deposit-schedule-modal-body {
            padding: 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default PaymentSettings;
