import { useMediaQuery } from "react-responsive";
import ProductWeb from "./ProductWeb";
import ProductMobile from "./ProductMobile";

const Product = ( {product, currency, productIndex }) => {

    const isMobile = useMediaQuery({ maxWidth: 640 });

    return(
        !isMobile ? 
        <ProductWeb product={product} currency={currency} productIndex={productIndex} /> : 
        <ProductMobile product={product} currency={currency} productIndex={productIndex} />
    )
}

export default Product;