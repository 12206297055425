import React from "react";
import { useMediaQuery } from "react-responsive";
import PhotoshootCheckoutMobile from "./PhotoshootCheckoutMobile";
import PhotoshootCheckoutWeb from "./PhotoshootCheckoutWeb";

function PhotoshootCheckout() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  return (
    <>
      {isTabletOrMobile ? (
        <PhotoshootCheckoutMobile />
      ) : (
        <PhotoshootCheckoutWeb />
      )}
    </>
  );
}

export default PhotoshootCheckout;
