import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Footer } from "../components/layout";
// import { Footer, Layout } from "../components/layout";
import { Button, Carousel, Collapse } from "antd";
import {
  ArrowRightOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import { createRef, useRef } from "react";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import SliderWrapper from "../components/slickSliderWrapper";
import AuthModal from "../components/AuthModal";
import BuyerAuthChoice from "../components/BuyerAuthChoice";
import SellerHeader from "./seller/SellerHeader";
import SellerHeaderMobile from "../components/layout/SellerHeaderMobile";

// const useIntersection = (element, rootMargin) => {
//   const [isVisible, setState] = useState(false);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         setState(entry.isIntersecting);
//       },
//       { rootMargin }
//     );

//     element.current && observer.observe(element.current);

//     let holdCurrent = element.current;

//     return () => observer.unobserve(holdCurrent);
//   }, [element, rootMargin]);

//   return isVisible;
// };

const SellerHomepage = () => {
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);

  const isMobile = useMediaQuery({ maxWidth: 640 });

  const [sellerAuthModalVisible, setSellerAuthModalVisible] = useState(false);
  const [sellerSignInAuthModalVisible, setSellerSignInAuthModalVisible] =
    useState(false);

  const settingsBigScreen = {
    dots: true,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 500,
    arrows: false,
    adaptiveHeight: true,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

  const sliderRef = useRef();

  const carouselSettings = {
    arrows: false,
    slidesToShow: !isMobile ? 3 : 1,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    afterChange: (current) => setActiveSlide(current),
  };

  const carouselRef = createRef();

  const faqArray = [
    {
      questionHeader: "Setting up my store and listing items",
      questions: [
        {
          question: "How do I list an item?",
          answer: (
            <>
              We have designed an item listing wizard which will guide you
              through the process of listing an item as well as our{" "}
              <span className="process-item-link">listing criteria</span> [link
              to criteria – marketing to simplify for sellers - Losode Listing
              Criteria.docx]
            </>
          ),
        },
        {
          question: "How long does it take to list an item?",
          answer:
            "If you have your photographs and item descriptions and weights ready, we expect it to take between three and five minutes to list an item.",
        },
        {
          question: "What support will you provide to help me list an item?",
          answer:
            "We have designed several support videos to enhance your success as a Losode designer [link to videos for sellers – ideally on Freshdesk so that agents can use too]",
        },
        {
          question: "What happens once my item is listed?",
          answer:
            "Your listing will be submitted to our customer operations team for quality review. Once this is complete, we will either approve the listing or ask you to make changes to the listing before it can be approved.",
        },
        {
          question:
            "I have listed my items, but my store is not yet visible on the platform.",
          answer: (
            <>
              We require a minimum number of item listings in each store to
              publish your store. Please contact us at{" "}
              <a href="mailto:designers@losode.com" className="process-item-link">
                designers@losode.com
              </a>{" "}
              and we will be happy to help you.
            </>
          ),
        },
      ],
    },
    {
      questionHeader: "Receiving Orders",
      questions: [
        {
          question: "Who can make purchases from my store?",
          answer:
            "Customers within and outside of Africa can purchase items from your store and make payments through our 3rd party payment providers. Payouts will be made to you in your local currency.",
        },
        {
          question: "How do I get notified that I have received an order?",
          answer: (
            <>
              You will be notified by email and SMS immediately an order is
              placed on your store. You will receive confirmation of the likely
              collection timing. Please note delivery timescales and cut-off
              times in our{" "}
              <span
                className="process-item-link"
                onClick={() => navigate("/faqs")}
              >
                delivery FAQs
              </span>
              .
            </>
          ),
        },
        {
          question: "What if I no longer have the item in stock?",
          answer:
            "If an item is no longer available, you must delist it immediately. We expect you to keep your store up to date daily and to delist items that are no longer in stock as soon as possible. We are keen to work with designers to minimise disappointing customers by cancelling orders.",
        },
        {
          question: "How do customers pay?",
          answer: (
            <>
              Losode collects payments from customers on behalf of our designers and
              issue payouts to our designers monthly in line with the designers’{" "}
              <span
                className="process-item-link"
                onClick={() => navigate("/returns")}
              >
                returns policy
              </span>
              . Customers can pay using PayPal, Flutterwave, Paystack or Losode
              Pay.
            </>
          ),
        },
      ],
    },
    {
      questionHeader: "Preparing and Shipping Orders",
      questions: [
        {
          question: "How do I package an order for shipment?",
          answer:
            "Customers within and outside of Africa can purchase items from your store and make payments through our 3rd party payment providers. Payouts to you will made to you in your local currency.",
        },
        {
          question: "Can I use my own packaging?",
          answer: (
            <>
              We expect all our designers to use Losode packaging when sending
              orders to our customers. This packaging is available for a nominal
              fee on Losode.com. Please contact us at{" "}
              <a href="mailto:designers@losode.com" className="process-item-link">
                designers@losode.com
              </a>{" "}
              and we will be happy to help you.
            </>
          ),
        },
        {
          question:
            "Can I contact the courier directly if there will be a delay in packaging the order?",
          answer: (
            <>
              Please contact us immediately at{" "}
              <a href="mailto:designers@losode.com" className="process-item-link">
                designers@losode.com
              </a>{" "}
              and we will be happy to help you.
            </>
          ),
        },
        {
          question: "When will the order be collected by the courier?",
          answer: (
            <>
              In most instances, the courier will contact you prior to the
              collection of the parcel. Please contact us at{" "}
              <a href="mailto:designers@losode.com" className="process-item-link">
                designers@losode.com
              </a>{" "}
              and we will be happy to help you.
            </>
          ),
        },
        {
          question: "How does Losode handle shipping and delivery?",
          answer:
            "Losode provides access to delivery companies who ship locally within Nigeria and to other parts of the world. Losode are usually able to ship at competitive rates due to these partnerships.",
        },
        {
          question: "Can I deliver directly to customers?",
          answer: "No, losode does not currently offer this service.",
        },
      ],
    },
  ];

  const scrollToRef = (ref) =>
    window.scrollTo({ top: ref.current.offsetTop - 120, behavior: "smooth" });
  const myBasicRef = useRef(null);
  const ourSellersRef = useRef(null);
  const myTipsRef = useRef(null);
  const myFAQRef = useRef(null);
  const myBannerRef = useRef(null);

  // const bannerInViewport = useIntersection(myBannerRef, "-150px");

  const location = useLocation();
  const [params] = useSearchParams();

  const section = params?.get("section");
  const device = params?.get("device");

  useEffect(() => {
    const sectionParam = section || location?.state?.section;
    const deviceParam = device || location?.state?.device;

    if (sectionParam === "faq") {
      const targetRef = deviceParam === "mobile" ? myFAQRef : myFAQRef.current;

      if (targetRef) {
        window.scrollTo({
          top: targetRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [section, device, location?.state?.section, location?.state?.device]);

  const Div1 = () => (
    <div className="seller-banner-1">
      <div className="seller-banner-content">
        <div className="seller-title">
          Make money selling on {!isMobile && <br />} Losode
        </div>
        <div className="seller-subtitle">
          Get your business seen by millions of customers as you grow with ease.
          Independent designers thrive on Losode! Scale your business by trading
          internationally. Join for free!
        </div>
        <Button
          className="seller-banner-button"
          type="primary"
          onClick={() => setSellerAuthModalVisible(true)}
          // onClick={() => setAuthChoiceModalVisible(true)}
          icon={<ArrowRightOutlined />}
        >
          Join Losode{" "}
        </Button>
        {/* <div className="seller-banner-btn-subtitle">Join for free today</div> */}
      </div>
    </div>
  );

  // const Div2 = () => (
  //   <div
  //     className="seller-banner-2"
  //     style={{ cursor: "pointer" }}
  //     onClick={() => setSellerAuthModalVisible(true)}
  //   />
  // );

  const [authChoiceModalVisible, setAuthChoiceModalVisible] = useState(false);

  return (
    <div>
      <AuthModal
        authModalVisible={sellerAuthModalVisible}
        setAuthModalVisible={setSellerAuthModalVisible}
        activePage="2"
        pageUserType="seller"
      />
      <AuthModal
        authModalVisible={sellerSignInAuthModalVisible}
        setAuthModalVisible={setSellerSignInAuthModalVisible}
        activePage="1"
        pageUserType="seller"
      />

      {!isMobile && (
        <SellerHeader
          scrollToRef={scrollToRef}
          myBasicRef={myBasicRef}
          myTipsRef={myTipsRef}
          myFAQRef={myFAQRef}
          ourSellersRef={ourSellersRef}
          // setAuthChoiceModalVisible={setAuthChoiceModalVisible}
          setSellerSignInAuthModalVisible={setSellerSignInAuthModalVisible}
          setSellerAuthModalVisible={setSellerAuthModalVisible}
          // bannerInViewport={bannerInViewport}
        />
      )}

      {isMobile && (
        <SellerHeaderMobile
          scrollToRef={scrollToRef}
          myTipsRef={myTipsRef}
          myFAQRef={myFAQRef}
          ourSellersRef={ourSellersRef}
          myBasicRef={myBasicRef}
        />
      )}

      <div className="seller-homepage-container">
        <div className="seller-banner" ref={myBannerRef}>
          <SliderWrapper>
            <Slider {...settingsBigScreen} ref={sliderRef} autoplay>
              <Div1 />
              {/* <Div2 /> */}
            </Slider>
          </SliderWrapper>
        </div>
        <div className="seller-info-board" ref={myBasicRef}>
          <div className="seller-info-board-content">
            <div className="seller-info-board-title">How Losode Works</div>
            <div className="seller-info-board-subtitle">
              Here is everything you need to know to start selling
            </div>
            <div className="seller-info-board-list">
              <div className="seller-info-board-list-item">
                <div className="seller-info-board-list-icon">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#FE7972" />
                    <g clip-path="url(#clip0_21777_91743)">
                      <path
                        d="M23.0857 11.4297L16.5714 17.944L13.1429 14.5154L8 19.6583"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.9717 11.4297H23.086V15.544"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_21777_91743">
                        <rect
                          width="16.4571"
                          height="16.4571"
                          fill="white"
                          transform="translate(7.31445 7.31445)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="seller-info-board-list-item-title">
                  Grow your business
                </div>
                <div className="seller-info-board-list-content">
                  Scale with ease! Set up your digital store in minutes.{" "}
                  <span
                    className="process-item-link"
                    // onClick={() => setSellerAuthModalVisible(true)}
                    onClick={() => setSellerAuthModalVisible(true)}
                  >
                    Join Losode
                  </span>
                </div>
              </div>
              <div className="seller-info-board-list-item info-board-with-bg">
                <div className="seller-info-board-list-icon">
                  <svg
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.5 30.25C24.0939 30.25 30.25 24.0939 30.25 16.5C30.25 8.90608 24.0939 2.75 16.5 2.75C8.90608 2.75 2.75 8.90608 2.75 16.5C2.75 24.0939 8.90608 30.25 16.5 30.25Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.75 16.5H30.25"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.5 2.75C19.9393 6.51524 21.8938 11.4015 22 16.5C21.8938 21.5985 19.9393 26.4848 16.5 30.25C13.0607 26.4848 11.1062 21.5985 11 16.5C11.1062 11.4015 13.0607 6.51524 16.5 2.75V2.75Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="seller-info-board-list-item-title">
                  Reach a global audience
                </div>
                <div className="seller-info-board-list-content">
                  Increase your profits! Gain access to millions of ready
                  customers across the globe
                </div>
              </div>
              <div className="seller-info-board-list-item">
                <div className="seller-info-board-list-icon">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#FE7972" />
                    <g clip-path="url(#clip0_21777_91761)">
                      <path
                        d="M23.0855 21.7146V20.3432C23.085 19.7354 22.8827 19.1451 22.5104 18.6648C22.1381 18.1844 21.6168 17.8414 21.0283 17.6895"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.9714 21.7139V20.3425C18.9714 19.615 18.6825 18.9174 18.1681 18.403C17.6537 17.8886 16.956 17.5996 16.2286 17.5996H10.7429C10.0154 17.5996 9.31775 17.8886 8.80336 18.403C8.28898 18.9174 8 19.615 8 20.3425V21.7139"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.2861 9.46094C18.8761 9.612 19.3991 9.95513 19.7725 10.4362C20.146 10.9173 20.3487 11.509 20.3487 12.1181C20.3487 12.7271 20.146 13.3188 19.7725 13.7999C19.3991 14.281 18.8761 14.6242 18.2861 14.7752"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.486 14.8568C15.0009 14.8568 16.2289 13.6288 16.2289 12.114C16.2289 10.5991 15.0009 9.37109 13.486 9.37109C11.9712 9.37109 10.7432 10.5991 10.7432 12.114C10.7432 13.6288 11.9712 14.8568 13.486 14.8568Z"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_21777_91761">
                        <rect
                          width="16.4571"
                          height="16.4571"
                          fill="white"
                          transform="translate(7.31445 7.31445)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="seller-info-board-list-item-title">
                  Join a thriving community
                </div>
                <div className="seller-info-board-list-content">
                  Gain access to education and tools focused on growing your
                  business
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sub-banner-section">
          <div className="sub-banner-container">
            <div className="sub-banner-content">
              <div className="sub-banner-title">
                We make it easy to trade globally
              </div>
              <div className="sub-banner-subtitle">
                At Losode, we are intentional about your growth. Access powerful
                tools to manage your inventory, accept orders, collect payments,
                engage with customers and track transactions. Improve your
                business now.
              </div>
              {/* <div className="sub-banner-subtitle">
              Grow quicker with us!
              </div> */}
              <Button
                className="seller-banner-button"
                type="primary"
                icon={<ArrowRightOutlined />}
                // onClick={() => setSellerAuthModalVisible(true)}
                onClick={() => setSellerAuthModalVisible(true)}
              >
                Join Losode
              </Button>
            </div>
            <div className="sub-banner-image-container">
              <img
                className="sub-banner-image"
                alt="Join the community"
                src="./images/seller-sub-banner.jpg"
              />
            </div>
          </div>
        </div>

        <div className="seller-process" ref={myTipsRef}>
          <div className="seller-process-content">
            <div className="seller-process-header">
              <div>
                <div className="seller-process-title">
                  Create a great listing
                </div>
                <div className="seller-info-board-subtitle">
                  We have specifically curated the following tips to guide your
                  store setup on Losode.
                </div>
              </div>
              <div className="process-icon-div-desktop">
                <div
                  className="process-icon-container"
                  onClick={() => {
                    if (activeSlide > 0) carouselRef.current.prev();
                  }}
                  style={{
                    display: activeSlide === 0 ? "none" : "inline-flex",
                  }}
                >
                  <LeftOutlined className="process-icon" />
                </div>
                <div
                  className="process-icon-container"
                  onClick={() => {
                    if (activeSlide !== 1) carouselRef.current.next();
                  }}
                  style={{
                    display: activeSlide === 1 ? "none" : "inline-flex",
                  }}
                >
                  <RightOutlined className="process-icon" />
                </div>
              </div>
            </div>
            <div className="seller-process-steps-container">
              <Carousel {...carouselSettings} ref={carouselRef}>
                <div className="process-item">
                  <div className="process-item-title">Title your items</div>
                  <div className="process-item-details">
                    Correctly list your items to increase searchability. Make
                    sure you enter the style and fabric, always end with your
                    brand name.{" "}
                  </div>
                </div>
                <div className="process-item">
                  <div className="process-item-title">Use great pictures</div>
                  <div className="process-item-details">
                    Use clear images taken in adequately lit areas or{" "}
                    <span>
                      <a
                        className="process-item-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/book-photoshoot"
                      >
                        book
                      </a>
                    </span>{" "}
                    an affordable professional photoshoot with us today.
                  </div>
                </div>
                <div className="process-item">
                  <div className="process-item-title">Upload a logo</div>
                  <div className="process-item-details">
                    Be intentional about your brand! Use a professional logo, it
                    enhances your credibility{" "}
                  </div>
                </div>
                {/* <div className="process-item">
                  <div className="process-item-title">
                    Download our designer kit
                  </div>
                  <div className="process-item-details">
                    Show off your new store on social. Use your status, stories
                    and profiles to tell your audience about your digital
                    presence. {""}
                    <span>
                      <a
                        className="process-item-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://losode.sharepoint.com/:f:/s/LOSODE/EnKK-OhUs2RIi4QRF86Gzm8B1dcIhAuciEXJGQwO8rLC6A?e=RdaEYM"
                      >
                        Download now
                      </a>
                    </span>
                  </div>
                </div> */}
              </Carousel>
            </div>
            <div className="process-icon-div-mobile">
              <div
                className="process-icon-container"
                onClick={() => {
                  if (activeSlide > 0) carouselRef.current.prev();
                }}
                style={{ display: activeSlide === 0 ? "none" : "inline-flex" }}
              >
                <LeftOutlined className="process-icon" />
              </div>
              <div
                className="process-icon-container"
                onClick={() => {
                  if (activeSlide !== 3) carouselRef.current.next();
                }}
                style={{ display: activeSlide === 3 ? "none" : "inline-flex" }}
              >
                <RightOutlined className="process-icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="seller-faq-section" ref={myFAQRef} id="faqs">
          <div className="seller-faq-container">
            <div className="seller-faq-bold-title">
              Frequently Asked Questions
            </div>
            {faqArray.map((questionPanel, idx) => {
              const { questionHeader, questions } = questionPanel;
              return (
                <div className="faq-panel-div" key={idx}>
                  <Collapse
                    className="collapse-container"
                    ghost
                    expandIconPosition="right"
                  >
                    <Collapse.Panel
                      className="panel"
                      header={questionHeader}
                      // key={question.id}
                    >
                      {questions.map((item, index) => {
                        const { question, answer } = item;
                        return (
                          <div className="sub-faq-panel-div" key={index}>
                            <Collapse
                              className="collapse-container"
                              ghost
                              expandIconPosition="right"
                            >
                              <Collapse.Panel
                                className="sub-panel"
                                header={question}
                              >
                                <p className="txt-faq-ans">{answer}</p>
                              </Collapse.Panel>
                            </Collapse>
                          </div>
                        );
                      })}
                    </Collapse.Panel>
                  </Collapse>
                </div>
              );
            })}
            <div style={{ textAlign: "end" }} className="faq-more-link">
              View more FAQ's{" "}
              <span
                className="process-item-link"
                onClick={() => navigate("/faqs")}
              >
                here
              </span>
            </div>
          </div>
        </div>

        <div
          className="stats-banner-section"
          onClick={() => setSellerAuthModalVisible(true)}
        ></div>

        <div className="seller-paragraph-section" ref={ourSellersRef}>
          <div className="seller-paragraph-container">
            <div className="seller-paragraph-title">
              We are interested in brands like you
            </div>
            <p className="seller-paragraph-body">
              Our story, is firmly wrapped around impact. Success to us is when
              village Africa starts to trade with cosmopolitan Africa and the
              world at large . To make this happen, we are particularly
              interested in brands who are passionate about their craft –
              beautiful designs and offerings. We want to see that you have an
              established presence on Instagram or Facebook, and you have plans
              to grow your business and increase your knowledge. Our promise to
              you remains service and excellence as we hand you the tools needed
              for your success. If this sounds like you, then please Join Losode{" "}
              <span
                className="process-item-link"
                style={{ fontWeight: "bold" }}
                // onClick={() => setSellerAuthModalVisible(true)}
                onClick={() => setSellerAuthModalVisible(true)}
              >
                {" "}
                Join Losode
              </span>
            </p>
            {/* <Button
              className="seller-paragraph-button"
              type="primary"
              icon={<ArrowRightOutlined />}
              // onClick={() => setSellerAuthModalVisible(true)}
              onClick={() => setAuthChoiceModalVisible(true)}
            >
              Register
            </Button> */}
          </div>
        </div>

        <div className="seller-homepage-categories-section">
          <div className="seller-homepage-categories-content">
            <div className="seller-homepage-categories-title">
              What can I sell on Losode?
            </div>
            <div className="seller-homepage-categories-subtitle">
              You can sell fashion related items, from clothing to beauty and
              skincare. Our only rule is that they are made by you or your
              brand. We restrict items that violate any laws, or infringe on
              intellectual property
            </div>
            <div className="seller-homepage-categories-container">
              <div className="seller-homepage-category">
                <div
                  className="seller-homepage-category-image"
                  style={{ backgroundImage: "url(./images/shoe-seller.png)" }}
                >
                  <div className="seller-homepage-category-name">
                    Footwear and Accessories
                  </div>
                </div>
              </div>
              <div className="seller-homepage-category">
                <div
                  className="seller-homepage-category-image"
                  style={{
                    backgroundImage: "url(./images/Seller-homepage-men.jpg)",
                  }}
                >
                  <div className="seller-homepage-category-name">
                    Clothing for Men
                  </div>
                </div>
              </div>

              <div className="seller-homepage-category">
                <div
                  className="seller-homepage-category-image"
                  style={{
                    backgroundImage:
                      "url(./images/seller-homepage-clothing.png)",
                  }}
                >
                  <div className="seller-homepage-category-name">
                    Clothing for Women
                  </div>
                </div>
              </div>
              <div className="seller-homepage-category">
                <div
                  className="seller-homepage-category-image"
                  style={{
                    backgroundImage:
                      "url('./images/category_skin and beauty.png')",
                  }}
                >
                  <div className="seller-homepage-category-name">
                    Skincare and Beauty
                  </div>
                </div>
              </div>
            </div>
            {/* <Button
              className="seller-homepage-categories-button"
              type="primary"
              icon={<ArrowRightOutlined />}
              // onClick={() => setSellerAuthModalVisible(true)}
              onClick={() => setAuthChoiceModalVisible(true)}
            >
              Register
            </Button> */}
          </div>
        </div>
      </div>

      <BuyerAuthChoice
        authChoiceModalVisible={authChoiceModalVisible}
        setAuthChoiceModalVisible={setAuthChoiceModalVisible}
        setSellerAuthModalVisible={setSellerAuthModalVisible}
        setSellerSignInAuthModalVisible={setSellerSignInAuthModalVisible}
      />
      <Footer />

      <style jsx="true">{`
        .seller-homepage-container {
          width: 100%;
          font-family: DomaineSansText-Regular;
          padding-top: 120px;
        }
        .sellpage-discount-header {
          align-items: center;
          background: #e9e9e9;
          display: flex;
          height: 40px;
          justify-content: center;
          padding-left: 50px;
          padding-right: 35px;
        }
        .sellpage-vText {
          font: normal normal 14px DomaineSansText-Light;
          margin-top: 15px;
        }
        .sellpage-code {
          color: #800000;
          font-weight: bold;
          font-style: normal;
        }

        .sellpage-discount-link {
          text-decoration: underline;
          font: normal normal normal 14px DomaineSansText-Light;
          letter-spacing: 0px;
          color: #000000;
        }
        .sellpage-navbar {
          font-family: "DomaineSansText-Light";
          font-size: 15px;
          align-items: center;
          background: white;
          width: 100%;
          position: fixed;
          z-index: 100;
          box-shadow: 0 2px 10px 0 #05050526;
        }
        .sellpage-navbar-content {
          display: flex;
          justify-content: space-between;
          padding: 0 25px;
          align-items: center;
          background: white;
          width: 100%;
          height: 80px;
        }
        .sellpage-navbar-title {
          font-size: 26px;
          font-weight: bold;
          font-family: "DomaineSansText-Regular";
        }
        .sellpage-navbar-left {
          display: flex;
          align-items: center;
          gap: 25px;
        }
        .sellpage-navbar-right {
          display: flex;
          gap: 30px;
          align-items: center;
        }
        .sellpage-navbar-logo img {
          height: 30px;
        }
        .sellpage-left-link,
        .sellpage-right-link {
          cursor: pointer;
        }
        .sellpage-left-link:hover,
        .sellpage-right-link:hover {
          color: #800000;
          text-decoration: underline;
        }

        .seller-banner {
          width: 100%;
          height: 530px;
          max-height: 679px;
        }

        .seller-banner-1 {
          width: 100%;
          height: 530px;
          max-height: 679px;
          background: url(./images/seller-homepage-picture.jpg);
          background-repeat: no-repeat;
          background-position: left 18%;
          background-size: cover;
          position: relative;
          display: flex;
          align-items: center;
        }
        .seller-banner-2 {
          width: 100%;
          height: 530px;
          background: url(./images/seller_banner_web.png);
          background-repeat: no-repeat;
          background-size: cover;
        }
        .seller-banner-content {
          position: relative;
          z-index: 99;
          margin-bottom: 50px;
          margin-left: 50px;
          background-color: white;
          width: 513px;
          padding: 23px;
          align-self: flex-end;
        }
        .seller-title {
          position: relative;
          z-index: 99;
          font-size: 36px;
          color: black;
          font-weight: 700;
          line-height: 39px;
          font-family: "DomaineSansText-Regular";
          margin-bottom: 8px;
        }
        .seller-subtitle {
          position: relative;
          z-index: 99;
          // color: #4f4f4f;
          font-size: 16px;
          line-height: 28px;
          padding-bottom: 8px;
        }
        .seller-banner-button {
          font-size: 16px;
          height: 48px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          width: 173px;
          align-items: center;
        }
        .seller-banner-btn-subtitle {
          color: #4f4f4f;
          font-size: 10px;
          margin-top: 7px;
        }

        .seller-info-board {
          width: 100%;
          display: flex;
        }
        .seller-info-board-content {
          width: 85%;
          padding: 40px 0px;
          margin: 0 auto;
          // max-width: 1520px;
        }
        .seller-info-board-title {
          font-size: 36px;
          color: black;
          font-weight: 600;
        }
        .seller-info-board-subtitle {
          font-size: 16px;
          color: black;
        }
        .info-board-with-bg {
          background: rgb(254, 121, 114);
          color: white;
          border-radius: 8px;
          padding: 8px 6px;
          grid-column-gap: 0px;
        }
        .info-board-with-bg .seller-info-board-list-item-title,
        .info-board-with-bg .seller-info-board-list-content {
          color: white;
        }
        .seller-info-board-list {
          display: flex;
          justify-content: space-between;
          margin-top: 24px;
        }
        .seller-info-board-list-icon {
          width: 41px;
          // background: black;
          color: white;
          border-radius: 50%;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
        }
        .seller-info-board-list-item-title {
          font-size: 28px;
          font-weight: 700;
          margin-top: 8px;
          color: black;
        }
        .seller-info-board-list-content {
          font-size: 16px;
          line-height: 31px;
          // color: #4f4f4f;
          color: black;
          max-width: 345px;
        }

        .sub-banner-section {
          background: #f9f9f9;
          width: 100%;
        }
        .sub-banner-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 80px 0px;
          width: 85%;
          margin: 0 auto;
          // max-width: 1520px;
        }
        .sub-banner-content {
          width: 54%;
          align-self: flex-start;
        }
        .sub-banner-title {
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 16px;
          color: black;
        }
        .sub-banner-subtitle {
          font-size: 16px;
          margin: 8px 0;
          color: #000;
          line-height: 28px;
        }
        .sub-banner-image {
          width: 493px;
          height: 294px;
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
        }
        .sub-banner-button {
          height: 47px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          width: 173px;
          font-size: 16px;
        }

        .process-item {
          display: inline-block;
          height: 194px;
          border-radius: 16px;
          background: #fe7972;
          padding: 24px;
        }
        .process-item li,
        .process-item-details {
          margin-top: 8px;
        }
        .process-item ul {
          padding-left: 20px;
        }
        .process-item-link {
          color: inherit;
          text-decoration: underline;
          cursor: pointer;
          font-family: "DomaineSansText-Black";
          // font-weight: bold;
        }
        .process-item-link:hover {
          text-decoration: none;
        }
        .process-item-title {
          font-size: 26px;
          color: white;
        }
        .process-item-details {
          font-size: 16px;
          color: white;
        }
        .process-icon {
          font-size: 18px;
          color: #800000;
        }
        .process-icon-container {
          padding: 8px;
          border-radius: 50%;
          border: 1px solid #800000;
          margin-left: 19px;
          width: 41px;
          height: 41px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .process-icon-div-desktop {
          display: block;
        }
        .process-icon-div-mobile {
          display: none;
        }
        .seller-process {
          width: 100%;
          padding-top: 24px;
          background: #fff;
          display: flex;
          align-items: center;
        }
        .seller-process-content {
          width: 85%;
          margin: auto;
        }
        .seller-process-steps-container {
          // margin-left: 40px;
          // margin-right: 40px;
        }
        .seller-process-header {
          // padding: 0 50px;
          color: black;
          margin-bottom: 34px;
          display: flex;
          justify-content: space-between;
        }
        .seller-process-title {
          font-size: 36px;
          font-weight: 700;
        }
        .ant-carousel .slick-initialized .slick-slide {
          padding: 8px;
        }

        .seller-faq-bold-title {
          font-size: 36px;
          text-align: center;
          margin-bottom: 15px;
          font-weight: bold;
        }
        .seller-faq-section {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 24px;
        }
        .seller-faq-item {
          min-height: 154px;
          border: 1px solid #c4c4c4;
          padding: 24px 56px;
          margin-bottom: 16px;
        }

        .faq-panel-div {
          border: 1px solid #c4c4c4;
          border-left: 0;
          border-right: 0;
          margin: 0px 0 18px;
          padding: 10px 0;
        }
        .sub-faq-panel-div {
          border: 1px solid #c4c4c4;
          border-left: 0;
          border-right: 0;
          border-top: 0;
          margin: 0px 0 5px;
          padding: 5px 0;
        }

        .panel {
          font-size: 20px;
          width: 1064px;
          color: #4f4f4f;
        }
        .sub-panel {
          font-size: 18px;
          width: 100%;
          color: #4f4f4f;
        }
        .seller-faq-header {
          display: flex;
          justify-content: space-between;
          font-size: 24px;
          font-weight: 700;
        }
        .seller-faq-answer {
          font-size: 18px;
          max-width: 950px;
          margin-top: 24px;
          color: #6f6f6f;
        }

        .txt-faq-ans {
          background: #f3f3f3;
          font: normal normal 16px DomaineSansText-Regular;
          padding: 20px 164px 28px 36px;
          text-align: left;
          color: #4f4f4f;
          margin: 0;
        }

        .ant-collapse
          > .ant-collapse-item
          > .ant-collapse-header
          .ant-collapse-arrow {
          padding: 20px 16px !important;
        }

        .ant-collapse-ghost
          > .ant-collapse-item
          > .ant-collapse-content
          > .ant-collapse-content-box {
          padding-top: 12px;
          padding-bottom: 12px;
          width: 97%;
        }

        .stats-banner-section {
          background-image: url("images/global-audience.png");
          height: 250px;
          background-repeat: no-repeat;
          width: 100%;
          background-position: center;
          background-size: contain;
          margin: 40px 0 0 0;
          cursor: pointer;
          position: relative;
        }
        // .stats-banner-button {
        //   position: absolute;
        //   bottom: 30px;
        //   right: 30px;
        //   font-size: 16px;
        //   margin: 0;
        //   padding: 0;
        //   background: #fff;
        //   display: flex;
        // }
        // .stats-banner-button-text {
        //   cursor: pointer;
        //   border: 1px solid;
        //   padding: 5px 8px;
        // }
        // .stats-banner-button-icon {
        //   padding: 5px 8px;
        //   border: 1px solid black;
        //   display: flex;
        //   justify-content: center;
        //   align-items: center;
        //   border-left: 0px;
        // }

        .seller-paragraph-section {
          width: 85%;
          margin: 0 auto 40px auto;
          padding-top: 40px;
        }
        .seller-paragraph-body {
          font-family: DomaineSansText-Light;
          font-size: 18px;
          color: black;
          line-height: 35px;
          // width: 961px;
        }
        .seller-paragraph-title {
          font-weight: bold;
          font-size: 36px;
          margin-bottom: 8px;
          text-align: center;
        }
        .seller-paragraph-button {
          height: 47px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          width: 147px;
          font-size: 16px;
        }

        .seller-homepage-categories-section {
          width: 100%;
          background: #f9f9f9;
          display: flex;
          align-items: center;
          padding: 50px 0;
        }
        .seller-homepage-categories-content {
          width: 85%;
          margin: auto;
        }
        .seller-homepage-categories-title {
          font-size: 36px;
          color: black;
          font-weight: bold;
          text-align: center;
        }
        .seller-homepage-categories-subtitle {
          font-size: 16px;
          margin-bottom: 30px;
          // width: 960px;
          color: black;
          font-family: DomaineSansText-Light;
          font-weight: 300;
        }
        .seller-homepage-categories-container {
          display: flex;
          justify-content: space-between;
        }
        .seller-homepage-category {
          font-size: 16px;
          color: #fff;
        }
        .seller-homepage-category-image {
          height: 328px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          width: 230px;
          position: relative;
        }
        .seller-homepage-category-image::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.4);
        }
        .seller-homepage-category-name {
          position: absolute;
          bottom: 30px;
          left: 10px;
          text-align: left;
          color: #fff;
          z-index: 1;
        }
        .seller-homepage-categories-button {
          font-size: 16px;
          margin-top: 16px;
          height: 47px;
          display: inline-flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          width: 147px;
        }
        .seller-banner-button svg,
        .seller-homepage-categories-button svg,
        .seller-paragraph-button svg,
        .sub-banner-button svg {
          vertical-align: middle;
        }
        .ant-btn > .anticon + span,
        .ant-btn > span + .anticon {
          margin-left: 0;
        }

        .seller-faq-container {
          width: 85%;
          margin: 0 auto;
        }

        .ant-collapse > .ant-collapse-item:last-child,
        .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
          border-radius: 0 0 2px 2px;
          width: 100%;
        }

        @media (min-width: 1445px) {
          .seller-homepage-category-image {
            height: 380px;
            width: 350px;
          }
        }

        @media (max-width: 1100px) and (min-width: 900px) {
          .seller-paragraph-section,
          .seller-homepage-categories-section {
            padding-right: 50px;
            padding-left: 50px;
            padding-top: 24px;
          }
          .txt-faq-ans {
            padding: 24px;
          }
          .panel {
            width: 90vw;
          }
          .seller-paragraph-section,
          .seller-paragraph-body,
          .seller-homepage-categories-content,
          .seller-homepage-categories-subtitle {
            width: 100%;
          }
          .stats-banner-section {
            margin: 24px 0;
            width: 100%;
          }
          .seller-homepage-category-image {
            width: 210px;
            height: 287px;
          }

          .seller-banner-2 {
            width: 100%;
            background: url(./images/seller-banner-tab.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }

          .seller-homepage-categories-container {
            gap: 8px;
          }
          .process-item {
            height: 220px;
          }
          .sub-banner-section {
            padding-bottom: 16px;
          }
          .sub-banner-container {
            padding: 0;
            margin: 24px auto;
            flex-direction: column;
            width: 90%;
          }
          .sub-banner-content {
            width: auto;
            padding: 16px 0;
          }
          .sub-banner-subtitle {
            margin: 0 0 16px;
          }
          .sub-banner-image {
            width: 100%;
            height: auto;
            margin-top: 16px;
            height: 490px;
          }
          .sub-banner-image-container {
            width: 100%;
          }
          .seller-info-board-content {
            padding: 50px 0;
            width: 90%;
          }
          .seller-info-board-list-item-title {
            font-size: 22px;
          }
          .seller-process-content {
            width: 90%;
          }
          .seller-faq-container {
            width: 90%;
            margin: 0 auto;
          }
        }

        @media (max-width: 900px) {
          .seller-homepage-container {
            padding-top: 0px;
          }
          .seller-title {
            font-size: 32px;
            line-height: 41px;
            margin-bottom: 20px;
          }
          .seller-subtitle {
            font-size: 14px;
            margin: 8px 0 24px;
            line-height: 24px;
            font-family: "DomaineSansText-Light";
          }
          .seller-banner {
            width: 100%;
            height: 607px;
          }

          .seller-homepage-category {
            font-size: 12px;
          }

          .seller-banner-1 {
            background-image: url(./images/seller-homepage-picture.jpg);
            background-position: center;
            height: 607px;
          }
          .seller-banner-2 {
            width: 100%;
            background: url(./images/seller-banner-mobile.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          }
          .seller-banner-content {
            margin: 0 12px 45px;
            padding: 30px 12px;
            align-self: flex-end;
          }
          .seller-banner-button {
            justify-content: space-between;
            height: 47px;
            width: 171px;
            padding: 13px 24px;
            font-size: 16px;
          }
          .seller-info-board-title {
            font-size: 22px;
            line-height: 23px;
          }
          .seller-info-board-subtitle {
            font-size: 16px;
            margin-top: 8px;
            font-family: "DomaineSansText-Light";
          }
          .seller-info-board-list {
            flex-direction: column;
            gap: 26px;
          }
          .seller-info-board-content {
            width: 100%;
            margin: 32px 12px 0;
            padding: 0;
          }
          .seller-info-board-list-item {
            display: grid;
            grid-template-columns: 40px 1fr;
            // grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 4px;
            grid-row-gap: 8px;
          }

          .seller-info-board-list-icon {
            grid-area: 1 / 1 / 3 / 2;
          }
          .seller-info-board-list-item-title {
            grid-area: 1 / 2 / 2 / 3;
          }
          .seller-info-board-list-content {
            grid-area: 2 / 2 / 3 / 3;
          }
          .seller-info-board-list-icon {
            width: 32px;
            height: 32px;
            font-size: 14px;
          }

          .seller-info-board-list-item-title {
            font-size: 18px;
            margin-top: 0;
          }
          .seller-info-board-list-content {
            font-size: 16px;
            line-height: 23px;
            font-family: "DomaineSansText-Light";
          }

          .sub-banner-container {
            padding: 0;
            margin: 32px 12px;
            flex-direction: column;
            width: auto;
          }
          .sub-banner-content {
            width: auto;
            padding: 25px 0 48px;
          }
          .sub-banner-title {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 24px;
          }
          .sub-banner-subtitle {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 24px;
            font-family: "DomaineSansText-Light";
          }
          .sub-banner-image {
            width: 100%;
            height: auto;
          }
          .sub-banner-button {
            height: 40px;
            width: 140px;
            font-size: 14px;
            padding: 10px;
          }

          .slick-slide {
            text-align: left !important;
          }
          // .process-icon-div-desktop {
          //   display: none;
          // }
          .process-icon-div-mobile {
            margin-left: 12px !important;
          }
          .process-icon-container {
            margin: 0;
            margin-right: 8px;
            height: 32px;
            width: 32px;
            border: 1px solid black;
            color: black;
          }
          .process-icon {
            font-size: 14px;
            color: black;
          }
          .process-item {
            padding: 26px 13px;
          }
          .process-item-title {
            font-size: 20px;
          }
          .process-item-details {
            font-size: 16px;
            font-family: "DomaineSansText-Light";
            line-height: 24px;
            margin-top: 16px;
            color: white;
          }
          .seller-process {
            height: auto;
            padding-top: 20px;
          }
          .seller-process-header {
            margin: 0px 12px;
          }
          .seller-process-content {
            width: 100%;
          }
          .seller-process-steps-container {
            margin: 24px 4px;
          }
          .seller-process-step {
          }
          .seller-process-title {
            font-size: 22px;
            font-family: DomaineSansText-Regular;
            font-weight: 700;
            margin: 0 0px 8px;
            padding: 0;
          }
          .seller-process-step-title {
            font-size: 20px;
          }

          .panel {
            width: 100%;
          }
          .ant-collapse-header {
            font-size: 16px;
            color: #4f4f4f !important;
          }
          .ant-collapse
            > .ant-collapse-item
            > .ant-collapse-header
            .ant-collapse-arrow {
            right: 0 !important;
            padding: 16px 0px !important;
          }
          .ant-collapse-icon-position-right
            > .ant-collapse-item
            > .ant-collapse-header {
            padding: 12px 0px;
            padding-right: 40px;
          }
          .txt-faq-ans {
            padding: 16px;
            font-size: 14px;
          }
          .seller-faq-section {
            display: block;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box {
            padding: 8px 0 !important;
          }
          .seller-faq-container {
            margin: 0 12px;
            width: auto;
          }
          .seller-faq-bold-title {
            font-size: 22px;
            line-height: 31px;
            text-align: left;
          }
          .faq-panel-div .collapse-container {
            margin-left: 0;
            margin-right: 0;
            margin-top: auto;
          }
          .faq-panel-div .ant-collapse-header {
            padding-left: 0 !important;
          }
          .faq-panel-div {
            border: 0;
            border-bottom: 1px solid #c4c4c494;
            padding: 8px 0;
            margin: 0;
          }
          .ant-collapse-ghost
            > .ant-collapse-item
            > .ant-collapse-content
            > .ant-collapse-content-box {
            width: auto;
          }
          .faq-more-link {
            margin-top: 24px;
            font-size: 16px;
          }

          .stats-banner-section {
            background-image: url("images/Global-audiences-phone.png");
            height: 400px;
            width: 100vw;
            margin: 32px 0;
            background-size: cover;
          }

          // .stats-banner-button {
          //   right: 0px;
          //   bottom: 50px;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   width: 295px;
          // }

          .seller-paragraph-section {
            width: 100%;
            padding: 0 12px;
            margin: 32px 0;
          }
          .seller-paragraph-title {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 24px;
          }
          .seller-paragraph-body {
            font-size: 15px;
            line-height: 24px;
            width: 100%;
            text-align: justify;
            font-family: "DomaineSansText-Light";
          }
          .seller-paragraph-button {
            height: 40px;
            width: 140px;
            font-size: 14px;
            padding: 10px;
          }

          .seller-homepage-categories-section {
            height: auto;
            padding: 0;
            background: white;
          }

          .seller-homepage-categories-content {
            width: 100%;
            padding: 0 12px;
          }
          .seller-homepage-categories-title {
            font-size: 22px;
            line-height: 31px;
            margin-bottom: 8px;
            font-weight: bold;
            text-align: left;
          }
          .seller-homepage-categories-subtitle {
            font-size: 15px;
            line-height: 24px;
            width: 100%;
            text-align: justify;
            font-family: "DomaineSansText-Light";
            margin-bottom: 24px;
          }
          .seller-homepage-categories-container {
            flex-wrap: wrap;
            justify-content: center;
            gap: 14px;
            margin: 32px 0 40px;
          }
          .seller-homepage-category {
            flex-basis: 45%;
            // margin-bottom: 32px;
          }
          .seller-homepage-category-name {
            font-size: 16px;
            bottom: 0px;
            left: 0px;
            line-height: 20px;
            padding: 10px;
          }

          .seller-homepage-category-image {
            height: 235px;
            width: auto;
          }
          .seller-homepage-categories-button {
            margin-top: 0;
            height: 40px;
            width: 140px;
            font-size: 14px;
            margin-top: -16px;
            padding: 10px;
          }

          @media (max-width: 900px) and (min-width: 640px) {
            .seller-info-board-list {
              flex-direction: row;
            }
            .sellpage-navbar-right {
              gap: 12px;
              font-size: 14px;
            }
            .sellpage-navbar-title {
              font-size: 20px;
            }
            .seller-homepage-container {
              padding-top: 80px;
            }
          }

          @media (max-width: 640px) {
            .process-icon-div-desktop {
              display: none;
            }
            .process-icon-div-mobile {
              display: block;
              margin: 8px 26px;
            }
          }
          @media (max-width: 430px) {
            .stats-banner-section {
              height: 200px;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default SellerHomepage;
