/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import {
  Avatar,
  Button,
  Input,
  Form,
  Col,
  Select,
  Row,
  Tooltip,
  Checkbox,
  Switch,
  Collapse,
  Space
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import SellerTopBar from "../SellerTopbar";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { storeActionCreators } from "../../../redux/stores";
import { userActionCreators } from "../../../redux/user";
import getLatLng from "../../../utils/getLatLng";
import { useNavigate } from "react-router-dom";
import { countries } from "../../../assets/constants";
import { fetchCities, fetchStates } from "../../../utils/getStates";
import triggerNotification from "../../../hooks/triggerNotification";

const { Option } = Select;

const { Panel } = Collapse;

const StoreSettingsMobile = () => {
  const dispatch = useDispatch();
  //   const currency = useSelector((state) => state.currency[0]);
  const [countryValue, setCountryValue] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [phone, setPhone] = useState("");
  const [imageUrl, setUrl] = useState("");
  const [fileName, setFileName] = useState("");
  // const [sizeGuide, setSizeGuide] = useState("");
  const [isVacation, setIsVacation] = useState(false);
  const [reason, setReason] = useState("");
  const [showError, setShowError] = useState(false);

  const {
    details,
    // sizeGuide: guide,
    returnType,
    vacationMode,
  } = useSelector((state) => state.store);

  const {
    // addSizeGuide,
    // removeSizeGuide,
    updatePassword,
    setReturnsType,
    // setDelivery,
    fetchDelivery,
    fetchVacationMode,
    fetchReturnsType,
    activateVacation,
    deactivateVacation,
    updateStoreDetails,
  } = bindActionCreators(storeActionCreators, dispatch);

  const { logout } = bindActionCreators(userActionCreators, dispatch);

  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const isIpad = useMediaQuery({ minWidth: 640, maxWidth: 1026 });

  const [form] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [returnsForm] = Form.useForm();

  const hiddenFileInput = useRef(null);
  // const hiddenSizeInput = useRef(null);

  const returns = { returnType };


  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  // const handleSizeClick = () => {
  //   hiddenSizeInput.current.click();
  // };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setFileName(fileUploaded.name);
    uploadImage(fileUploaded);
  };

  // const handleSizeChange = async (event) => {
  //   const fileUploaded = event.target.files[0];
  //   setSizeGuide(fileUploaded.name);
  //   uploadSizeGuide(fileUploaded);
  // };

  const uploadImage = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("api_key", "693411225724272");
    data.append("upload_preset", "barners");
    data.append("cloud_name", "losode-ng");
    const result = await axios.post(
      `https://api.cloudinary.com/v1_1/losode-ng/image/upload?q_auto:eco`,
      data
    );
    setUrl(result.data.secure_url);
    return result.data.secure_url;
  };

  // const uploadSizeGuide = async (file) => {
  //   const data = new FormData();
  //   data.append("file", file);
  //   data.append("api_key", "693411225724272");
  //   data.append("upload_preset", "guides");
  //   data.append("cloud_name", "losode-ng");
  //   const result = await axios.post(
  //     `https://api.cloudinary.com/v1_1/losode-ng/image/upload?q_auto:eco`,
  //     data
  //   );
  //   if (result.status === 200) {
  //     const res = await addSizeGuide({
  //       guideName: file.name,
  //       sizeGuide: result.data.secure_url,
  //     });

  //     if (res.status === "ok") {
  //       triggerNotification({
  //         type: "success",
  //         message: res.message,
  //       });
  //     }
  //     if (res.status === "not ok") {
  //       triggerNotification({
  //         type: "error",
  //         message: res.message,
  //       });
  //     }
  //     return result.data.secure_url;
  //   }
  // };

  // const deleteSizeGuide = async () => {
  //   const res = await removeSizeGuide();
  //   if (res.status === "ok") {
  //     triggerNotification({
  //       type: "success",
  //       message: res.message,
  //     });
  //     setSizeGuide("");
  //   }
  // };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(details.storeLink);
      triggerNotification({
        type: "success",
        message: "Your Store link has been copied to clipboard",
      });
    } catch (err) {
      triggerNotification({
        type: "error",
        message: `Failed to copy: , ${err.message}`,
      });
    }
  };

  const onChangePassword = async () => {
    const values = passwordForm.getFieldsValue();

    const res = await updatePassword(values);
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: res.message,
      });

      passwordForm.resetFields();
      logout();
      navigate("/sell");
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  const onChangeReturns = async () => {
    const values = returnsForm.getFieldsValue();
    const res = await setReturnsType(values);
    if (res.status === "ok") {
      triggerNotification({
        type: "success",
        message: res.message,
      });
      returnsForm.resetFields();
    }
    if (res.status === "not ok")
      triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  // const onChangeDelivery = async (value) => {
  //   const res = await setDelivery( { delivery: value });
  //   if (res.status === "ok") message.success(res.message);

  //   if (res.status === "not ok") message.error(res.message);
  // };

  const onSelectVacationReason = (val) => {
    setShowError(false);
    setReason(val);
  };

  const onVacationModeChange = async (val) => {
    if (val && reason === "") {
      setIsVacation(false);
      return setShowError(true);
    }
    if (val) {
      const res = await activateVacation({ reason });

      if (res.status === "ok")
        return triggerNotification({
          type: "success",
          message: res.message,
        });
    }
    if (!val) {
      const res = await deactivateVacation();
      if (res.status === "ok")
        return triggerNotification({
          type: "success",
          message: res.message,
        });
    }
  };

  const onFinish = async () => {
    const { storeCountry, storeState, storeAddress } = form.getFieldsValue();
    const values = {
      country: storeCountry,
      state: storeState,
      address: storeAddress,
    };
    const latLng = await getLatLng(values);
    const latitude = latLng.split(",")[0];
    const longitude = latLng.split(",")[1];

    const data = {
      ...form.getFieldsValue(),
      ...values,
      brandLogo: imageUrl ? imageUrl : details.logoUrl,
      latitude,
      longitude,
    };
    const res = await updateStoreDetails(data);
    if (res.status === "ok")
      return triggerNotification({
        type: "success",
        message: res.message,
      });
    if (res.status === "not ok")
      return triggerNotification({
        type: "error",
        message: res.message,
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      brandLogo: fileName,
      logoUrl: imageUrl,
    });
    // setSizeGuide(guide.size_guide_name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  useEffect(() => {
    setIsVacation(vacationMode);
  }, [vacationMode]);

  const onSelectCountry = async () => {
    if (countryValue && states.length === 0) {
      const res = await fetchStates(countryValue);
      setStates(res);
    }
  };

  const getCities = async () => {
    setCities(await fetchCities("nigeria"));
  };

  useEffect(() => {
    onSelectCountry();
  }, [countryValue]);

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    returnsForm.setFieldsValue({ returnType });
  }, [returnType]);

  // useEffect(() => {
  //   setDeliveryType(deliveryOption);
  // }, [deliveryOption]);

  useEffect(() => {
    setCountryValue(details?.storeCountry);
    setPhone(details?.phoneNumber);
  }, [details?.storeCountry]);

  useEffect(() => {
    form.setFieldValue("phone", phone);
  }, [phone]);

  useEffect(() => {
    fetchReturnsType();
    fetchDelivery();
    fetchVacationMode();
  }, []);

  return (
    <div className="store-settings-container">
      <SellerTopBar />
      {isTabletOrMobile && (
        <div className="manage-listing-all-content">
          <div style={{ background: "white" }}>
            {/* {isTabletOrMobile && (
              <div className="hello-store-mobile">
                Hello {details?.brandName}
              </div>
            )} */}
            <div className="store-settings-adspace" />
          </div>

          <div className="store-settings-body-content-header">
            <div className="store-settings-body-content-header-container">
              <div className="store-settings-body-content-header-title">
                Store Settings
              </div>
              <div className="store-settings-body-content-header-subtitle">
                In your Store settings, you can edit or change your store
                details and manage general characteristics for your Store.
                {/* Remember to join the Losode Seller Community here and take
                advantage of our affordable photoshoot packages */}
              </div>
            </div>
          </div>

          {/* mobile section */}
          <Collapse accordion style={{ margin: "0 10px 40px" }}>
            <Panel header="About Your Business" key="1">
              <div className="store-settings-about-business">
                <Form
                  form={form}
                  initialValues={details}
                  name="user"
                  scrollToFirstError
                  layout="vertical"
                  requiredMark={false}
                  onFinish={onFinish}
                >
                  <Col
                    span={!isTabletOrMobile ? 12 : 24}
                    style={{ margin: "auto" }}
                  >
                    <Form.Item
                      name="storeCountry"
                      label="Country"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        placeholder="Select Country"
                        optionFilterProp="children"
                        defaultValue={countryValue}
                        value={countryValue}
                        onChange={(value) => setCountryValue(value)}
                        disabled
                      >
                        {countries.map(({ country, icon }) => (
                          <Option key={country} value={country}>
                            <img
                              className="icon-flag"
                              src={icon}
                              alt={country}
                            />
                            {country}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <h2 style={{ margin: "20px auto" }}>About You</h2>

                  <Row className="form-row" gutter={[isIpad ? 36 : 72, 12]}>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="firstName"
                        label="First Name"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your first name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="lastName"
                        label="Last Name"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your last name"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="email"
                        label="Email Address"
                        rules={[
                          {
                            type: "email",
                            message: "Use a valid email address",
                          },
                        ]}
                      >
                        <Input className="input" disabled />
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select"
                          placeholder="Select your gender"
                        >
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <h2 style={{ margin: "20px auto" }}>About Your Brand</h2>
                  <Row className="form-row" gutter={[isIpad ? 36 : 72, 12]}>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brandName"
                        label="Brand Name"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your business or brand name"
                          disabled
                        />
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item name="brandLogo" label="Change Brand Logo">
                        <Input
                          className="input"
                          placeholder="Use a professionally designed logo"
                          onChange={uploadImage}
                          readOnly
                          prefix={<Avatar size={40} src={details.logoUrl} />}
                          suffix={
                            <>
                              <Button
                                className="btn-upload"
                                onClick={handleClick}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "end",
                                  }}
                                >
                                  <img alt="upload" src="/images/upload.png" />
                                  <span
                                    style={{
                                      fontSize: `${
                                        !isTabletOrMobile ? "14px" : "10px"
                                      }`,
                                    }}
                                  >
                                    Browse
                                  </span>
                                </div>
                              </Button>
                              <input
                                accept="image/*"
                                type="file"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{ display: "none" }}
                              />
                            </>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="description"
                        label="Brand Description"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="Enter your brand description"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="phone"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone Number!",
                          },
                          {
                            min: 10,
                            message:
                              "Please check the number of digits entered",
                          },
                          {
                            max: 11,
                            message:
                              "Please check the number of digits entered",
                          },
                        ]}
                      >
                        <Space.Compact style={{ width: "100%" }}>
                          <Select
                            defaultValue={"+234"}
                            style={{
                              width: "45%",
                            }}
                          >
                            <Option
                              key={countries[0].dial}
                              value={countries[0].dial}
                            >
                              <img
                                className="icon-flag"
                                src={countries[0].icon}
                                alt={countries[0].country}
                              />
                              {countries[0].dial}
                            </Option>
                          </Select>
                          <Input
                            className="input"
                            type="number"
                            placeholder="Enter phone number"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                          />
                        </Space.Compact>
                      </Form.Item>
                    </Col>
                    {/* <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="alternateEmail"
                        label="Alternate Email Address"
                        rules={[
                          {
                            type: "email",
                            message: "Use a valid email address",
                          },
                          () => ({
                            validator(rule, value) {
                              if (value !== info.email) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                "Please enter a different email address. This must be different from your login email address."
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          className="input"
                          suffix={
                            <Tooltip title="To ensure you do not miss any orders, we need to collect an alternative email address  ">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                  fontSize: "20px",
                                  marginRight: "20px",
                                }}
                              />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                    </Col> */}

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="businessRegistered"
                        label="Is your business registered?"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value={true}>Yes</Select.Option>
                          <Select.Option value={false}>No</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {countryValue === "Nigeria" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="regNumber"
                          label="Business Registration Number (Optional) "
                          rules={[]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your CAC number"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {countryValue !== "Nigeria" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="businessID"
                          label="Business registration number (optional) "
                          rules={[]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your business registration number"
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="storeAddress"
                        label="Brand Address"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                          {
                            max: 45,
                            message:
                              "Please limit your address to 45 characters or less",
                          },
                        ]}
                      >
                        <Input
                          className="input"
                          placeholder="19 East Drive, Agodi"
                          suffix={
                            <Tooltip title="Enter only your street address here, do not add state or country ">
                              <InfoCircleOutlined
                                style={{
                                  color: "rgba(0,0,0,.45)",
                                  fontSize: "20px",
                                  marginRight: "20px",
                                }}
                              />
                            </Tooltip>
                          }
                        />
                      </Form.Item>
                    </Col>

                    {(countryValue === "Nigeria" ||
                      countryValue === "United States of America") && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="storeState"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Select
                            className="select"
                            placeholder="Select your state"
                            showSearch
                          >
                            {states?.map(({ state_name: state }) => (
                              <Option key={state} value={state}>
                                {state}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {form.getFieldsValue("country") !== "United Kingdom" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="city"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          {/* <AutoComplete
                    className="select-city"
                    placeholder="Select City"
                    // onSearch={fetchCities}
                    options={cities}
                  /> */}
                          <Select
                            className="select"
                            placeholder="Select your city"
                            showSearch
                          >
                            {cities?.map((city, index) => (
                              <Option key={index} value={city}>
                                {city}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    {countryValue === "Nigeria" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="area"
                          label="Area (Optional)"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your area"
                          />
                        </Form.Item>
                      </Col>
                    )}

                    {countryValue === "United Kingdom" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="area"
                          label="County"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your area"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {countryValue === "Nigeria" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="zipCode"
                          label="Postal Code (Optional)"
                        >
                          <Input
                            className="input"
                            placeholder="Enter your postal code"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {countryValue === "USA" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="zipCode"
                          label="Zip Code"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your zip code"
                          />
                        </Form.Item>
                      </Col>
                    )}
                    {countryValue === "UK" && (
                      <Col span={!isTabletOrMobile ? 12 : 24}>
                        <Form.Item
                          name="zipCode"
                          label="Post Code"
                          rules={[
                            {
                              required: true,
                              message: "This is a required field",
                            },
                          ]}
                        >
                          <Input
                            className="input"
                            placeholder="Enter your post code"
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item
                        name="brandCategory"
                        label="Tell us what your Store sells"
                        rules={[
                          {
                            required: true,
                            message: "This is a required field",
                          },
                        ]}
                      >
                        <Select
                          className="select  multiselect"
                          placeholder="Select all categories that relate to your Store"
                          mode="multiple"
                        >
                          <Option value="Clothing">Clothing</Option>
                          <Option value="Shoes">Shoes</Option>
                          <Option value="Bags">Bags</Option>
                          <Option value="Accessories">Accessories</Option>
                          <Option value="Beauty/Grooming">
                            Beauty | Grooming
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={!isTabletOrMobile ? 12 : 24}>
                      <Form.Item>
                        <Button
                          className="seller-welcome-btn-submit"
                          htmlType="submit"
                        >
                          Update Settings
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Panel>

            <Panel header="Password" key="2">
              <div className="store-settings-box store-settings-password">
                <div>
                  <div className="store-settings-summary-title">
                    Change Your Password
                  </div>
                  <div className="store-settings-summary-subtitle">
                    You can change Store password at any time here. Passwords
                    must meet our security criteria
                  </div>
                  <div className="store-settings-password-container">
                    <Form
                      form={passwordForm}
                      scrollToFirstError
                      layout="vertical"
                      requiredMark={false}
                      onFinish={onChangePassword}
                      style={{ padding: "20px 0" }}
                    >
                      <Row className="form-row" gutter={[isIpad ? 36 : 72, 12]}>
                        <Col span={24}>
                          <Form.Item
                            name="oldPassword"
                            label="Current Password"
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                            ]}
                          >
                            <Input
                              className="password-input input"
                              placeholder="Current Password"
                              type="password"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row className="form-row" gutter={[isIpad ? 36 : 72, 12]}>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="newPassword"
                            label="New Password"
                            extra="Password must be longer than 8 characters and must contain at least 1 capital letter, 1 small letter and 1 number "
                            rules={[
                              {
                                required: true,
                                message: "This is a required field",
                              },
                              {
                                pattern:
                                  /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,300})$/,
                                message:
                                  "The password you have entered does not meet our requirements. Please change it for your enhanced security",
                              },
                            ]}
                          >
                            <Input
                              className="password-input input"
                              placeholder="Enter a new password"
                              type="password"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Form.Item
                            name="confirmPassword"
                            label="Confirm password"
                            dependencies={["newPassword"]}
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (
                                    !value ||
                                    getFieldValue("newPassword") === value
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    "The passwords that you entered do not match!"
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input
                              className="password-input input"
                              placeholder="Confirm Password"
                              type="password"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Button
                            className="password-btn-cancel"
                            // htmlType="submit"
                          >
                            Cancel
                          </Button>
                        </Col>
                        <Col span={!isTabletOrMobile ? 12 : 24}>
                          <Button
                            className="password-btn-submit"
                            htmlType="submit"
                          >
                            Change Password
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </Panel>

            <Panel header="Currency" key="3">
              <div className="store-settings-box store-settings-currency">
                <div>
                  <div className="store-settings-summary-title">
                    Your Currency
                  </div>
                  <div className="store-settings-summary-subtitle">
                    Your store currency has been set to{" "}
                    {details?.storeCurrency?.code} in alignment to your
                    operating location. If you would like to change your base
                    currency. Kindly update your address and country to change
                    your currency
                  </div>
                  <div className="store-settings-currency-container">
                    <div className="store-settings-currency-active-container">
                      <div className="store-settings-currency-active-title">
                        Current currency
                      </div>
                      <div className="store-settings-currency-active">
                        {details?.storeCurrency?.code} (
                        {details?.storeCurrency?.symbol})
                      </div>
                    </div>
                  </div>
                  <div>
                    Note that you would need to have a bank account that can
                    accept the updated currency{" "}
                  </div>
                </div>
              </div>
            </Panel>

            <Panel header="Store Link" key="4">
              <div className="store-settings-box store-settings-store-link">
                <div>
                  <div className="store-settings-summary-title">
                    Share Your Store Link
                  </div>
                  <div className="store-settings-summary-subtitle">
                    Share your unique Losode Store link with members of your
                    community, simply copy it and share on social media or in an
                    email to increase sales to your store
                  </div>
                  <div>
                    <div
                      style={{ marginTop: "30px", overflowWrap: "anywhere" }}
                    >
                      Your unique Store link:{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={details.storeLink}
                      >
                        {details.storeLink}
                      </a>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "30px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        className="store-link-button-copy"
                        onClick={copyLink}
                      >
                        Copy Link
                      </Button>
                      <Button
                        className="store-link-button-preview"
                        onClick={() =>
                          window.open(
                            `/store/${details?.slug}/preview`,
                            "_blank"
                          )
                        }
                      >
                        Preview Store
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
            {/* <Panel header="Delivery Settings" key="5">
              <div className="store-settings-summary-title">
                <strong>Update Delivery Settings</strong>
              </div>

              <div className="store-settings-summary-subtitle">
                Note: We have negotiated competitive delivery rates with our
                logistics partners and can deliver items to buyers quickly
              </div>
              <Radio.Group
                onChange={(e) => onChangeDelivery(e.target.value)}
                className="delivery-items-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "16px",
                  marginTop: "30px",
                  fontWeight: "bold",
                }}
                value={deliveryType}
              >
                <Radio value="self">Handle delivery yourself?</Radio>
                <Radio value="losode">Losode handles delivery?</Radio>
                <Radio value="pay">
                  You pay for delivery (free to the buyer)?
                </Radio>
              </Radio.Group>
            </Panel> */}

            <Panel header="Returns" key="7">
              <div className="store-settings-box store-settings-returns">
                <div>
                  <div className="store-settings-summary-title">
                    Returns, Exchanges, And Amendments
                  </div>
                  <div className="store-settings-summary-subtitle">
                    To give our buyers a level of comfort when buying from
                    Losode, we have made it mandatory for sellers to offer
                    buyers returns, exchanges or amendments
                  </div>
                </div>
                <div className="delivery-settings-box">
                  <div>
                    How does your Store treat returns? Select all that apply
                  </div>
                  <Form
                    form={returnsForm}
                    scrollToFirstError
                    initialValues={returns}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={onChangeReturns}
                    style={{ padding: "20px 0" }}
                  >
                    <div className="">
                      <Form.Item
                        name="returnType"
                        rules={[
                          {
                            required: true,
                            message: "Please select at least a returns type!",
                          },
                        ]}
                      >
                        <Checkbox.Group
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          <Checkbox value="Returns">Returns</Checkbox>
                          <Checkbox
                            style={{ marginLeft: "0px" }}
                            value="Amendments"
                          >
                            Amendments
                          </Checkbox>
                          <Checkbox
                            style={{ marginLeft: "0px" }}
                            value="Exchanges"
                          >
                            Exchanges
                          </Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                      <Button
                        className="btn-update-returns"
                        htmlType="submit"
                        style={{ width: "100%" }}
                      >
                        Update Settings
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Panel>

            <Panel header="Vacation" key="8">
              <div className="store-settings-box store-settings-returns vacation-section-container">
                <div>
                  <div className="store-settings-summary-title">
                    Set Your Store To Vacation
                  </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
                    What happens when you go on vacation?
                  </div>
                  <ul style={{ paddingInlineStart: "20px" }}>
                    <li>Your account will be inactive, until you reopen it</li>
                    <li>
                      Your profile, Store and listings will no longer appear on
                      Losode
                    </li>
                    <li>
                      All open orders must be completed before your Store is set
                      to vacation{" "}
                    </li>
                    <li>
                      Your account settings will remain intact, and no one will
                      be able to use your Store ID and Store name
                    </li>
                  </ul>
                </div>
                <div style={{ marginTop: "20px", fontWeight: "bold" }}>
                  You can re-activate your Store at any time, simply toggle back
                  from vacation mode
                </div>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <div style={{ marginBottom: "8px" }}>
                      Tell us why you are going on vacation
                    </div>

                    <Select
                      className="vacation-select"
                      placeholder="Select reason for vacation"
                      onChange={(val) => onSelectVacationReason(val)}
                    >
                      <Option value="Travelling">Travelling</Option>
                      <Option value="Change of staff">Change of staff</Option>
                      <Option value="Breaks">Breaks</Option>
                      <Option value="Relocation purposes">
                        Relocation purposes
                      </Option>
                      <Option value="Health Conditions">
                        Health Conditions
                      </Option>
                      <Option value="Out of stock and working on a new collection">
                        Out of stock and working on a new collection
                      </Option>
                    </Select>
                    {showError && (
                      <p className="txt-required">Please select a reason</p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      fontWeight: "bold",
                      fontSize: "16px",
                      marginTop: "16px",
                    }}
                  >
                    <div>Go on vacation</div>
                    <Switch
                      checked={isVacation}
                      onChange={onVacationModeChange}
                    />
                  </div>
                </div>
              </div>
            </Panel>

            <Panel header="Delete your account" key="9">
              <div>
                <div>
                  {/* <div className="store-settings-summary-title">
                    Delete Your Account
                  </div> */}
                  <div className="store-settings-summary-subtitle">
                    To delete your account, kindly send an email to{" "}
                    <a
                      href="mailto:designers@losode.com"
                      style={{ textDecoration: "underline" }}
                    >
                      designers@losode.com
                    </a>
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      )}
      <style jsx="true">{`
        .store-settings-container {
          font-family: DomaineSansText-Light;
          color: black;
        }
        .manage-listing-all-content {
          position: relative;
          margin-top: 154px;
        }
        .comm-link {
          cursor: pointer;
          text-decoration: underline;
        }

        .store-settings-adspace {
          background-image: url("images/seller-stats-banner.png");
          height: 211px;
          background-repeat: no-repeat;
          width: 80%;
          background-position: bottom;
          background-color: black;
          margin: auto;
        }

        .store-settings-body-container {
          margin: 0 40px 40px;
          display: flex;
          justify-content: space-between;
        }
        .store-settings-body-content-header-container {
          margin-bottom: 24px;
        }
        .store-settings-body-content-header {
          display: flex;
          justify-content: space-between;
          padding: 24px 40px 0;
          background: white;
        }
        .store-settings-body-content-header-title {
          font-size: 24px;
          font-weight: bold;
        }
        .store-settings-body-content-header-subtitle {
          font-size: 14px;
          color: #707070;
        }

        .user-avatar {
          width: 42px;
          height: 42px;
        }
        .store-settings-summary-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .store-settings-summary-subtitle {
          font-size: 14px;
        }

        .icon-flag {
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }

        .ant-tabs-content-holder {
          border: 1px solid;
          padding: 20px;
          font-size: 14px;
        }
        .ant-tabs-tab-btn {
          font-size: 14px;
        }
        .store-settings-faq-section {
          padding: 16px 60px;
          font-size: 14px;
          margin-bottom: 40px;
        }

        .section-title {
          font-family: DomaineSansText-Regular;
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
        }

        .store-settings-faq-link {
          text-decoration: underline;
        }
        .store-settings-summary-logos-container {
          display: flex;
          gap: 12px;
          margin: 20px 0;
          align-items: center;
        }
        .store-settings-summary-logos-container img {
          height: 25px;
        }
        .store-settings-summary-deposit-section {
          font-size: 14px;
          margin-top: 40px;
        }
        .store-settings-summary-deposit-box {
          border: 1px solid #ececee;
          padding: 10px;
          width: 80%;
          margin-top: 8px;
          color: white;
          background: black;
        }
        .store-settings-summary-confirm-account-section {
          display: flex;
          gap: 16px;
          margin: 16px 0;
          width: 80%;
        }
        .store-settings-summary-confirm-account-input {
          height: 48px;
        }
        .store-settings-summary-confirm-account-button {
          height: 48px;
          background: black;
          color: white;
        }
        .store-settings-summary-confirm-account-cancel {
          height: 48px;
        }
        .store-settings-summary-bank-section {
          font-size: 14px;
        }
        .store-settings-summary-bank-title {
          font-weight: bold;
          margin-top: 20px;
        }
        .store-settings-summary-bank-subtitle {
        }
        .store-settings-summary-section {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .store-settings-currency-container {
          display: flex;
          gap: 200px;
          margin: 40px 0;
        }
        .store-settings-currency-active-title {
          font-weight: bold;
        }
        .store-settings-currency-active {
          border: 1px solid #d9d9d9;
          margin-top: 8px;
          padding: 10px;
          background: #d4d4d4;
        }

        .store-settings-about-business
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item,
        .store-settings-about-business
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          align-items: center;
        }

        .store-settings-about-business
          .select-city.ant-select-show-search.ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector
          input {
          height: 48px;
          padding: 0px;
        }

        .store-settings-about-business
          .select-city.ant-select-single
          .ant-select-selector
          .ant-select-selection-item,
        .ant-select-single
          .ant-select-selector
          .ant-select-selection-placeholder {
          padding: 10px;
        }

        .store-settings-about-business
          .ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #707070;
          padding: 10px;
          height: 48px;
          font-size: 14px;
        }
        .store-settings-currency {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }

        .delivery-items-container .ant-form label {
          font-size: 14px;
        }

        .store-setting-tab-container {
          background: white;
          margin: 40px;
          padding: 10px 0 20px;
        }
        .store-setting-tab {
          padding: 0 30px;
        }

        .store-settings-about-business
          .ant-form-vertical
          .ant-form-item-label
          > label {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .store-settings-about-business
          .ant-form-item-explain.ant-form-item-explain-error {
          margin-top: 8px;
        }
        .store-settings-about-business .form-row {
          margin-top: 26.52px;
        }
        .input {
          height: 48px;
          border: 1px solid #707070;
          padding: 4px 0px 4px 16px;
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .store-settings-about-business
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector,
        .ant-picker {
          height: 48px !important;
          width: 100%;
          border: 1px solid #707070;
        }
        .store-settings-about-business .ant-picker-input > input {
          font-size: 16px;
          font-family: "DomaineSansText-Light";
        }
        .store-settings-about-business .ant-select,
        .store-settings-about-business
          .ant-input-affix-wrapper
          > input.ant-input,
        .store-settings-about-business span.ant-radio + * {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .store-settings-about-business .ant-radio-group {
          height: 48px;
          display: flex;
          align-items: center;
        }
        .store-settings-about-business .ant-radio-inner {
          width: 20px;
          height: 20px;
        }

        .store-settings-about-business .ant-radio-wrapper {
          margin-right: 40px;
        }
        .store-settings-about-business
          .ant-input-affix-wrapper
          > input.ant-input {
          color: #000;
        }
        .store-settings-about-business
          .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          border: 1px solid #707070;
          height: 48px;
          padding: 0px;
        }

        .store-settings-about-business
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item,
        .store-settings-about-business
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          display: flex;
          padding: 18px;
        }
        .store-settings-about-business .btn-upload {
          margin-right: 0px !important;
        }
        .store-settings-about-business .btn-upload,
        .store-settings-about-business .btn-upload:hover {
          background: black;
          color: #fff;
          height: 48px;
          width: 130px;
        }
        .store-settings-about-business .seller-welcome-btn-submit {
          font-size: 14px;
          height: 48px;
          margin-top: 30px;
          background: black;
          color: white;
          width: 375px;
        }
        .store-settings-about-business .seller-welcome-btn-cancel {
          float: right;
          font-size: 14px;
          width: 195px;
          height: 48px;
          width: 100%;
          margin-top: 30px;
        }
        .store-settings-about-business
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item,
        .store-settings-about-business
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding: 13px;
        }
        .store-settings-about-business .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .store-settings-about-business
          .ant-select-item-option-selected:not(
            .ant-select-item-option-disabled
          ) {
          background-color: #e5e5e5;
        }

        .store-link-button-preview,
        .store-link-button-copy {
          height: 48px;
          padding: 0 28px;
          background: black;
          color: white;
        }

        .store-settings-size-guide .btn-upload {
          margin-right: 0px !important;
        }
        .store-settings-size-guide .btn-upload,
        .store-settings-size-guide .btn-upload:hover {
          background: black;
          color: #fff;
          height: 48px;
          width: 130px;
        }
        .store-settings-size-guide .ant-input-affix-wrapper {
          padding: 0;
          padding-left: 10px;
          min-width: 400px;
        }
        .store-settings-size-guide .size-guide-delete-btn {
          height: 48px;
          padding: 0 60px;
          background: black;
          color: white;
          display: flex;
          align-items: center;
          gap: 20px;
        }
        .ant-tooltip-inner {
          font-size: 14px;
          font-family: "DomaineSansText-Light";
          background: #f4f4f4;
          color: black;
        }
        .vacation-select .ant-select-selector {
          width: 400px !important;
          height: 48px !important;
        }

        .store-settings-password-container .password-input {
          height: 48px;
          border: 1px solid #707070;
          padding: 4px 16px 4px 16px;
          font-size: 14px;
          font-family: "DomaineSansText-Light";
        }
        .store-settings-password-container .ant-form-item-explain,
        .store-settings-password-container .ant-form-item-extra {
          margin-top: 8px;
        }
        .store-settings-password-container .password-btn-submit {
          float: right;
          font-size: 14px;
          width: 195px;
          height: 48px;
          width: 100%;
          margin-top: 30px;
          background: black;
          color: white;
        }
        .store-settings-password-container .password-btn-cancel {
          float: right;
          font-size: 14px;
          width: 195px;
          height: 48px;
          width: 100%;
          margin-top: 30px;
        }
        .delivery-settings-box {
          margin-top: 20px;
        }
        .delivery-settings-options {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
        }
        .btn-update-returns {
          background: #000;
          color: #fff;
          height: 48px;
          padding: 0 60px;
          flex: 0.3;
        }
        .txt-required {
          color: red;
          font-size: 12px;
        }
        .vacation-section-container
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-item,
        .vacation-section-container
          .ant-select-single.ant-select-show-arrow
          .ant-select-selection-placeholder {
          padding: 13px;
        }
        .store-settings-about-business
          .store-tags
          .ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          overflow: scroll;
        }

        @media (max-width: 1026px) and (min-width: 640px) {
          .store-settings-body-content {
            width: 85%;
            margin: auto;
            padding: 0 16px;
          }

          .store-settings-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
          }
          .manage-listings-body-sidebar-add-listing-button {
            margin-top: 0;
            width: fit-content;
            align-self: flex-end;
          }

          .store-settings-adspace {
            height: 140px;
            background-position: center;
            margin-right: 86px;
            width: auto;
            margin-left: 56px;
            background-size: cover;
          }
          .store-setting-tab-container {
            margin: 40px 16px;
          }
          .ant-tabs-tab-btn {
            font-size: 13px;
          }
          .store-settings-size-guide .size-guide-delete-btn {
            padding: 0 24px;
          }
          .ant-tabs-tab + .ant-tabs-tab {
            margin-left: 20px;
          }
        }

        @media (max-width: 830px) and (min-width: 640px) {
          .ant-radio-wrapper {
            margin-right: 12px !important;
          }
          .ant-form-vertical .ant-form-item-label > label {
            height: 44px;
          }
          .store-settings-about-business .seller-welcome-btn-submit {
            margin-top: 52px;
          }
          .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
          .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
            flex-wrap: wrap;
          }
          .ant-tabs-tab + .ant-tabs-tab {
            margin-left: 0;
            margin-right: 20px;
          }
          .ant-tabs-tab {
            margin-right: 20px;
          }

          .size-guide-contain-flex {
            flex-wrap: wrap;
            gap: 16px;
          }

          .store-settings-returns
            .ant-checkbox-wrapper
            + .ant-checkbox-wrapper {
            margin-left: 0px;
          }
          .store-settings-returns .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }

          .vacation-contain-flex {
            flex-wrap: wrap;
            gap: 16px;
          }
        }

        @media (max-width: 640px) {
          .account-container {
            margin: 12px 24px;
            margin-top: 52px;
          }
          .manage-listing-all-content {
            margin-top: ${!details.phone_verify_status ? "154px" : "90px"};
          }
          .txt-title,
          .txt-about {
            font-size: 22px !important;
            font-weight: 700;
            text-align: center;
            width: 100%;
            line-height: 15px;
            font-family: DomaineSansText-Regular !important;
          }
          .txt-more,
          .txt-info {
            font-size: 10px;
            text-align: center;
            width: 100%;
            line-height: 15px;
          }
          .form-row {
            row-gap: 7px !important;
            margin-top: 5px !important;
          }
          .btn-upload {
            height: 40px;
            width: 86px;
            margin: 3px;
            padding: 5px;
          }
          .btn-upload span {
            line-height: 1.2;
          }
          .btn-upload img {
            height: 16px;
          }
          .btn-submit {
            width: 100%;
            margin-bottom: 130px;
            margin-top: 16px;
            font-size: 14px;
            font-family: DomaineSansText-Light;
          }

          .store-settings-coverall-container {
            margin-left: 0;
          }
          .store-settings-adspace {
            background-image: url(images/seller-stats-banner.png);
            height: 120px;
            background-repeat: no-repeat;
            width: 100%;
            background-position: center;
            background-color: black;
            margin: auto;
            background-size: cover;
          }
          .hello-store-mobile {
            padding: 0px 16px 16px;
            font-weight: bold;
            font-size: 18px;
          }

          .store-settings-body-main {
            padding: 0 16px;
            padding-bottom: 40px;
            margin-right: 0;
          }
          .store-settings-body-content-header-container {
            display: flex;
            align-items: center;
          }
          .store-settings-body-content-header-title {
            font-size: 18px;
          }
          .store-settings-body-sidebar-add-listing-button {
            padding: 0 16px;
            height: 40px;
            font-size: 14px;
            width: 169px;
          }

          .store-settings-body-content-listings-container {
            display: block;
            margin-bottom: 40px;
          }
          .store-settings-body-content-header-container {
            display: block;
            align-items: center;
            margin-bottom: 16px;
          }
          .store-settings-body-content-header {
            padding: 24px 16px;
            flex-direction: column;
          }
          .store-settings-body-content-header-subtitle {
            width: 100%;
            font-size: 14px;
          }
          .store-settings-body-container {
            flex-direction: column-reverse;
            margin-top: 0;
            justify-content: center;
          }

          .store-settings-about-business .seller-welcome-btn-submit {
            width: 100%;
          }

          .input,
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker,
          .ant-select-selector {
            height: 48px !important;
            margin-top: 0 !important;
            font-size: 14px !important;
          }
          .ant-select,
          .ant-input-affix-wrapper > input.ant-input,
          span.ant-radio + * {
            font-size: 14px !important;
          }
          .ant-form-item-explain-error {
            margin-top: 8px;
            font-size: 12px;
            margin-bottom: 8px;
          }
          .ant-form-vertical .ant-form-item-label > label,
          .ant-form label,
          .ant-input:placeholder-shown,
          .ant-picker-input > input:placeholder-shown {
            font-size: 14px !important;
          }

          .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            line-height: 1.5;
          }

          .store-settings-about-business .btn-upload,
          .store-settings-about-business .btn-upload:hover {
            width: 80px;
            height: 48px;
          }
          .store-settings-about-business
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector,
          .ant-picker {
            height: 48px !important;
          }

          .store-settings-about-business .multiselect .ant-select-selector {
            height: max-content !important;
          }
          .store-settings-password-container .password-btn-submit,
          .store-settings-password-container .password-btn-cancel {
            margin-top: 16px;
            height: 48px;
          }
          .store-settings-size-guide .ant-input-affix-wrapper {
            min-width: auto;
          }
          .store-settings-size-guide .size-guide-delete-btn {
            gap: 8px;
            justify-content: center;
            padding: 16px;
            margin-bottom: 16px;
          }
          .vacation-select .ant-select-selector {
            width: 300px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default StoreSettingsMobile;
