import React from "react";
import { styled } from "styled-components";
import {
  CheckCircle,
  Grid,
  MinusCircle,
  Photoshoot1,
  Photoshoot2,
  Photoshoot3,
  Photoshoot4,
  Photoshoot5,
} from "../../../../public/images";
import { Button } from "antd";
import { planFeatures } from "../../../assets/constants";
import { useNavigate } from "react-router-dom";
import formatter from "../../../utils/formatter";
import { useSelector } from "react-redux";


function BookPhotoshootWeb() {
  const navigate = useNavigate();

  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };
  return (
    <PhotoshootContainerWeb>
      <section className="header-section">
        <div className="logo-container" onClick={() => navigate("/")}>
          <img src="/images/losode-no-tagline.png" alt="losode-logo" />
        </div>
      </section>
      <section className="top-section">
        <div className="header-content">
          <h2 className="title">Book your Photoshoots</h2>
          <p className="desc">
            Embark on a personalized visual journey with our{" "}
            <b>"Book Your Photoshoots"</b> service, where seasoned photographers
            capture your items for digital catalogues.
          </p>
        </div>

        <img alt="book your photoshoots" src={Photoshoot1} />
      </section>
      <section className="info-section">
        <h2 className="title">
          We make Photoshoots easy for Fashion Designers and Brands
        </h2>
        <div className="content-section">
          <div className="left">
            <p className="desc">
              At Losode, we take the burden of photoshoots off you.
              <br /> We have two carefully curated packages for you to choose
              from. Choose to attend or not, we ensure your brand is properly
              represented to do business online. <br />
              Our clothing packages offer you a maximum of twelve (12) items
              with one model and photographer for a fixed price of{" "}
              <b>N175,000</b> Our non-clothing package offers a maximum of (20)
              items with one photographer, for a fixed price of <b>N135,000</b>.
            </p>
            <p className="desc">
              At this time, Losode can only offer photoshoots for brands
              physically located in Nigeria. To book a photoshoot, pick one of
              the packages below and and pay online and a member of our team
              will be in touch with you.
            </p>
          </div>
          <div className="right">
            <img alt="book your photoshoots" src={Photoshoot2} />
          </div>
        </div>
      </section>
      <section className="extra-section">
        <h2 className="title">We do photoshoots with ease</h2>
        <p className="desc">
          To sell online, intentionality is required. It is important to
          position yourself properly in maximising trade. Choose one of our
          packages below and a member of our team will be in touch. 
        </p>
        <div className="images">
          <img alt="losode images" width={"25%"} src={Photoshoot3} />
          <img alt="losode images" width={"50%"} src={Photoshoot4} />
          <img alt="losode images" width={"25%"} src={Photoshoot5} />
        </div>
      </section>
      <section className="booking-section">
        <div className="header-section">
          <h2 className="title">Pricing</h2>
          <p className="desc">Choose the package that is right for you</p>
        </div>
        <div className="plan-details">
          <div className="plan-section">
            <img className="img-grid" src={Grid} alt="grid" />
            <div className="plans-container">
              <div className="plan1">
                <h4 className="txt-plan">Photoshoot 1</h4>
                <p className="txt-plan-type">Non-clothing items</p>
                <h2 className="txt-plan-price">
                  {formatter(135000, rate, code, indicator).split(".")[0]}
                </h2>
                <Button
                  className="btn-plan1"
                  onClick={() => navigate("/plan1-photoshoot-checkout")}
                >
                  Choose plan
                </Button>
              </div>
              <div className="plan2">
                <h4 className="txt-plan">Photoshoot 2</h4>
                <p className="txt-plan-type">Clothing items</p>
                <h2 className="txt-plan-price">
                  {formatter(175000, rate, code, indicator).split(".")[0]}
                </h2>
                <Button
                  className="btn-plan2"
                  onClick={() => navigate("/plan2-photoshoot-checkout")}
                >
                  Choose plan
                </Button>
              </div>
            </div>
          </div>
          <div className="plan-features-section">
            {planFeatures.map((plan) => (
              <div className="plan-feature" key={plan.name}>
                <h2 className="txt-feature">{plan.name}</h2>
                <h2 className="plan1">
                  {plan.plan1 === "true" ? (
                    <CheckCircle />
                  ) : plan.plan1 === "false" ? (
                    <MinusCircle />
                  ) : (
                    plan.plan1
                  )}
                </h2>
                <h2 className="plan2">
                  {plan.plan2 === "true" ? (
                    <CheckCircle />
                  ) : plan.plan2 === "false" ? (
                    <MinusCircle />
                  ) : (
                    plan.plan2
                  )}
                </h2>
              </div>
            ))}
            <div className="plan-bg" />
          </div>
        </div>
      </section>
    </PhotoshootContainerWeb>
  );
}

const PhotoshootContainerWeb = styled.div`
  color: #272727;
  b {
    font-family: DomaineSansText-Black;
  }
  .header-section {
    .logo-container {
      box-shadow: 0px 4px 23.299999237060547px 0px #00000014;
      cursor: pointer;
      display: grid;
      place-items: start;
      padding: 25px 0 25px 80px;

      img {
        width: 130px;
      }
    }

    @media (max-width: 640px) {
      .logo-container {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;

        img {
          width: 100px;
        }
      }
    }
  }

  .top-section {
    background: linear-gradient(269.31deg, #dadfe2 -8.19%, #eeeeee 115.37%);
    display: flex;
    padding-left: 80px;
    gap: 40px;

    .header-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        color: #272727;
        font-size: 73px;
        font-family: DomaineSansText-Regular;
        font-weight: 600;
        line-height: 83.95px;
      }
      .desc {
        color: #272727;
        font-size: 24px;
        font-family: DomaineSansText-Light;
        font-weight: 600;
        line-height: 42.48px;
      }
    }

    img {
      flex: 1;
      width: 50%;
    }

    @media (max-width: 640px) {
      .header-content {
        margin-bottom: 0 !important;

        .title {
          font-size: 32px;
          max-width: 60%;
          line-height: normal;
          align-self: flex-start;
          margin: 0 0 8px;
        }
        .desc {
          font-size: 16px !important;
          font-family: "DomaineSansText-Light" !important;
          line-height: normal !important;
          text-align: left !important;
        }
      }
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 640px) {
    .top-section {
      flex-direction: column;
      gap: 16px;
      padding: 32px 16px 0;
      position: relative;
    }
  }

  .info-section {
    padding: 30px 80px 8rem;

    @media (max-width: 640px) {
      position: relative;
    }

    .title {
      color: #272727;
      font-size: 32px;
      font-weight: 600;
      font-family: DomaineSansText-Regular;
      line-height: 41.38px;
      margin: 0 auto;
      width: 50vw;
      text-align: center;
      padding-bottom: 60px;
    }
    .content-section {
      display: flex;
      gap: 40px;

      @media (max-width: 640px) {
        gap: 24px;
        margin-bottom: 0px !important;
        flex-direction: column;
      }

      .left {
        flex: 1;

        .desc {
          font-size: 19px;
          font-weight: 300;
          font-family: DomaineSansText-Light;
          line-height: 37.8px;

          @media (max-width: 640px) {
            text-align: justify;
            font-size: 16px !important;
            line-height: 1.5 !important;
            font-family: "DomaineSansText-Light" !important;
          }
        }
      }
      .right {
        flex: 1;

        img {
          max-width: 600px;
          @media (max-width: 640px) {
            width: 100%;
          }
        }
      }
    }
    @media (max-width: 640px) {
      padding: 16px;

      .title {
        padding-bottom: 16px;
        padding-top: 24px;
        font-size: 24px;
        line-height: normal;
        width: 100%;
        text-align: left;
        font-weight: 600;
      }
    }
  }

  .extra-section {
    border-top: 0.3px solid #000000;
    border-bottom: 0.3px solid #000000;
    padding: 2rem 80px 4rem;

    @media (max-width: 640px) {
      padding: 16px;
      border: none;
    }

    .title {
      color: #272727;
      font-family: DomaineSansText-Regular;
      font-size: 32px;
      font-weight: 600;
      line-height: 35px;
      letter-spacing: 0.1em;
      text-align: center;

      @media (max-width: 640px) {
        padding-bottom: 16px;
        padding-top: 8px;
        font-size: 24px;
        line-height: normal;
        width: 100%;
        text-align: left;
        font-weight: 600;
        letter-spacing: normal;
        margin: 0;
      }
    }

    .desc {
      color: #272727;
      font-size: 20px;
      font-family: DomaineSansText-Light;
      font-weight: 400;
      line-height: 31px;
      text-align: center;
      margin: 0 auto;
      width: 60vw;

      @media (max-width: 640px) {
        text-align: justify;
        font-size: 16px !important;
        line-height: 1.5 !important;
        font-family: "DomaineSansText-Light" !important;
        width: 100%;
        letter-spacing: normal;
      }
    }

    .images {
      display: flex;
      gap: 1rem;
      margin-top: 4rem;
      @media (max-width: 640px) {
        flex-direction: column;
        margin: 16px 0;

        img {
          width: 100% !important;
          height: 440px;
          object-fit: cover;
          object-position: top;

          &:first-child {
            display: none;
          }
        }
      }
    }
  }

  .booking-section {
    padding-bottom: 4rem;
    margin-bottom: 80px;
    background: #f9f9f9;

    @media (max-width: 640px) {
      margin-bottom: 30px;
      padding: 16px;
    }

    .header-section {
      background-color: #fff;
      padding: 3% 0;
      .title {
        font-family: DomaineSansText-Regular;
        font-size: 32px;
        color: #272727;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0.1em;
        text-align: center;
        margin-bottom: 10px;

        @media (max-width: 640px) {
          padding-bottom: 8px;
          padding-top: 8px;
          font-size: 24px;
          line-height: normal;
          width: 100%;
          font-weight: 600;
          letter-spacing: normal;
          margin: 0;
        }
      }

      .desc {
        color: #272727;
        font-size: 20px;
        font-family: DomaineSansText-Light;
        font-weight: 400;
        line-height: 31px;
        text-align: center;
        margin: 0 auto;
        width: 60vw;

        @media (max-width: 640px) {
          text-align: center;
          font-size: 16px !important;
          line-height: 1.5 !important;
          font-family: "DomaineSansText-Light" !important;
          width: 100%;
          letter-spacing: normal;
          margin-bottom: 16px;
        }
      }
    }

    margin-bottom: 80px;

    .plan-section {
      font-family: DomaineSansText-Light;
      padding: 40px 80px 0px;
      display: flex;
      justify-content: flex-end;
      position: relative;
    }
    .img-grid {
      position: absolute;
      top: -10px;
      left: -40px;
    }

    .plans-container {
      display: flex;
      align-items: center;

      .txt-choose {
        font-size: 49px;
        line-height: 72.54px;
        flex: 2;
        margin-bottom: 6rem;
      }

      .plan1,
      .plan2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0px 0px;
        text-align: center;
        width: 25vw;
      }

      .plan2 {
        background-color: #d4d2d2a1;
      }

      .txt-plan {
        font-weight: 400px;
        font-size: 25px;
        line-height: 33px;
        margin: 0px;
      }
      .txt-plan-type {
        font-weight: 400px;
        font-size: 20px;
        line-height: 25px;
      }
      .txt-plan-price {
        font-weight: 700;
        font-size: 51px;
        line-height: 66px;
      }

      .btn-plan1,
      .btn-plan2 {
        background-color: #fff;
        color: #000;
        font-size: 20px;
        height: 64px;
        margin-top: 2rem;
        width: 240px;
      }

      .btn-plan2 {
        background-color: #000;
        color: #fff;
      }
    }

    .plan-features-section {
      font-family: DomaineSansText-Regular;
      margin: 0px 80px;
      padding-top: 100px;
    }

    @media (max-width: 640px) {
      .plan-features-section,
      .plan-section {
        display: none;
      }
    }

    .plan-details {
      background: #fff;
      margin: 4%;
      padding: 20px 40px 70px;
      margin-bottom: 0;

      @media (max-width: 640px) {
        display: none;
      }
    }
    .plan-feature {
      align-items: center;
      border-bottom: 1px solid #8a8686;
      display: flex;
      gap: 5rem;
      padding: 10px 0px;

      .txt-feature {
        margin: 0px;
        flex: 2.2;
      }

      .plan1,
      .plan2 {
        margin: 0px;
        flex: 2;
        text-align: center;
        line-height: 48px;
      }
      .plan2 {
        z-index: 4000;
      }
    }

    .plan-features-section {
      position: relative;
    }

    .plan-bg {
      background-color: #d4d2d2a1;
      position: absolute;
      height: -webkit-fill-available;
      top: 0px;
      right: 0px;
      width: 25vw;
    }
  }

  @media (max-width: 1300px) and (min-width: 900px) {
    .plan-section {
      padding: 80px 40px 0px !important;
    }
    .plan-features-section {
      margin: 0px 40px !important;
    }

    .booking-section .plan-feature .plan1,
    .booking-section .plan-feature .plan2 {
      flex: 2 !important;
    }
  }

  @media (min-width: 1445px) {
    .info-div {
      div {
        margin: 20px auto;
        width: 95% !important;
      }

      .txt-book {
        font-size: 120px !important;
        line-height: 160px !important;
      }
      .txt-photoshoot-info {
        font-size: 55px !important;
        line-height: 60px !important;
      }
    }
  }

  @media (min-width: 1800px) and (min-width: 900px) {
    .plan-feature {
      .plan1,
      .plan2 {
        flex: 1.8 !important;
      }
    }
  }

  .mobile-btn-plan1 {
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 48px;
    margin-top: 16px;
    font-size: 16px;
  }
  .mobile-plan-features-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export default BookPhotoshootWeb;
