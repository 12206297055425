import { Button, Form } from "antd";
import {
  // Link,
  useNavigate,
} from "react-router-dom";
import { connect } from "react-redux";
import { registerSeller } from "../redux/user/user.actions";
// import { useEffect, useState } from "react";

// const { Option } = Select

const RegisterSeller = ({ setCurrentKey }) => {
  //register, user, setUserType, <----props
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = () => {
    navigate("/sell/apply-to-join");
    // values.dob = values["dob"].format("YYYY-MM-DD");
    // const res = await register(values);
    // if (res.status === "not ok") message.error(res.message);
    // if (res.status === "ok") {
    //   navigate("/seller/email-verification");
    // }
  };

  // useEffect(() => {
  //   if (user.isAuthenticated && user.info.role === 2) {
  //     if (user.info.email_verified === true) {
  //       if (user.info.has_created_store === true) {
  //         navigate("/account");
  //       }

  //       if (user.info.has_created_store !== true) {
  //         navigate("/create-store");
  //       }
  //     }
  //     if (user.info.email_verified !== true) {
  //       navigate("/seller/email-verification");
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user.info]);

  // const [countryValue, setCountryValue] = useState('')

  return (
    <div>
      <div className="reg-register-seller-container">
        <div className="reg-register-container seller-form">
          <div className="reg-regiseter-seller-contents">
            <h2 className="reg-txt-register">Join Losode</h2>
            <div className="reg-text-setup-div">
              <p className="reg-txt-setup">
                Are you a fashion entrepreneur aspiring to go global? We
                understand the challenges of business growth and are here to
                support you. Click the button below to get started.
              </p>
            </div>
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
            >
              <div className="reg-inputs-container">
                {/*<Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="reg-input" />
                </Form.Item>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="reg-input" />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email address"
                  rules={[
                    {
                      type: "email",
                      message: "Use a valid email address",
                    },
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                >
                  <Input className="reg-input" />
                </Form.Item>

                <Form.Item
                      name="phoneNumber"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone Number!",
                        },
                        {
                          min: 11,
                          message: "Please check the number of digits entered",
                        },
                        {
                          max: 11,
                          message: "Please check the number of digits entered",
                        },
                      ]}
                    >
                      <Input
                        className="reg-input"
                        type="number"
                      />
                    </Form.Item>

                    <Form.Item
                      name="country"
                      label="Country your business is located in"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        // placeholder="Select Country"
                        optionFilterProp="children"
                        value={countryValue}
                        onChange={(value) => setCountryValue(value)}
                      >
                        <Option value="Nigeria">Nigeria</Option>
                        <Option value="United States">United States</Option>
                        <Option value="United Kingdom">United Kingdom</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name="businessInstagram"
                      label="Business Instagram Handle"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Input className="reg-input" />
                    </Form.Item>

                    <Form.Item
                      name="howHeard"
                      label="Tell us how you heard about Losode"
                      rules={[
                        {
                          required: true,
                          message: "This is a required field",
                        },
                      ]}
                    >
                      <Select
                        className="select"
                        // placeholder="Select Country"
                        optionFilterProp="children"
                      >
                        <Option value="searchEngine">Search Engine</Option>
                        <Option value="friend">Referred By A Friend</Option>
                        <Option value="advert">Advert</Option>
                        <Option value="social">Facebook / Instagram / Twitter / LinkedIn</Option>
                        <Option value="event">A Losode Event</Option>

                      </Select>
                    </Form.Item>

                 <Form.Item
                  name="password"
                  label="Password"
                  extra="Password must be longer than 8 characters and must contain at least 1 capital letter, 1 small letter and 1 number "
                  rules={[
                    {
                      required: true,
                      message: "This is a required field",
                    },
                    {
                      pattern:
                        /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9@#$^+=])(.{8,300})$/,
                      message:
                        "The password you have entered does not meet our requirements. Please change it for your enhanced security",
                    },
                  ]}
                >
                  <Input.Password
                    className="reg-input"
                    // placeholder="Password"
                    type="password"
                  />
                </Form.Item>

                <Form.Item
                  name="c_password"
                  label="Confirm password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          "The passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    className="reg-input"
                    // placeholder="Confirm Password"
                    type="password"
                  />
                </Form.Item> */}

                {/* <Form.Item
                  name="dob"
                  label='Date of birth'
                  rules={[
                    {
                      required: true,
                      message: "Date of birth is required",
                    },
                  ]}
                >
                  <DatePicker className="date" 
                  // placeholder="Date of birth" 
                  />
                </Form.Item> */}
                {/* <Form.Item
                  name="gender"
                  label='Gender'
                  rules={[
                    {
                      required: true,
                      message: "Gender is required",
                    },
                  ]}
                >
                  <Select
                    // placeholder="Gender"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="other">Other</Option>
                  </Select>
                </Form.Item> */}

                {/* <Form.Item
                  name="country"
                  label="Select Country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required",
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    // placeholder="Select Country"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="Nigeria">Nigeria</Option>
                    <Option value="USA">USA</Option>
                    <Option value="UK">UK</Option>
                  </Select>
                </Form.Item> */}
                {/* <p className="reg-txt-terms">
                  By registering, you agree with our{" "}
                  <Link to="/sell-terms" target="_blank">
                    Losode Seller Agreement
                  </Link>
                  ,{" "}
                  <Link to="/terms" target="_blank">
                    Terms and Conditions
                  </Link>
                  ,{" "}
                  <Link to="/policy" target="_blank">
                    Privacy
                  </Link>{" "}
                  {` and `}{" "}
                  <a href="/policy#cookies" target="_blank">
                    Cookie Policy
                  </a>
                  . <br />
                  Join our seller{" "}
                  <a href="https://t.me/+EOIEDBbkFMgwNzE8">community here</a> to
                  gain access to tools and products from our partner network.
                  <br />
                  We will only use your personal information to keep you updated
                  on exciting products and services. show less
                </p> */}
                <Form.Item style={{ paddingTop: "10px" }}>
                  <Button
                    className="reg-btn-register"
                    type="primary"
                    htmlType="submit"
                  >
                    Join Losode
                  </Button>
                </Form.Item>
                {/* <p className="reg-txt-signin">
                  To buy an item, <Link to="/register">register here</Link>
                </p> */}
                <p
                  className="reg-txt-signin"
                  style={{ textAlign: "center", marginTop: "16px" }}
                >
                  Already have an account on Losode? Sign in{" "}
                  <span
                    className="signin-txt-signin"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      setCurrentKey("1");
                    }}
                  >
                    <strong>here</strong>
                  </span>
                </p>
              </div>
            </Form>
            <p className="reg-txt-setup">
              We are currently open to Nigerian Designers. If you are in another
              country and wish to sell on Losode, indicate your interest by
              joining our waitlist
            </p>
            <Button
              className="reg-btn-waitlist"
              type="ghost"
              onClick={() => navigate("/sell/apply-to-waitlist")}
            >
              Join our waitlist
            </Button>
          </div>
          <div className="signin-img-container">
            <div className="seller-auth-img-txt-bg"></div>
          </div>
        </div>
      </div>

      <style jsx="true">
        {`
          .reg-register-seller-container {
            display: flex;
            height: 85vh;
            overflow: auto;
            -ms-overflow-style: none; 
            scrollbar-width: none;
          }

          .reg-register-container {
            flex: 1;
            text-align: left;
            margin: 30px 0;
          }
          .reg-txt-register {
            font: normal bold 24px DomaineSansText-Black;
            color: #000;
            margin-bottom: 10px;
            text-align: center;
          }
          .reg-txt-setup {
            font: normal 14px DomaineSansText-Light;
            color: #000;
            padding: 0 24px;
            line-height: 25px;
            text-align: center;
            margin-bottom: 0px;
          }
          .ant-form-item {
            margin-bottom: 16px;
          }
          .ant-form-item-extra,
          .ant-form-item-explain {
            padding-top: 4px;
          }
          .ant-form-vertical .ant-form-item-label > label {
            font-family: "DomaineSansText-Regular";
            font-size: 14px;
          }
          .reg-inputs-container {
            margin: 0 24px;
            padding-bottom: 5px;
          }
          .reg-input,
          .reg-date {
            border: 0;
            border: 1px solid #808080;
            font-size: 16px;
            font-weight: 400;
            height: 48px;
            width: 100%;
          }
          .reg-input:focus,
          .reg-input:hover {
            border: 1px solid #000;
          }
          .ant-picker-input > input {
            font-size: 16px;
            font-weight: 300;
          }
          .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            border: 2px solid #ccc;
            font-size: 16px;
            height: 48px;
            padding: 0px;
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
          .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            display: flex;
            padding: 7px 10px;
          }

          .reg-btn-register {
            font: normal 16px DomaineSansText-Light;
            width: 100%;
            height: 48px;
            margin-top: 5px;
          }
          .reg-btn-waitlist {
            font: normal 16px DomaineSansText-Light;
            width: 90%;
            height: 48px;
            margin: 10px 24px;
          }

          .reg-txt-signin {
            font: normal normal 14px DomaineSansText-Light;
            color: #000;
            margin-top: 0px;
            text-align: center;
          }
          .reg-txt-terms {
            font: normal normal normal 14px/22px DomaineSansText-Light;
          }
          .reg-txt-terms a:hover {
            text-decoration: underline;
          }
          .ant-picker:hover,
          .ant-picker-focused,
          .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border-color: black;
            border-right-width: 1px !important;
          }

          .ant-form-item-extra {
            margin-bottom: -8px;
          }

          .signin-img-container {
            background-image: url("./images/seller-sub-banner.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            margin: 0 24px;
            height: 277px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          @media (max-width: 900px) {
            .reg-register-container {
              background: white;
              margin: 0;
              max-width: 100%;
              width: 100% !important;
              padding: 18px 11px;
            }
            .reg-regiseter-seller-contents {
              padding: 0;
            }
            .input,
            .ant-select-single:not(.ant-select-customize-input)
              .ant-select-selector,
            .reg-btn-register,
            .reg-date,
            .ant-picker-input > input {
              margin-top: 0 !important;
              font-size: 16px !important;
            }
            .ant-form-vertical .ant-form-item-label > label {
              font-size: 12px !important;
            }
            .ant-form-item {
              margin-bottom: 16px !important;
            }
            .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
            .ant-select-single.ant-select-show-arrow
              .ant-select-selection-placeholder {
              display: flex;
              padding: 0 15px;
              align-items: center;
            }
            .ant-select-arrow {
              top: 53%;
              height: 10px;
              width: 16px;
            }
            .reg-txt-setup {
              font-size: 13px !important;
              width: 100%;
              line-height: 22px !important;
              padding: 0 9px 14px;
            }
            .reg-txt-register {
              font-size: 24px !important;
              line-height: 1;
              margin-bottom: 16px;
              margin-top: 14px;
            }
            .reg-txt-signin {
              font: normal normal 13px/14px DomaineSansText-Light !important;
              margin-bottom: 0px;
            }
            .reg-btn-waitlist,
            .reg-btn-waitlist span {
              border: 0;
              font-weight: bold;
              text-decoration: underline;
              text-transform: capitalize;
              font-family: DomaineSansText-Black;
              height: auto;
              color: black;
            }
            .signin-img-container {
              border-radius: 8px;
              margin: 48px 12px 40px;
            }
            .reg-txt-terms {
              font: normal normal 12px/16px DomaineSansText-Regular !important;
            }

            .reg-inputs-container {
              margin: 0px;
              padding-bottom: 24px;
            }
            .ant-form-item-explain,
            .ant-form-item-extra {
              font-size: 12px;
            }
          }
        `}
      </style>
    </div>
  );
};

const matchDispatchToProps = (dispatch) => ({
  register: (values) => dispatch(registerSeller(values)),
});

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, matchDispatchToProps)(RegisterSeller);
