import React from "react";
import { useMediaQuery } from "react-responsive";
import CompleteListingMobile from "./CompleteListingMobile";
import CompleteListingWeb from "./CompleteListingWeb";

function CompleteListing() {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  return (
    <>{isTabletOrMobile ? <CompleteListingMobile /> : <CompleteListingWeb />}</>
  );
}

export default CompleteListing;
